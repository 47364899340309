import * as React from "react";
import { Selection, Select as NSelect, SelectItem } from "@nextui-org/react";
import { DropdownType, EmployeeDirectoryType } from "../../../../types";

interface Props {
  array: DropdownType[];
  selectedValue?: EmployeeDirectoryType | undefined;
  selectedKeys: Selection;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelectionChange: (keys: Selection) => any;
}

export default function Select({
  array,
  selectedValue,
  selectedKeys,
  onSelectionChange,
}: Props) {
  return (
    <NSelect
      color="secondary"
      selectionMode="single"
      onSelectionChange={onSelectionChange}
      selectedKeys={selectedKeys}
      className="min-w-36"
    >
      {array?.map((item) => (
        <SelectItem key={item.key}>{item.value}</SelectItem>
      ))}
    </NSelect>
  );
}
