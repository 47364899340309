import React from "react";
import Main from "../../../components/ui/sidebar/Main.tsx";

const Documents = () => {
  return (
    <Main>
      <div>
        <div>Documents Page</div>
      </div>
    </Main>
  );
};

export default Documents;
