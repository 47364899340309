import React from "react";
import { Colors } from "../../assets/colors";

const ServiceCard = ({ imageUrl, cardtitle, content, onClick = () => {} }) => {
  return (
    <div onClick={onClick} className="flex justify-center cursor-pointer">
      <div className="text-zinc-800 relative mb-4  bg-white  rounded-md  w-[100%] ">
        {imageUrl && (
          <img
            src={imageUrl}
            alt="Service Image"
            className="w-full h-auto mb-4 rounded-md"
          />
        )}

        {/* Title */}
        <h2 className="text-black text-xl leading-8 font-roboto font-medium mb-4">
          {cardtitle}
        </h2>

        {/* Content */}
        <p className="text-black text-base pb-3 relative font-light  font-roboto w-[80%]">
          {content}
        </p>

        {/* Read More */}
        <p
          style={{ offsetPosition: "normal", color: Colors.buttonprimary }}
          className="underline font-roboto font-normal text-base cursor-pointer"
        >
          Learn more
        </p>
      </div>
    </div>
  );
};

export default ServiceCard;
