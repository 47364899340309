export type Article = {
  author: string;
  content: string;
  createdAt: string;
  description: string;
  id: string;
  image: string;
  metadata: string;
  slug: string;
  title: string;
  updatedAt: string;
};

export type Address = {
  city: string;
  address: string;
  image: string;
  phone: string;
  pocname: string;
  pocemail: string;
  poclinkdin: string;
  iframe: string;
};

// Define an interface for the component props
export type CarouselItemProps = {
  imgSrc: string;
  showReadMore: boolean;
  onClickKnowmore: () => void;
  onContactClick: () => void;
  bannerImgSrc?: string;
  showBanner: boolean;
};

export type User = {
  user_id: number;
  exp: number;
};

export type StoreType = {
  authToken?: string;
  setAuthToken: (authToken?: string) => void;
  user?: User;
  setUser: (user: User) => void;
  logout: () => void;
};

//rental type
export type ClientRentalNumbersList = {
  ID: number;
  CreatedAt: string;
  UpdatedAt: string;
  DeletedAt: string | null;
  Action: string;
  Status: number;
  ClientCode: string;
  DocumentUrl: string;
  ScheduleNumber: string;
  State: string;
  StartDate: string;
  ExpiryDate: string;
  Value: string;
  NumberOfAssets: string;
};

export type ClientInvoiceNumbersList = {
  ID: number;
  CreatedAt: string;
  UpdatedAt: string;
  DeletedAt: string | null;
  asset_value: string;
  billing_state: string;
  cess_amount: string;
  cess_amount_val: string;
  cgst_amount: string;
  cgst_amount_val: string;
  connect_amount: string;
  connect_state: string;
  credit_ledger_1: string;
  credit_ledger_1_amount: string;
  credit_ledger_2: string;
  credit_ledger_2_amount: string;
  credit_ledger_3: string;
  credit_ledger_3_amount: string;
  credit_ledger_4: string;
  credit_ledger_4_amount: string;
  credit_ledger_5: string;
  credit_ledger_5_amount: string;
  customer_address_line_1: string;
  customer_gst_number: string;
  customer_gstin: string;
  customer_name: string;
  customer_state: string;
  debit_party_ledger: string;
  document_type: string;
  e_invoice_status: string;
  funder_amount: string;
  funder_name: string;
  igst_amount: string;
  igst_amount_val: string;
  invoice_date: string;
  invoice_number: string;
  invoice_pdf_url: string;
  invoice_transaction_type: string;
  invoice_type: string;
  irn: string;
  item_description: string;
  other_charges: string;
  payment_amount: string;
  payment_date: string;
  place_of_supply: string;
  remark: string;
  rental_end_date: string;
  rental_schedule: string;
  rental_schedule_state: string;
  rental_start_date: string;
  rental_type: string;
  sgst_amount: string;
  taxable_value: string;
  total_amount: string;
  total_invoice_value: string;
  uuid: string;
  voucher_type: string;
};

export type OrderEppList = {
  CompanyCode: string;
  CreatedAt: string;
  DeletedAt: string | null;
  DeletedDate: string; // This could be null or an empty string if not used
  EmployeeEmail: string;
  EmployeeID: string;
  EmployeeName: string;
  ID: number;
  OrderDate: string;
  ProductID: string;
  ProductSpecification: string;
  Quantity: number;
  RemovalDate: string; // This could be null or an empty string if not used
  RentalAmount: string;
  Status: string;
  UpdatedAt: string;
  Variant: string;
  level: string;
  total_monthly_rent: number;
};

export type EppOrderList = {
  ID: number;
  CreatedAt: string;
  UpdatedAt: string;
  DeletedAt: string | null;
  InvoiceNumber: string;
  Action: string;
  Status: number;
  ClientCode: string;
  DocumentUrl: string;
  ScheduleNumber: string;
  Year: string;
  Amount: string;
  PaymentStatus: boolean;
};

export type ClientScheduleNumbersList = {
  ID: number;
  CreatedAt: string;
  UpdatedAt: string;
  DeletedAt: string | null;
  ScheduleNumber: string;
  Action: string;
  Status: number;
  ClientCode: string;
  TotalAssets: number;
  State: string;
  EndDate: string;
};

export type AssetInventoryType = {
  ADP: string;
  AdapterChargerCable: string;
  AssetCategory: string;
  AssetDetailsDescription: string;
  Branch: string;
  Brand: string;
  CPU: string;
  CompanyCode: string;
  CreatedAt: string;
  DeletedAt: string;
  DeliveryAddress: string;
  EndDate: string;
  FromDate: string;
  Generation: string;
  HSNCode: string;
  ID: number;
  Identifier: number;
  InvoiceDate: string;
  InvoiceNo: string;
  ModelName: string;
  NameofVendor: string;
  OriginalScheduleNumber: string;
  Processor: string;
  PurchaseDate: string;
  QTY: string;
  RAM: string;
  ScheduleAmount: string;
  ScheduleNumber: string;
  Screen: string;
  SerialNumber: string;
  State: string;
  Storage: string;
  Tenure: string;
  TrancheNo: string;
  UpdatedAt: string;
  WarrantyEndDate: string;
  WarrantyStartDate: string;
  Remark: string;
  Status: string;
  ProductId: string;
  monthly_rent: string;
};

export type DeviceInfoType = {
  ADP: string;
  AgentName: string;
  BIOSLocked: string;
  BatteryCharging: string;
  BatteryHealth: string;
  BatteryHealthPercentage: string;
  BluetoothConnectivity: string;
  Brand: string;
  CPU: string;
  Camera: string;
  Category: string;
  Charger: string;
  ChargerPowerWattage: string;
  CompanyCode: string;
  CreatedAt: string;
  DamageCharges: string;
  DeletedAt: null | string;
  Fingerprint: string;
  GPUMemory: string;
  GPUName: string;
  Grade: string;
  HDDBrandSerialNumber: string;
  HDDType: string;
  HDMIPort: string;
  Hinge: string;
  ID: number;
  InstalledOS: string;
  Keyboard: string;
  LANPort: string;
  ManualGrade: string;
  ManualGradeComments: string;
  Microphone: string;
  Model: string;
  PanelA: string;
  PanelB: string;
  PanelC: string;
  PanelD: string;
  PowerKey: string;
  QCDate: string;
  QCRemarks: string;
  RAMBrandSerialNumberSlot1: string;
  RAMBrandSerialNumberSlot2: string;
  RAMTotal: string;
  RAMTypeSlot1: string;
  RAMTypeSlot2: string;
  SSDBrandSerialNumber: string;
  SSDType: string;
  ScheduleNumber: string;
  Screen: string;
  ScreenSize: string;
  SerialNumber: string;
  Speaker: string;
  Status: number;
  TouchDisplay: string;
  Touchpad: string;
  USBPort: string;
  UpdatedAt: string;
  Warranty: string;
  WifiConnectivity: string;
  WindowsKey: string;
  OriginalScheduleNumber: string;
  InventoryModelName: string;
  InventoryCPU: string;
  InventoryRAM: string;
  InventoryStorage: string;
  ScheduleAmount: string;
  State: string;
  Location: string;
  ShipmentId: string;
};

export interface UpcomingQcType {
  id: number;
  company_code: string;
  schedule_number: string;
  end_date: string;
  total_assets: number;
  client_name: string;
  qc_request_date: string;
}

export type LatestUpdateList = {
  Header: string;
  CreatedAt: string;
  UpdatedAt: string;
  DeletedAt: string | null;
  Link: string;
};

export type Style = {
  [key: string]: React.CSSProperties;
};
// TEXT TYPES

export type FontSize = 4 | 8 | 12 | 16 | 20 | 24 | 28 | 32 | 36 | 40; // Adjust this list as needed

export type HeadingSize = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

export type SubheadingSize = "subheading1" | "subheading2" | "subheading3";

export const headingSizeMap: Record<HeadingSize, number> = {
  h1: 32,
  h2: 28,
  h3: 24,
  h4: 20,
  h5: 16,
  h6: 12,
};

export const subheadingSizeMap: Record<SubheadingSize, number> = {
  subheading1: 20,
  subheading2: 16,
  subheading3: 12,
};

export type SelectType = {
  label: string;
  value: string | number;
};

export interface EmployeeDirectoryType {
  name: string;
  email: string;
  mobile: string;
  status: string;
  uuid: string;
  id: string;
  last_login_date: string;
  company_code: string;
  uploaded_by: string;
  role: string;
  active_devices: number;
  total_monthly_rent: number;
  level: string;
  address: string;
}

export interface EppAddressType {
  address_code: string;
  company_name: string;
  address_line_1: string;
  address_line_2: string;
  city: string;
  state: string;
  pin: string;
  gst_number: string;
  uploaded_by: string;
}

export type DropdownType = {
  key: string;
  value: string | number;
};

export type ColorMapping = {
  [key: string]: string; // This allows any string key
};

export type CartType = {
  id: number;
  uuid: string;
  employee_name: string;
  device_configuration: string;
  request_date: string;
  rental_amount: string;
  quantity: number;
  status: string;
};

export type OrderType = {
  id: number;
  order_id: string;
  order_value: string;
  order_date: string;
  quantity: number;
  status: string;
  company_code: string;
  company_name: string;
};

export type VendorClientListType = {
  "Client Code": string;
  "Client Name": string;
  "Vendor ID": string;
};

export type VendorOrderListType = {
  "Client ID": string;
  CreatedDate: string;
  "PO Number": string;
  "PO PDF-Link": string;
  "Total Amount": number;
  "Total Quantity": number;
  "Vendor ID": string;
};

export type VendorType = {
  client_code: string;
  company_name: string;
  status: string;
  vendor_id: string;
  vendor_name: string;
};

export type OrderDetailType = {
  total_value: number;
  total_cess: number;
  total_tax: number;
  total_base_amount: number;
  unit_price: number;
  quantity: string;
  item_desc: string;
  item_category: string;
  item_name: string;
  po_number: string;
};

export type ProductDetailType = {
  ID: number;
  NameofVendor: string;
  InvoiceNo: string;
  InvoiceDate: string;
  AssetCategory: string;
  Brand: string;
  ModelName: string;
  CPU: string;
  RAM: string;
  Storage: string;
  AssetDetailsDescription: string;
  QTY: string;
  DeliveryAddress: string;
  Branch: string;
  State: string;
  ScheduleNumber: string;
  ScheduleAmount: string;
  Tenure: string;
  FromDate: string;
  EndDate: string;
  TrancheNo: string;
  SerialNumber: string;
  HSNCode: string;
  Processor: string;
  Generation: string;
  WarrantyStartDate: string;
  Screen: string;
  WarrantyEndDate: string;
  ADP: string;
  AdapterChargerCable: string;
  OriginalScheduleNumber: string;
  CompanyCode: string;
  Identifier: number;
  PurchaseDate: string;
  Remark: string;
  Status: string;
  VendorId: string;
};

export type VendorAddressType = {
  uuid: string;
  address: string;
  state: string;
  pin: string;
  vendor_code: string;
  gstin: string;
};

export type VendorInfoType = {
  Pan: string;
  VendorType: string;
  IsMsme: string;
  UdyamStatus: string;
  UdyamNumber: string;
  Status: number;
  BankName: string;
  AccountNumber: string;
  IfscCode: string;
  CancelledChequeUrl: string;
  BankDetailsStatus: string;
  VendorCode: string;
  GstNumber: string;
  is_pan_verified: boolean;
  is_bank_verified: boolean;
};

export interface VendorInventoryType {
  ID: number;
  CreatedAt: string;
  UpdatedAt: string;
  DeletedAt: string | null;
  uuid: string;
  order_id: string;
  invoice_number: string;
  invoice_date: string;
  po_number: string;
  asset_type: string;
  asset_description: string;
  rate_per_unit: number;
  quantity: number;
  serial_number: string;
  tax_rate: number;
  cgst: number;
  igst: number;
  sgst: number;
  cess_rate: number;
  cess: number;
  gst_amount: number;
  total_asset_cost: number;
  delivery_address_1: string;
  delivery_address_2: string;
  delivery_pin_code: string;
  delivery_city: string;
  delivery_state: string;
  vendor_pan_no: string;
  vendor_gst_no: string;
  vendor_address: string;
  state_of_billing: string;
  udyam_number: string;
  vendor_code: string;
  client_code: string;
  email: string;
}

export interface VendorInvoiceType {
  ID: number;
  CreatedAt: string;
  UpdatedAt: string;
  DeletedAt: string | null;
  PoNumber: string;
  InvoiceNumber: string;
  DocumentUrl: string;
  CompanyCode: string;
  VendorCode: string;
  VendorEmail: string;
}

export type VendorClientType = {
  vendor_code: string;
  vendor_name: string;
  sno: string;
};

export type VendorInvoiceListType = {
  PO_Number: string;
  Invoice_PDF_URL__c: string;
  Invoice_Remarks__c: string;
  Invoice_Status__c: string;
  Invoice_Type__c: string;
};

export type PreProcurementDocumentsType = {
  uuid: string;
  id: string;
  client_name: string;
  document_name: string;
  document_type: string;
  CreatedAt: string;
  document_status: string;
  remarks: string;
  document_url: string;
};
