import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  getDocs,
  getDoc,
  doc,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCTaieidzwRIxgE-T_GxJ5_Woz7pgP9B4g",
  authDomain: "connectrpl-87785.firebaseapp.com",
  databaseURL: "https://connectrpl-87785-default-rtdb.firebaseio.com",
  projectId: "connectrpl-87785",
  storageBucket: "connectrpl-87785.appspot.com",
  messagingSenderId: "583464823285",
  appId: "1:583464823285:web:700cd3cc36ecf4665218bc",
  measurementId: "G-1ZHCNK9LWN",
};

// Initialize Firebase app
const firebase = initializeApp(firebaseConfig);
const firestore = getFirestore(firebase);
const auth = getAuth(firebase);

export { firebase, auth, firestore, collection, getDocs, getDoc, doc };
