import React from "react";
import { ClientScheduleNumbersList } from "../../../types.ts";
import Spacer from "../../../components/ui/Spacer.tsx";
import ScheduleNumbersList from "../../../components/pages/client/inventory-number-list/List.tsx";
import useApi from "../../../hooks/useApi.tsx";
import { DownloadQrCodes, GetScheduleNumbersApi } from "../../../apis.ts";
import { toast } from "react-toastify";
import Main from "../../../components/ui/sidebar/Main.tsx";

const Inventory = () => {
  const { makeApiCall } = useApi();

  const [clientScheduleNumbers, setClientScheduleNumbers] = React.useState<
    ClientScheduleNumbersList[]
  >([]);
  const [loading, setLoading] = React.useState(false);
  const [isDownloading, setIsDownloading] = React.useState(false);

  // Rental schedule get API call
  React.useEffect(() => {
    setLoading(true);
    makeApiCall(GetScheduleNumbersApi())
      .then((response) => {
        if (response !== undefined) {
          setClientScheduleNumbers(response);
        }
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, [makeApiCall]);

  const downloadDocument = React.useCallback(() => {}, []);

  const downloadQrCodes = React.useCallback(
    (scheduleNumber: string) => {
      setIsDownloading(false);
      toast.promise(
        new Promise((resolve, reject) => {
          return makeApiCall(DownloadQrCodes(scheduleNumber))
            .then((response) => {
              const blobUrl = URL.createObjectURL(response);
              const anchor = document.createElement("a");
              anchor.href = blobUrl;
              anchor.download = `${scheduleNumber}.zip`;
              anchor.style.display = "none";
              document.body.appendChild(anchor);
              anchor.click();

              URL.revokeObjectURL(blobUrl);
              document.body.removeChild(anchor);
              resolve(null);
            })
            .catch((err) => {
              console.error("Error fetching or downloading ZIP file:", err);
              reject(new Error("Qr codes download failed"));
            })
            .finally(() => setIsDownloading(false));
        }),
        {
          pending: "Qr codes downloading",
          success: "Qr codes download successfully",
          error: "Qr codes download failed",
        },
      );
    },
    [makeApiCall],
  );

  return (
    <Main>
      <div className="text-black bg-pageBackground px-10 min-h-screen">
        <Spacer size="sm" />
        <ScheduleNumbersList
          scheduleNumbers={clientScheduleNumbers}
          loading={loading}
          downloadInventory={downloadDocument}
          downloadQrCodes={downloadQrCodes}
        />
      </div>
    </Main>
  );
};

export default Inventory;
