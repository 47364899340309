import * as React from "react";
import Spacer from "../../../components/ui/Spacer.tsx";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import useApi from "../../../hooks/useApi.tsx";
import { AddEmployeesApi } from "../../../apis.ts";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import FileInput from "../../../components/ui/FileInput.tsx";
import Button from "../../../components/ui/Button.tsx";
import Row from "../../../components/ui/Row.tsx";
import Main from "../../../components/ui/sidebar/Main.tsx";
import { CiSaveDown2 } from "react-icons/ci";
import { exportToExcel } from "../../../utils/utils.ts";

const INTIAL_VALUES = {
  employees: null as File | null,
};

export default function AddEmployees() {
  const { makeApiCall } = useApi();
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    employees: Yup.mixed().required("File is required"),
  });

  const downloadCSV = React.useCallback(() => {
    const headers = [{ Name: "", Email: "", Mobile: "" }];
    return exportToExcel(headers, "example-csv");
  }, []);

  const handleSubmit = React.useCallback(
    ({ employees }: typeof INTIAL_VALUES) => {
      return makeApiCall(AddEmployeesApi(employees!))
        .then(() => {
          navigate("/dashboard/employee-directory");
          toast.success("Employee added succesfully");
        })
        .catch(() => {
          toast.error("Employee added failed");
        });
    },
    [makeApiCall, navigate],
  );
  return (
    <Main>
      <div className="text-black bg-pageBackground px-10 min-h-screen">
        <Spacer size="sm" />
        <div className="flex flex-col">
          <div className="flex flex-row justify-between items-center w-[100%]">
            <p className="text-black text-lg leading-8 font-roboto font-normal mt-[2%]">
              Add Employees
            </p>
            <Button
              color="default"
              variant="bordered"
              startContent={<CiSaveDown2 size={18} />}
              className="font-roboto"
              size="sm"
              radius="sm"
              onClick={downloadCSV}
            >
              Download
            </Button>
          </div>
          <Spacer size="xs" />
          <Formik
            initialValues={INTIAL_VALUES}
            onSubmit={handleSubmit}
            validateOnBlur
            validateOnChange
            validationSchema={validationSchema}
            enableReinitialize
          >
            <Form>
              <FileInput name="employees" type="dropzone" accept=".csv" />
              <Spacer size="sm" />
              <Row justifyContent="center">
                <Button type="submit">Submit</Button>
              </Row>
            </Form>
          </Formik>
        </div>
      </div>
    </Main>
  );
}
