import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { Providers } from "./redux/providers";
import Paths from "./router";
import { useLocation } from "react-router-dom";
import { dashPaths } from "./assets/data";
import DashPaths from "./dashrouter";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  const { pathname } = useLocation();

  const isRoute = (pathname, hiddenRoutes) => {
    return hiddenRoutes.some((route) => {
      const routePattern = new RegExp(`^${route.replace(/:\w+/g, "\\w+")}$`);
      return routePattern.test(pathname);
    });
  };

  const isShow = isRoute(pathname, dashPaths);

  return (
    <div>
      <div className="flex flex-col min-h-screen">
        {!isShow && <Header />}
        <Providers>
          <div className="flex-grow">{!isShow ? <Paths /> : <DashPaths />}</div>
        </Providers>
        {!isShow && <Footer />}
      </div>
      <ToastContainer />
    </div>
  );
};

export default App;
