export const Colors = {
  primary: "#00006A",
  background: "#f2f2f2",
  text: "#2E2E2E",
  // Add more colors as needed
  activeprimary: "#007FFF",
  buttoncolor: "#CE000B",
  buttonprimary: "#00006A",
  darkBackground: "#F7F7F7",
  pageBackground: "#F4f4f4",
  linkColor: "#2775EB",
  secondryLinkColor: "#68696F",
  textColorGrey: "#68696F",
  tableHeaderColor: "#2C2D33",
  textColorSecondary: "#45464E",
  bgButtonGreen: "#d2eace",
  buttonBackground: "#00006D",

  //apple epp clors
  black: "#000000",
  white: "#FFFFFF",
  silver: "#C0C0C0",
  gold: "#FFCC00",
  blue: "#007A7A",
  spacegray: "#A1A1A1",
};
