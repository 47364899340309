import React from "react";
import TopNav from "../../components/TopNav";
import Welcome from "../../components/homeelments/Welcome";
import Ourservices from "../../components/homeelments/Ourservices";
import { Helmet } from "react-helmet";
import Contactform from "../../components/homeelments/Contactform";
import OurCustomer from "../../components/homeelments/OurCustomer";
import { useNavigate } from "react-router-dom";
import Programmes from "../programmes/Programmes";
import OurCompanies from "../../components/homeelments/OurCompanies";

const Homepage = () => {
  const navigate = useNavigate();
  const welcomeRef = React.useRef(null);

  const scrollToWelcome = () => {
    if (welcomeRef.current) {
      welcomeRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <section>
      <Helmet>
        <title>
          Asset Leasing and Financing | Connect Residuary Private Limited | Home
        </title>
        <meta
          name="description"
          content="Connect Residuary Pvt Limited is a well-established asset leasing brand in India dealing in all types of IT Assets, Fit-outs, Plant and machinery etc. We have been leased over 2000cr + of assets in past 12 years of operations."
        />
        <meta
          name="keywords"
          content="leasing, rent, asset on rent, laptop on rent, leasing, laptop on lease, Corporate Asset Purchase, IT asset leasing, equipment leasing, Financing, small business financing, equipment financing, vehicle financing, asset management, Leaseback, capital equipment, business loans, commercial loans, equipment rentals, SME, tax benefits of leasing, lease accounting"
        />
      </Helmet>
      <div className="w-full   bg-no-repeat bg-center bg-cover">
        <TopNav
          bannerurl="https://storage.googleapis.com/connectrpl-landing-pagev3/banner1%20.png"
          title="Extending Smart &
Innovative Rental Solution"
          titledesc="Connect follows a consultative approach for tailoring equipment
renting solutions for its clients by bringing in their residual
management capabilities along with the teams widespread industry
experience."
          showreadmore={true}
          showbanner={true}
          showlease={true}
          onClickKnowmore={() => {
            scrollToWelcome();
          }}
          onContactClick={() => {
            navigate("/reachus");
          }}
        />
        <div ref={welcomeRef}>
          <Welcome />
        </div>{" "}
        <Ourservices />
        <Programmes />
        <OurCustomer />
        <OurCompanies />
        {/* <Benefits /> */}
        {/* <Ourjourney /> */}
        {/* <Testimonials/> */}
        <Contactform />
        {/* <Gorevive /> */}
      </div>
    </section>
  );
};

export default Homepage;
