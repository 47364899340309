import React from "react";
import Main from "../../../components/ui/sidebar/Main";

const ManageOrders = () => {
  return (
    <Main>
      <p>Manage Orders!!</p>
    </Main>
  );
};

export default ManageOrders;
