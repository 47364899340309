import React from "react";
import TopNav from "../../components/TopNav";
import { Helmet } from "react-helmet";
import Header from "../../components/common/Header";
import HeadingBanner from "../../components/common/HeadingBanner.tsx";
import TitleHead from "../../components/common/TitleHead.tsx";
import Contactform from "../../components/homeelments/Contactform";
import IndustriesCardDetail from "../../components/common/IndustriesCardDetail.js";
import { industriesArray } from "../../assets/data/index.js";

const Industries = () => {
  return (
    <div>
      <Helmet>
        <title>
          Asset Leasing and Financing | Connect Residuary Private Limited |
          industries
        </title>
        <meta
          name="description"
          content="Connect Residuary Pvt Limited is a well-established asset leasing brand in India dealing in all types of IT Assets, Fit-outs, Plant and machinery etc. We have been leased over 2000cr + of assets in past 12 years of operations."
        />
        <meta
          name="keywords"
          content="leasing, rent, asset on rent, laptop on rent, leasing, laptop on lease, Corporate Asset Purchase, IT asset leasing, equipment leasing, Financing, small business financing, equipment financing, vehicle financing, asset management, Leaseback, capital equipment, business loans, commercial loans, equipment rentals, SME, tax benefits of leasing, lease accounting"
        />
        {/* Add any other meta tags as needed */}
      </Helmet>{" "}
      <section style={{ offsetPosition: "normal" }} className="text-zinc-800">
        <div className="w-full ">
          <Header />
          <div className=" w-screen">
            <HeadingBanner
              imageUrl={
                "https://storage.googleapis.com/connectrpl_images/companywebsite/ReachBanner.png"
              }
              text="Connect’s Portfolio"
              desc={"Our Solutions, your growth"}
            />
          </div>
          <div className="flex justify-center ">
            <div className=" w-[80%] ">
              <p className=" text-base font-roboto w-full text-black text-center mt-5">
                Leasing is a widespread financial tool used across various
                industries around the world. This includes industries like:
              </p>
              <ul
                style={{ listStyleType: "disc" }}
                className="flex  flex-col items-center"
              >
                <li className=" text-base font-roboto  text-black text-left mt-5 w-[70%]">
                  Aviation
                </li>
                <li className=" text-base font-roboto  text-black text-left mt-5 w-[70%]">
                  FMCG (Fast Moving Consumer Goods)
                </li>
                <li className=" text-base font-roboto  text-black text-left mt-5 w-[70%]">
                  Real Estate
                </li>
                <li className=" text-base font-roboto  text-black text-left mt-5 w-[70%]">
                  Banking
                </li>
                <li className=" text-base font-roboto  text-black text-left mt-5 w-[70%]">
                  Software and IT
                </li>
                <li className=" text-base font-roboto  text-black text-left mt-5 w-[70%]">
                  Networking
                </li>
                <li className=" text-base font-roboto  text-black text-left mt-5 w-[70%]">
                  Medical Infrasture
                </li>
                <li className=" text-base font-roboto  text-black text-left mt-5 w-[70%]">
                  Payment Gateway Solutions
                </li>
                <li className=" text-base font-roboto  text-black text-left mt-5 w-[70%]">
                  Digital Finance
                </li>
              </ul>
              <p className=" text-base font-roboto w-full text-black text-center mt-5">
                Connect acts as a one-stop shop for leasing solutions, catering
                to these diverse industries and fulfilling their specific
                leasing needs.
              </p>
              <TitleHead title="Industries" subtitle="" />

              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mx-auto my-8">
                {industriesArray.map((item) => {
                  return (
                    <IndustriesCardDetail
                      imageUrl={item.imageUrl}
                      cardtitle={item.text}
                    />
                  );
                })}
              </div>

              <Contactform />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Industries;
