import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/common/Header.js";
import HeadingBanner from "../../components/common/HeadingBanner.tsx";
import Contactform from "../../components/homeelments/Contactform.js";
import TitleHead from "../../components/common/TitleHead.tsx";
import InfoCard from "../../components/common/InfoCard.tsx";
import { appleCardArray } from "../../assets/data/index.js";
import Benefits from "../../components/common/Benifits.tsx";

const AppleforBusiness = () => {
  return (
    <div>
      <Helmet>
        <title>
          Asset Leasing and Financing | Connect Residuary Private Limited |
          Apple for business
        </title>
        <meta
          name="description"
          content="Connect Residuary Pvt Limited is a well-established asset leasing brand in India dealing in all types of IT Assets, Fit-outs, Plant and machinery etc. We have been leased over 2000cr + of assets in past 12 years of operations."
        />
        <meta
          name="keywords"
          content="leasing, rent, asset on rent, laptop on rent, leasing, laptop on lease, Corporate Asset Purchase, IT asset leasing, equipment leasing, Financing, small business financing, equipment financing, vehicle financing, asset management, Leaseback, capital equipment, business loans, commercial loans, equipment rentals, SME, tax benefits of leasing, lease accounting"
        />
      </Helmet>

      {/* servicespage */}
      <div className="w-full ">
        <Header />
        <div className=" w-screen">
          <HeadingBanner
            imageUrl={
              "https://storage.googleapis.com/connectrpl_images/companywebsite/StartupsBanner.png"
            }
            text="Apple for Business"
            desc={"Apple for Connect Partnership"}
          />
        </div>
        <div className="flex justify-center ">
          <div className=" w-[80%] ">
            <p className=" text-base font-roboto w-full text-black text-center mt-5">
              Connect as Partnered with Apple to lease Macbooks, IPads, IPhones
              and other Apple accessories at attractive financing plans with
              benefits on procurement of upto 20 to 27%
            </p>

            <TitleHead title="Our Solutions" subtitle="" />

            {appleCardArray?.map((card, index) => (
              <InfoCard
                key={index}
                imageUrl={card.imageUrl}
                text={card.text}
                desc={card.desc}
                reverseOrder={card.reverseOrder}
              />
            ))}
            <Benefits />
            <Contactform />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppleforBusiness;
