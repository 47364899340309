import Button from "../../../ui/Button.tsx";
import Input from "../../../ui/Input.tsx";
import { Form, Formik } from "formik";
import * as React from "react";
import * as Yup from "yup";
import {
  AssetInventoryType,
  DropdownType,
  EmployeeDirectoryType,
} from "../../../../types.ts";
import { MdContentCopy } from "react-icons/md";
import secureLocalStorage from "react-secure-storage";
import Select from "./Select.tsx";
import { Selection } from "@nextui-org/react";

interface Props {
  device: AssetInventoryType;
  onSubmitRemark: (serial_number: string, remark: string) => void;
  employees: EmployeeDirectoryType[];
}

export const mapEmployeeNamesToDropdownTypes = (
  employees: EmployeeDirectoryType[],
): DropdownType[] => {
  return employees.map((employee) => ({
    key: employee.name, // Assuming uuid is unique and can be used as the key
    value: employee.name,
  }));
};

export default function UpdateRemarks({
  device,
  onSubmitRemark,
  employees,
}: Props) {
  const [selectedKeys, setSelectedKeys] = React.useState<Selection>(
    new Set([device?.Remark ?? ""]),
  );

  const handleSubmit = React.useCallback(
    ({ remark }: { remark: string }) => {
      return onSubmitRemark(device?.SerialNumber, remark);
    },
    [device?.SerialNumber, onSubmitRemark],
  );

  const validationSchema = Yup.object().shape({
    remark: Yup.string().required("Remark is required"),
  });

  const onSelectionChange = React.useCallback(
    (keys: Selection) => {
      setSelectedKeys(keys);
      const newRemark = Array.from(keys)[0] as string;
      return onSubmitRemark(device?.SerialNumber, newRemark);
    },
    [device?.SerialNumber, onSubmitRemark],
  );

  const selectedValue = React.useMemo(() => {
    return Array.from(selectedKeys).map((item) => {
      const filteredItem = employees.find(
        (sortedItem) => sortedItem.name === item,
      );
      return filteredItem;
    })[0];
  }, [employees, selectedKeys]);

  if (
    secureLocalStorage.getItem("platform") === "epp" &&
    secureLocalStorage.getItem("role") === "employer"
  ) {
    return (
      <Select
        array={mapEmployeeNamesToDropdownTypes(employees)}
        selectedKeys={selectedKeys}
        onSelectionChange={onSelectionChange}
        selectedValue={selectedValue}
      />
    );
  }

  return (
    <Formik
      initialValues={{ remark: device?.Remark ?? "" }}
      onSubmit={handleSubmit}
      validateOnBlur
      validateOnChange
      validationSchema={validationSchema}
      enableReinitialize
      key={`remark-${device?.Remark}`}
    >
      <Form>
        <Input
          name="remark"
          size="sm"
          endContent={
            <Button isIconOnly variant="light">
              <MdContentCopy />
            </Button>
          }
        />
      </Form>
    </Formik>
  );
}
