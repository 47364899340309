import React from "react";
import ProgrammeCard from "../../components/common/ProgrammeCard";
import Startups from "../../assets/images/startups.svg";
import Corporate from "../../assets/images/corporates.svg";
import Msmes from "../../assets/images/msmes.svg";
import Apple from "../../assets/images/apple.svg";
import AssetBuy from "../../assets/images/assetbuyback.svg";
import TitleHead from "../../components/common/TitleHead.tsx";
import { useNavigate } from "react-router-dom";
import { goTop } from "../../lib/methods.ts";

const Programmes = () => {
  const navigate = useNavigate();

  const navigateForStartups = React.useCallback(() => {
    window.open("/startups", "_blank");
  }, []);

  const navigateCorporates = React.useCallback(() => {
    window.open("/corporates", "_blank");
  }, []);

  const navigateMsme = React.useCallback(() => {
    window.open("/msme", "_blank");
  }, []);
  const navigateAppleLease = React.useCallback(() => {
    window.open("/appleforbusiness", "_blank");
  }, []);
  const navigateAssetBuyBack = React.useCallback(() => {
    window.open("/assetdisposal", "_blank");
  }, []);

  return (
    <section
      style={{ offsetPosition: "normal", backgroundColor: "#F7F7F7" }}
      className="text-zinc-800  mt-10 flex justify-center items-center pb-12"
    >
      <div className=" w-[80%] flex flex-col">
        <TitleHead title="Programmes" subtitle="Programmes we offer" />
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mx-auto my-4">
          <ProgrammeCard
            imageUrl={Startups}
            cardtitle={"For Startups"}
            content={
              "Leasing Assets for Startups is critical to ensure capital efficiency, especially during scaling up of business operations. "
            }
            onClick={navigateForStartups}
          />
          <ProgrammeCard
            imageUrl={Corporate}
            cardtitle={"For Corporates"}
            content={
              "Corporate organizations, MNCs can incur massive costs related to procurement of IT and Non IT assets for business operations. "
            }
            onClick={navigateCorporates}
          />
          <ProgrammeCard
            imageUrl={Msmes}
            cardtitle={"For MSMEs"}
            content={
              "Connect supports the massive MSME industry of India, helping with managing cashflows and exersing maximum benefits of time so that the MSME"
            }
            onClick={navigateMsme}
          />{" "}
          <ProgrammeCard
            imageUrl={Apple}
            cardtitle={"Apple"}
            content={
              "Lease Latest Apple Products, from Macbooks, Desptops, iPads, IPhone  etc for your business with Connect and gain on net positive ROI "
            }
            onClick={navigateAppleLease}
          />{" "}
          <ProgrammeCard
            imageUrl={AssetBuy}
            cardtitle={"Asset Buyback"}
            content={
              "Have old assets to dispose off? Connect provides its “FIrst in Industry” grading tools, packaging and pickup facilities PAN india "
            }
            onClick={navigateAssetBuyBack}
          />
        </div>
      </div>
    </section>
  );
};

export default Programmes;
