import React from "react";
import Logo1 from "../../assets/images/cipla.svg";
import Logo2 from "../../assets/images/kpmg.svg";
import Logo3 from "../../assets/images/samsung.svg";
import Logo4 from "../../assets/images/tata.svg";
import Logo5 from "../../assets/images/philips.svg";
import Logo6 from "../../assets/images/schlumberger.svg";
import { Colors } from "../../assets/colors";
import ClientsAnimation from "../common/ClientsAnimation";
import TitleHead from "../common/TitleHead.tsx";

const OurCustomer = () => {
  return (
    <div className="w-full flex justify-center items-center  mt-16 mb-10 md:mt-20 md:mb-10 ">
      <div className="flex flex-col md:flex-row w-[100%] mt-10">
        <div className=" flex-1 w-full ">
          <div className="flex flex-col justify-center items-center ">
            <TitleHead title="Clients" subtitle="Our Clients" />
          </div>
          <ClientsAnimation />
        </div>
      </div>
    </div>
  );
};

export default OurCustomer;
