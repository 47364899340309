import React from "react";
import Spacer from "../../../components/ui/Spacer.tsx";
import useApi from "../../../hooks/useApi.tsx";
import { GetClientSupportApi } from "../../../apis.ts";
import Card from "../../../components/common/SupportCard.tsx";
import Main from "../../../components/ui/sidebar/Main.tsx";

// Define the ApiResponse interface
interface ApiResponse {
  user_name: string;
  user_email: string;
  manager_name: string;
  manager_email: string;
  manager_designation: string;
  manager_phone: string;
  user_designation: string;
}

const Support = () => {
  const { makeApiCall } = useApi();
  const [responseData, setResponseData] = React.useState<ApiResponse | null>(
    null,
  ); // State to store API response data
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    makeApiCall(GetClientSupportApi())
      .then((response) => {
        if (response !== undefined) {
          setResponseData(response); // Set the API response data in state
        }
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, [makeApiCall]);

  return (
    <Main>
      <div className="text-black bg-pageBackground px-10 min-h-screen">
        <Spacer size="sm" />
        <p className="text-black text-lg leading-8 font-roboto font-normal mt-[2%]">
          Support
        </p>
        <Spacer size="xs" />
        {loading ? (
          <p>Loading...</p>
        ) : responseData ? (
          <div className="flex flex-col">
            <Card
              user_name={responseData.user_name}
              user_email={responseData.user_email}
              designation={responseData?.user_designation}
              phone={responseData?.manager_phone}
              cardTitle="Key Account Manager"
              level={1}
            />
            <div className="w-full  mb-4 border-b-2 border-dashed " />
            <Card
              user_name={responseData.manager_name}
              user_email={responseData.manager_email}
              designation={responseData?.manager_designation}
              phone={responseData?.manager_phone}
              cardTitle="Senior Account Manager"
              level={2}
            />
            <div className="w-full  mb-4 border-b-2 border-dashed " />
          </div>
        ) : (
          <p>No data available</p>
        )}
      </div>
    </Main>
  );
};

export default Support;
