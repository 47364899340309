import React from "react";
import Readbutton from "../../common/Readbutton";

// Define an interface for the component props
interface CarouselItemProps {
  imgSrc: string;
  showReadMore: boolean;
  onClickKnowmore: () => void;
  onContactClick: () => void;
  bannerImgSrc?: string;
  showBanner: boolean;
  title: string;
  description: string;
}

const CarouselItem: React.FC<CarouselItemProps> = ({
  imgSrc,
  showReadMore,
  onClickKnowmore,
  onContactClick,
  bannerImgSrc,
  showBanner,
  title,
  description,
}) => {
  const Colors = { white: "#ffffff" }; // Define Colors object, replace with your actual colors import

  return (
    <div className="carousel_item ">
      <img
        src={imgSrc}
        alt=""
        className="carousel_img"
        // style={{ height: "60vh" }}
      />

      <div className="carousel_caption">
        <div className="w-full flex justify-center items-center">
          <div className="flex flex-col md:flex-row w-[80%]  ">
            <div className="flex-1">
              <h1
                style={{
                  offsetPosition: "normal",
                  color: Colors.white,
                  fontSize: "2rem",
                }}
                className="mt-6 md:mt-14 text-3xl sm:text-4xl font-roboto font-light pb-2 border-solid text-left"
              >
                {title}
              </h1>
              <p
                style={{
                  offsetPosition: "normal",
                  color: Colors.white,
                  lineHeight: "1.5rem",
                }}
                className="text-base sm:text-xl font-roboto font-extralight py-4 text-left md:w-[37vw]"
              >
                {description}
              </p>
              {showReadMore ? (
                <div className="mt-1 mb-2">
                  <Readbutton
                    onClickKnowmore={onClickKnowmore}
                    onclickContact={onContactClick}
                    buttontext="Read more"
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarouselItem;
