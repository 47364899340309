import * as React from "react";
import { Formik, Form as FormikForm } from "formik";
import * as Yup from "yup";
import FileInput from "../../../components/ui/FileInput.tsx";
import Button from "../../../components/ui/Button.tsx";
import Main from "../../../../src/components/ui/sidebar/Main.tsx";
import useApi from "../../../../src/hooks/useApi.tsx";
import { UploadEppProductsApi } from "../../../../src/apis.ts";
import { toast } from "react-toastify";

const INTIAL_VALUES = {
  documents: null as File | null,
};

export default function ProductUpload() {
  const { makeApiCall } = useApi();

  const validationSchema = Yup.object().shape({
    documents: Yup.mixed().required("Documents are required"),
  });

  const handleSubmit = React.useCallback(
    ({ documents }: typeof INTIAL_VALUES) => {
      console.log("uploafdign products", documents);
      return makeApiCall(UploadEppProductsApi(documents!))
        .then((response) => {
          toast.success("Invoice added succesfully");
          console.log(response, "Responseof upload products");
        })
        .catch((error) => {
          toast.error(
            error?.response?.data?.message || "Invoice addition failed",
          );
        });
    },
    [makeApiCall],
  );

  return (
    <Main>
      <section className="bg-white dark:bg-gray-900">
        <div className="py-8 px-4 mx-auto max-w-2xl lg:py-16">
          <h2 className="mb-4 text-xl font-normal text-gray-900 dark:text-white">
            Upload Employee Products
          </h2>
          <Formik
            initialValues={INTIAL_VALUES}
            onSubmit={handleSubmit}
            validateOnBlur
            validateOnChange
            validationSchema={validationSchema}
            enableReinitialize
          >
            <FormikForm>
              <FileInput name="documents" type="dropzone" accept=".csv" />
              <Button color="primary" type="submit" className="mt-4">
                Submit
              </Button>
            </FormikForm>
          </Formik>
        </div>
      </section>
    </Main>
  );
}
