import React from "react";

import {
  Table,
  TableHeader,
  TableBody,
  TableColumn,
  TableRow,
  TableCell,
  Spinner,
  Pagination,
  Input,
  SortDescriptor,
  Chip,
} from "@nextui-org/react";
import Spacer from "../../../ui/Spacer";
import { CiSearch } from "react-icons/ci";
import { VendorClientListType } from "../../../../types";
import Action from "./client-list/Action.tsx";
import { useNavigate } from "react-router-dom";

interface Props {
  data: VendorClientListType[];
  loading: boolean;
}

const COLUMNS = [
  {
    name: "Sr No.",
    key: "sr_no",
  },
  {
    name: "Company Name",
    key: "company_name",
    sortable: true,
  },
  {
    name: "Status",
    key: "status",
  },
  {
    name: "Action",
    key: "action",
  },
];

export default function ClientList({ data, loading }: Props) {
  const [filterValue, setFilterValue] = React.useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(20);

  const navigate = useNavigate();

  //   const dropdownData: DropdownType[] = React.useMemo(
  //     () => [
  //       { key: "pending", value: "Pending" },
  //       { key: "approved", value: "Approved" },
  //       { key: "rejected", value: "Rejected" },
  //       { key: "processed", value: "Processed" },
  //     ],
  //     [],
  //   );

  const [sortDescriptor, setSortDescriptor] = React.useState<SortDescriptor>({
    column: "client_name",
    direction: "ascending",
  });

  const pages = React.useMemo(() => {
    if (data?.length === 0) {
      return 1;
    }
    return Math.ceil((data?.length ?? 1) / rowsPerPage);
  }, [data, rowsPerPage]);

  const hasSearchFilter = Boolean(filterValue);
  const [page, setPage] = React.useState(1);

  const filteredItems = React.useMemo(() => {
    let filteredUsers = [...data];

    if (hasSearchFilter) {
      filteredUsers = filteredUsers.filter((user) =>
        user.client_code.toLowerCase().includes(filterValue.toLowerCase()),
      );
    }

    return filteredUsers;
  }, [data, filterValue, hasSearchFilter]);

  const items = React.useMemo(() => {
    const start = (page - 1) * rowsPerPage;
    const end = start + rowsPerPage;
    return filteredItems.slice(start, end);
  }, [page, filteredItems, rowsPerPage]);

  const onRowsPerPageChange = React.useCallback((e: any) => {
    setRowsPerPage(Number(e.target.value));
    setPage(1);
  }, []);

  const onSearchChange = React.useCallback((value: any) => {
    if (value) {
      setFilterValue(value);
      setPage(1);
    } else {
      setFilterValue("");
    }
  }, []);

  const renderStatus = React.useCallback((item: VendorClientListType) => {
    if (item?.status === "pending") {
      return (
        <Chip variant="solid" color="warning">
          Pending
        </Chip>
      );
    } else if (item?.status === "success") {
      return (
        <Chip variant="solid" color="success">
          Completed
        </Chip>
      );
    } else {
      return <Chip variant="solid">Not Started</Chip>;
    }
  }, []);

  const handleViewOrders = React.useCallback(
    (item: VendorClientListType) => {
      return navigate(
        `/client/view-orders?company_code=${encodeURIComponent(item?.client_code)}&company_name=${encodeURIComponent(item?.company_name)}`,
      );
    },
    [navigate],
  );

  const renderCell = React.useCallback(
    (client: VendorClientListType, columnKey: React.Key) => {
      const index = data
        .map((object) => object.client_code)
        .indexOf(client.client_code);

      switch (columnKey) {
        case "sr_no":
          return (
            <div className="flex flex-col">
              <p className="text-bold text-sm capitalize">{index + 1}</p>
            </div>
          );

        case "company_name":
          return (
            <div className="flex flex-col">
              <p className="text-bold text-sm capitalize">
                {client.company_name}
              </p>
            </div>
          );
        case "status":
          return (
            <div className="flex flex-col">
              <p className="text-bold text-sm capitalize">
                {renderStatus(client)}
              </p>
            </div>
          );
        case "action":
          return (
            <div className="flex">
              <Action item={client} onViewOrders={handleViewOrders} />
            </div>
          );
      }
    },
    [data, handleViewOrders, renderStatus],
  );

  const sortedItems = React.useMemo(() => {
    return [...items]?.sort(
      (a: VendorClientListType, b: VendorClientListType) => {
        const first = a[sortDescriptor.column as keyof VendorClientListType];
        const second = b[sortDescriptor.column as keyof VendorClientListType];
        const cmp = first < second ? -1 : first > second ? 1 : 0;

        return sortDescriptor.direction === "descending" ? -cmp : cmp;
      },
    );
  }, [sortDescriptor, items]);

  const topContent = React.useMemo(() => {
    return (
      <div className="relative flex flex-col gap-4">
        <div className="flex justify-between gap-3 items-end my-2">
          <Input
            isClearable
            className="focus:outline-none focus:border-none"
            classNames={{
              base: "w-full sm:max-w-[44%] focus:outline-none focus:border-none",
              inputWrapper:
                "border-0 focus:border-0 focus:outline-none focus:border-none",
              input: "border-0 focus:outline-none focus:border-none",
            }}
            placeholder="Search..."
            size="sm"
            startContent={<CiSearch />}
            value={filterValue}
            onClear={() => {
              setFilterValue("");
            }}
            onValueChange={onSearchChange}
          />
        </div>
      </div>
    );
  }, [filterValue, onSearchChange]);

  const bottomContent = React.useMemo(() => {
    return (
      <div className="py-2 px-2 flex justify-between items-center">
        <Pagination
          showControls
          classNames={{
            item: "bg-pageBackground w-6 h-6 min-w-4 font-roboto",
            cursor: "w-6 h-6 min-w-4 font-roboto",
          }}
          color="default"
          isDisabled={hasSearchFilter}
          page={page}
          total={pages}
          variant="light"
          onChange={setPage}
        />
        <div className="flex justify-between items-center">
          <label className="flex items-center text-small font-roboto text-black font-light ">
            Items per page :&nbsp;
            <select
              className="border-none shadow-sm outline-none text-default-400 text-small font-roboto font-light px-1 py-0 rounded-md"
              onChange={onRowsPerPageChange}
              defaultValue={"20"}
            >
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
              <option value="25">25</option>
            </select>
          </label>
        </div>
      </div>
    );
  }, [hasSearchFilter, page, pages, onRowsPerPageChange]);

  const classNames = React.useMemo(
    () => ({
      th: [
        "bg-transparent",
        "text-tableHeaderColor",
        "border-b",
        "border-divider",
        "font-roboto",
        "font-regular",
        "text-sm",
      ],
      td: [
        "group-data-[first=true]:first:before:rounded-none",
        "group-data-[first=true]:last:before:rounded-none",
        "group-data-[middle=true]:before:rounded-none",
        "group-data-[last=true]:first:before:rounded-none",
        "group-data-[last=true]:last:before:rounded-none",
        "font-roboto",
        "font-normal",
        "text-textColorGrey",
      ],
      table: "min-h-[350px]",
      wrapper: "table-wrapper",
    }),
    [],
  );

  return (
    <div className="flex flex-col">
      <p className="text-black text-lg leading-8 font-roboto font-normal mt-[2%]">
        Active Clients
      </p>
      <Spacer size="xs" />
      <Table
        selectionMode="single"
        classNames={classNames}
        topContent={topContent}
        bottomContent={bottomContent}
        bottomContentPlacement="inside"
        sortDescriptor={sortDescriptor}
        onSortChange={setSortDescriptor}
      >
        <TableHeader columns={COLUMNS}>
          {(column) => (
            <TableColumn
              allowsSorting={column.sortable}
              key={column.key}
              align={column.key === "action" ? "end" : "start"}
              width={column.key === "action" ? 100 : undefined}
              className="text-center"
            >
              {column.name}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody
          emptyContent={!loading && "No rows to display."}
          items={sortedItems}
          isLoading={loading}
          loadingContent={<Spinner label="Loading..." />}
        >
          {(item) => (
            <TableRow
              key={`${item.client_code}`}
              className="cursor-pointer h-12 "
            >
              {(columnKey) => (
                <TableCell className="text-center">
                  {renderCell(item, columnKey)}
                </TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
}
