import * as React from "react";
import TopNav from "../../components/TopNav";
import { useParams } from "react-router-dom";
import { firestore, collection, getDocs } from "../../lib/db";
import { Skeleton } from "@nextui-org/react";
import { HelmetProvider, Helmet } from "react-helmet-async";

const Blogpage = () => {
  const { slug } = useParams();
  const fullURL = window.location.href;

  const [selectedBlog, setSelectedBlog] = React.useState({});
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(firestore, "blogs"));
        const fetchedData = querySnapshot.docs
          .map((doc) => ({
            ...doc.data(),
          }))
          .find((blog) => blog?.slug === slug);
        setSelectedBlog(fetchedData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [slug]);

  const renderContent = React.useCallback(() => {
    if (loading) {
      return (
        <>
          <Skeleton className="h-5 w-auto rounded-lg" />
          <Skeleton className="h-5 w-auto rounded-lg mt-2 mb-2" />
          <Skeleton className="h-80 w-auto rounded-lg" />
          <Skeleton className="h-20 w-auto rounded-lg mt-4" />
        </>
      );
    }
    return (
      <>
        <h1 className="text-3xl font-bold mb-4">{selectedBlog?.title}</h1>
        <div className="flex flex-row justify-between mb-5 mt-2">
          <p className="text-black font-semibold">
            {selectedBlog?.author && `Published By ${selectedBlog?.author}`}
          </p>
          <p className="text-gray-700"> {selectedBlog?.createdAt}</p>
        </div>
        {selectedBlog?.image && (
          <img
            className="w-full"
            src={selectedBlog?.image}
            alt={selectedBlog?.title}
          />
        )}
        <div className="text-gray-800 text-lg whitespace-pre-line mt-4">
          {selectedBlog?.content}
        </div>
      </>
    );
  }, [
    loading,
    selectedBlog?.author,
    selectedBlog?.content,
    selectedBlog?.createdAt,
    selectedBlog?.image,
    selectedBlog?.title,
  ]);

  return (
    <HelmetProvider>
      <Helmet>
        <title>
          {selectedBlog?.title ||
            "Asset Leasing and Financing | Connect Residuary Private Limited | blog"}
        </title>
        <link rel="canonical" href={fullURL} />
        <meta
          name="description"
          content="Connect Residuary Pvt Limited is a well-established asset leasing brand in India dealing in all types of IT Assets, Fit-outs, Plant and machinery etc. We have been leased over 2000cr + of assets in past 12 years of operations."
        />
        <meta
          name="keywords"
          content="leasing, rent, asset on rent, laptop on rent, leasing, laptop on lease, Corporate Asset Purchase, IT asset leasing, equipment leasing, Financing, small business financing, equipment financing, vehicle financing, asset management, Leaseback, capital equipment, business loans, commercial loans, equipment rentals, SME, tax benefits of leasing, lease accounting"
        />
      </Helmet>
      <div>
        <TopNav
          title={selectedBlog?.title || ""}
          showreadmore={false}
          showCarousel={false}
        />
        <div className="max-w-3xl mx-auto p-4 ">{renderContent()}</div>
      </div>
    </HelmetProvider>
  );
};

export default Blogpage;
