import * as React from "react";
import { Style } from "../../types.ts";

interface Props {
  children: React.ReactNode;
  varient?: "bold" | "light" | "normal";
}

export default function Cell({ children, varient = "light" }: Props) {
  return (
    <div className="flex flex-col" style={style[varient]}>
      <p className="text-bold text-sm  font-roboto line-clamp-2">{children}</p>
    </div>
  );
}

const style: Style = {
  bold: {
    lineHeight: "10px",
    padding: "0px",
    fontWeight: "600",
    color: "#535353",
  },
  light: {
    color: "#757575",
  },
  normal: {},
};
