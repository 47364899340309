import React from "react";
import { useLocation } from "react-router-dom";
import Main from "../../../components/ui/sidebar/Main";

interface LocationState {
  pdfUrl: string;
  pdfName: string;
}

const PDFViewerPage: React.FC = () => {
  const location = useLocation();
  const state = location.state as LocationState | null;

  // Use URL from state or fallback to an empty string
  const pdfUrl = state?.pdfUrl || "";

  // If pdfUrl is empty, show an error message
  if (!pdfUrl) {
    return <div>Error: No PDF provided.</div>;
  }

  return (
    <Main>
      <div style={{ width: "100%", height: "100vh", overflow: "hidden" }}>
        <iframe
          src={pdfUrl}
          style={{ width: "100%", height: "95%", border: "none" }}
          title="PDF Viewer"
        />
      </div>
    </Main>
  );
};

export default PDFViewerPage;
