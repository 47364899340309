import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/common/Header.js";
import HeadingBanner from "../../components/common/HeadingBanner.tsx";
import Contactform from "../../components/homeelments/Contactform.js";
import TitleHead from "../../components/common/TitleHead.tsx";
import InfoCard from "../../components/common/InfoCard.tsx";
import { CSRCardArray } from "../../assets/data/index.js";
import Benefits from "../../components/common/Benifits.tsx";

const CSR = () => {
  return (
    <div>
      <Helmet>
        <title>
          Asset Leasing and Financing | Connect Residuary Private Limited |
          Corporate Social Responsibility
        </title>
        <meta
          name="description"
          content="Connect Residuary Pvt Limited is a well-established asset leasing brand in India dealing in all types of IT Assets, Fit-outs, Plant and machinery etc. We have been leased over 2000cr + of assets in past 12 years of operations."
        />
        <meta
          name="keywords"
          content="leasing, rent, asset on rent, laptop on rent, leasing, laptop on lease, Corporate Asset Purchase, IT asset leasing, equipment leasing, Financing, small business financing, equipment financing, vehicle financing, asset management, Leaseback, capital equipment, business loans, commercial loans, equipment rentals, SME, tax benefits of leasing, lease accounting"
        />
      </Helmet>

      <div className="w-full">
        <Header />
        <div className="w-screen">
          <HeadingBanner
            imageUrl="https://storage.googleapis.com/connectrpl-landing-pagev3/siteimages/CSRBanner.png"
            text="Corporate Social Responsibility"
            desc="Connect’s Commitment to a safe and secure future"
          />
        </div>
        <div className="flex justify-center">
          <div className="w-[80%]">
            <p className="text-base font-roboto font-light w-full text-black text-center mt-10">
              At Connect, we believe success extends far beyond the bottom line.
              We are a company driven by purpose, and that purpose includes
              making a positive impact on the communities we serve and the
              environment we share. This commitment is reflected in our robust
              Corporate Social Responsibility (CSR) initiatives.
            </p>

            <TitleHead title="Past CSR Initiatives" subtitle="" />

            {CSRCardArray?.map((card, index) => (
              <InfoCard
                key={index}
                imageUrl={card.imageUrl}
                text={card.text}
                desc={card.desc}
                reverseOrder={card.reverseOrder}
              />
            ))}
            <Benefits />
            <Contactform />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CSR;
