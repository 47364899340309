import { create } from "zustand";
import { persist } from "zustand/middleware";
import { StoreType } from "../types";

const useStore = create(
  persist<StoreType>(
    (set) => ({
      authToken: undefined,
      setAuthToken: (authToken) => set(() => ({ authToken })),
      user: undefined,
      setUser: (user) => set({ user }),
      logout: () => set({ authToken: undefined, user: undefined }),
    }),
    {
      name: "localStore", // Name for the persisted store
    },
  ),
);

export default useStore;
