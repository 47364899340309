import React from "react";

interface InfoCardProps {
  imageUrl: string;
  text: string;
  desc: string;
  reverseOrder: boolean;
}
const InfoCard: React.FC<InfoCardProps> = ({
  imageUrl,
  text,
  desc,
  reverseOrder = true,
}) => {
  return (
    <div className="bg-white mt-[5vh]">
      <div className={`flex ${reverseOrder ? "flex-row-reverse" : "flex-row"}`}>
        <div className="w-1/2 p-4">
          <h2 className="text-black text-xl leading-8 font-roboto font-medium mb-4">
            {text}
          </h2>
          <p className="text-black text-base pb-3 relative font-light font-roboto">
            {desc}
          </p>
        </div>
        <div className="w-1/2 p-4">
          <img
            src={imageUrl}
            alt="Banner"
            className="w-full h-full object-cover"
          />
        </div>
      </div>
    </div>
  );
};

export default InfoCard;
