import React from "react";
import TopNav from "../../components/TopNav";
import { Helmet } from "react-helmet";

const Whyrent = () => {
  return (
    <div>
      <Helmet>
        <title>
          {" "}
          Asset Leasing and Financing | Connect Residuary Private Limited |
          whyrent{" "}
        </title>
        <meta
          name="description"
          content="Connect Residuary Pvt Limited is a well-established asset leasing brand in India dealing in all types of IT Assets, Fit-outs, Plant and machinery etc. We have been leased over 2000cr + of assets in past 12 years of operations."
        />
        <meta
          name="keywords"
          content="leasing, rent, asset on rent, laptop on rent, leasing, laptop on lease, Corporate Asset Purchase, IT asset leasing, equipment leasing, Financing, small business financing, equipment financing, vehicle financing, asset management, Leaseback, capital equipment, business loans, commercial loans, equipment rentals, SME, tax benefits of leasing, lease accounting"
        />
        {/* Add any other meta tags as needed */}
      </Helmet>

      <div>
        <TopNav
          bannerurl="https://storage.googleapis.com/connectrpl-landing-pagev3/banner1%20.png"
          title="Why rent"
          titledesc=""
          showreadmore={false}
        />
        <div className=" flex justify-center items-center">
          <div className=" w-[80%] ">
            <section
              style={{ offsetPosition: "normal" }}
              className="text-zinc-800 py-12 px-5"
            >
              <div className="px-4 m-auto">
                <div className="-m-3.5">
                  <p className="text-black text-3xl font-poppins font-medium text-center mb-3 lg:text-4xl">
                    Use our money to grow...
                  </p>
                  <div className="text-black text-[1.38rem] leading-7 font-poppins font-medium pb-3 ml-8">
                    RENT or BUY ?
                  </div>
                  <div className="relative  mb-3 p-12">
                    <h2 className="text-black bg-white border-t-red-600 border-t-2 text-xl font-poppins font-bold px-3 relative border-solid">
                      What are your objectives?
                    </h2>
                    <ul className="list-none">
                      <li className="text-black text-base text-justify list-item font-poppins">
                        Are you planning to purchase new equipment?
                      </li>
                      <li className="text-black text-base text-justify list-item font-poppins">
                        Are you looking to conserve capital?
                      </li>
                      <li className="text-black text-base text-justify list-item font-poppins">
                        Are you looking at an Asset Lite Balance sheet?
                      </li>
                      <li className="text-black text-base text-justify list-item font-poppins">
                        Are you looking for hedging technology obsolescence?
                      </li>
                      <li className="text-black text-base text-justify list-item font-poppins">
                        Has disposing off used equipment been a challenge?
                      </li>
                    </ul>

                    <p className="text-black text-xl font-semibold font-poppins">
                      If you have answered YES to any of these questions then,
                      renting should be an option to consider.
                    </p>
                  </div>
                </div>
                <div>
                  <h3 className="text-black text-2xl font-medium pb-1 font-poppins lg:ml-8">
                    Benefits
                  </h3>

                  <ul className="relative list-none">
                    <ul className="relative">
                      <li className="text-black text-base pl-5 relative list-item font-poppins">
                        Economical and more efficient way of paying for
                        equipment that depreciates in value and blocks capital
                      </li>
                    </ul>
                    <ul className="relative">
                      <li className="text-black text-base pl-5 relative list-item font-poppins">
                        Single window solution for multiple equipment class such
                        as IT, Furniture, Fit Outs, Office equipment etc.
                      </li>
                    </ul>
                    <ul className="relative">
                      <li className="text-black text-base pl-5 relative list-item font-poppins">
                        Flexible End of term Options – return, re-rent, upgrade
                        some or all Equipment
                      </li>
                    </ul>
                    <ul className="relative">
                      <li className="text-black text-base pl-5 relative list-item font-poppins">
                        Flexibility to choose the make, configuration and
                        purchase price.
                      </li>
                    </ul>
                    <ul className="relative">
                      <li className="text-black text-base pl-5 relative list-item font-poppins">
                        Enable Technology refresh through pre-defined end of
                        life strategy
                        <ul className="relative">
                          <li className="pl-5 relative list-item font-poppins">
                            Hedge against technology obsolescence
                          </li>
                        </ul>
                        <ul className="relative">
                          <li className="pl-5 relative list-item font-poppins">
                            Implement a refresh methodology that becomes part of
                            the overall business strategy
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="relative">
                      <li className="text-black text-base pl-5 relative list-item font-poppins">
                        Eliminate asset disposition concerns and cost
                        <ul className="relative">
                          <li className="pl-5 relative list-item font-poppins">
                            Implement a secure and environmentally friendly
                            disposal without the hassles
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="relative ">
                      <li className="text-black text-base pl-5 relative list-item font-poppins">
                        Ensure regulatory compliance
                      </li>
                    </ul>
                    <ul className="relative">
                      <li className="text-black text-base pl-5 relative list-item font-poppins">
                        Enable better planning
                        <ul className="relative">
                          <li className="pl-5 relative list-item font-poppins">
                            Make predictable payments instead of a large upfront
                            cash purchase and have better financial planning.
                          </li>
                        </ul>
                        <ul className="relative">
                          <li className="pl-5 relative list-item font-poppins">
                            Transition from existing equipment, acquire new
                            technology, manage that technology then retire it
                            cost effectively
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="relative">
                      <li className="text-black text-base pl-5 relative list-item font-poppins">
                        Spread costs over the rent term with better ROI
                        <ul className="relative">
                          <li className="pl-5 relative list-item font-poppins">
                            Lower Total Cost of Ownership over the useful life
                            of the solution
                          </li>
                        </ul>
                        <ul className="relative">
                          <li className="pl-5 relative list-item font-poppins">
                            Improves efficiency ratios
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </ul>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Whyrent;
