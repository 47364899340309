import { Home, Search } from "lucide-react";

export const servicesCardArray = [
  {
    imageUrl:
      "https://storage.googleapis.com/connectrpl_images/companywebsite/ServicesImage11.png",
    text: "New Equipment Rentals",
    desc: "Connect  alleviates client’s investment risk by offering customized options to help ensure that they have ready access to the new  equipment they need even during their initial rental term. New Equipment  rental can free up your business  capital, release your business from  the costs associated with equipment ownership. It’s an ideal way to use  equipment while you conserve your cash. With flexible options  of month-to-month or rent-to-own programs, Connect ensures you get the  most out of equipment. You only pay for the term you use. Renting with  Connect is on a consultative approach with transparency. Our expert  sales engineers will find the best solution for your need.",
    reverseOrder: false,
  },
  {
    imageUrl:
      "https://storage.googleapis.com/connectrpl_images/companywebsite/ServicesImage21.png",
    text: "Sell and Rent Back",
    desc: "If  client owns equipment, but wants the flexibility and disposition  benefits of renting, they can sell owned assets at WDV and rent them  back for a mutually agreed term. As a result, client enjoys benefits  like an immediate cash infusion from the sale, expedited migration plan  to a new solution, safeguard the availability of capital stock, and  reduced risk of obsolescence. With this service, client can convert its  capital assets to liquid assets.",
    reverseOrder: true,
  },
  {
    imageUrl:
      "https://storage.googleapis.com/connectrpl_images/companywebsite/ServicesImage3.png",
    text: "Short Term Rentals",
    desc: "When  you have a requirement for something very specific, short term renting  is the ideal solution. Short term renting can be very beneficial when  the requirement is as short as 1 month to 1 year. Short Term Renting can  be used for certain projects which require specific hardware, testing  of newly developed software products on different hardware and  configurations, as standby equipment until the ordered equipment  actually arrives from suppliers, training and conferences, growth in  your business or for temporary staff.",
    reverseOrder: false,
  },
  {
    imageUrl:
      "https://storage.googleapis.com/connectrpl_images/companywebsite/ServicesImage4.png",
    text: "Refresh Plan",
    desc: "A  plan which gives our clients the flexibility to return / upgrade their  existing portfolio and refresh their equipment prior to the expiry of  the term. This helps our clients to develop a planned procurement  strategy with the certainty of operating cash flows, and gives the  flexibility to the client to upgrade what they need to and when they  need to.",
    reverseOrder: true,
  },
];

export const startupsCardArray = [
  {
    imageUrl:
      "https://storage.googleapis.com/connectrpl_images/companywebsite/StartupsIMG11.png",
    text: "Tailored Asset Acquisition",
    desc: "We recognize that every startup has unique needs and timelines. Our  leasing experts work closely with you to understand your specific asset  requirements, whether it's cutting-edge technology for software  development or heavy machinery for manufacturing. By tailoring our  leasing agreements to align with your operational and financial goals,  we ensure that you get the right tools at the right time, keeping your  operations smooth and efficient.",
    reverseOrder: false,
  },
  {
    imageUrl:
      "https://storage.googleapis.com/connectrpl_images/companywebsite/StartupsIMG22.png",
    text: "Scalability and Growth Support",
    desc: "As your startup grows, your needs will inevitably change. Our leasing  solutions are built to accommodate the dynamic nature of your business.  This means you can upgrade or expand your leased assets in line with  your growth trajectory. We support your scalability by adapting our  terms and available equipment, ensuring that you are always equipped  with the best tools to face your next challenge.",
    reverseOrder: true,
  },
  {
    imageUrl:
      "https://storage.googleapis.com/connectrpl_images/companywebsite/StartupsIMG33.png",
    text: "Industry-Specific Expertise",
    desc: "Our team brings specialized knowledge across various industries,  allowing us to provide insights and leasing options that go beyond  general needs. Whether you’re in fintech, healthtech, or manufacturing,  we offer advice and assets that are most relevant and beneficial to your  sector. This industry-specific approach not only enhances your  operational capacity but also ensures you are always ahead of the curve  in a competitive landscape.",
    reverseOrder: false,
  },
  {
    imageUrl:
      "https://storage.googleapis.com/connectrpl_images/companywebsite/StartupsIMG44.png",
    text: "Financial Flexibility for Innovators",
    desc: "We understand that managing cash flow is crucial for startups. Our  leasing programs are designed to offer financial flexibility—minimizing  initial costs and spreading out payments over time. This approach allows  you to retain capital for critical investments in research and  development, marketing, and other key areas that drive business growth.  By easing financial pressure, we help you keep your focus on innovation  and market expansion.",
    reverseOrder: true,
  },
];

export const corporatesCardArray = [
  {
    imageUrl:
      "https://storage.googleapis.com/connectrpl_images/companywebsite/corporatesIMG1.png",
    text: "Customized Asset Leasing ",
    desc: "Every corporation has unique demands based on its industry, scale, and  operational tempo. We provide customized leasing solutions tailored to  meet these specific requirements. From office technology and machinery  to vehicles and industrial equipment, our program ensures that your  assets remain up-to-date and aligned with your business objectives,  allowing for peak operational performance.",
    reverseOrder: false,
  },
  {
    imageUrl:
      "https://storage.googleapis.com/connectrpl_images/companywebsite/corporatesIMG2.png",
    text: "Scalable Solutions for Dynamic Enterprises",
    desc: "As your corporation grows and evolves, so do your asset needs. Our  leasing solutions are designed to be as dynamic as your business,  offering scalable options that adapt to your changing requirements. This  flexibility allows you to expand, upgrade, or modify your leased assets  portfolio in sync with your company’s growth and shifting market  conditions, ensuring continuous operational efficiency.",
    reverseOrder: true,
  },
  {
    imageUrl:
      "https://storage.googleapis.com/connectrpl_images/companywebsite/corporatesIMG3.png",
    text: "Expert Advisory Services",
    desc: "Leverage our expertise to make informed decisions about asset  management. Our team of specialists provides in-depth industry insights  and strategic advisory services, helping you to optimize your leasing  arrangements. By understanding the trends and forecasts in your specific  industry, we assist in planning your asset leasing in a way that  supports both current efficiency and future growth.",
    reverseOrder: false,
  },
  {
    imageUrl:
      "https://storage.googleapis.com/connectrpl_images/companywebsite/corporatesIMG4.png",
    text: "Financial Strategy Alignment",
    desc: "Our Corporate Leasing Program is designed not just to supply assets but  to integrate seamlessly with your corporate financial strategies. We  offer structures that can improve cash flow management, enhance tax  efficiency, and align with corporate budgeting processes. This strategic  financial alignment helps in maintaining liquidity and investing  capital into core business activities that drive competitive advantage.",
    reverseOrder: true,
  },
];

export const msmeCardArray = [
  {
    imageUrl:
      "https://storage.googleapis.com/connectrpl_images/companywebsite/msmeIMG1.png",
    text: "Flexible Asset Solutions",
    desc: "We understand that MSMEs require agility in their operations. Our  leasing solutions offer the flexibility to choose from a wide range of  assets—from manufacturing equipment to IT technology—tailored to fit the  diverse needs of small businesses. With minimal initial investment and  manageable monthly payments, you can preserve cash flow and focus on  what matters most: growing your business.",
    reverseOrder: false,
  },
  {
    imageUrl:
      "https://storage.googleapis.com/connectrpl_images/companywebsite/msmeIMG2.png",
    text: "Customized Leasing Terms",
    desc: "Recognizing that every MSME operates differently, we offer customized  leasing terms that can be adjusted based on your specific business  cycles and financial capabilities. Whether it's seasonal upgrades or  scaling operations, our program is designed to align with your  business's pace and scale, providing the support you need when you need  it.",
    reverseOrder: true,
  },
  {
    imageUrl:
      "https://storage.googleapis.com/connectrpl_images/companywebsite/msmeIMG3.png",
    text: "Quick and Easy Application Process",
    desc: "Time is a precious commodity for MSMEs, and lengthy processes can stifle  growth. Our application process is streamlined and straightforward,  ensuring you can access necessary assets quickly and without hassle. We  prioritize efficiency to minimize downtime, helping you keep your  operations running smoothly with the latest equipment.",
    reverseOrder: false,
  },
  {
    imageUrl:
      "https://storage.googleapis.com/connectrpl_images/companywebsite/msmeIMG4.png",
    text: "Support Beyond Leasing",
    desc: "Our commitment to supporting MSMEs extends beyond simple asset leasing.  We provide ongoing support, including maintenance and upgrade options,  ensuring that the assets you lease continue to serve your business  optimally throughout the lease term. This approach helps you avoid the  pitfalls of outdated technology and equipment, keeping you competitive  in a fast-paced market.",
    reverseOrder: true,
  },
];

export const appleCardArray = [
  {
    imageUrl:
      "https://storage.googleapis.com/connectrpl_images/companywebsite/appleIMG1.png",
    text: "Apple Business Leasing",
    desc: "Get the maximum benefits of leasing Apple products with Connect, via Connect and Apple deep partnership for Indian Markets. Covering the entire product suite of Apple, you can get tailor-made custom leasing solutions for Apple at best rentals in the market from Connect. ",
    reverseOrder: false,
  },
  {
    imageUrl:
      "https://storage.googleapis.com/connectrpl_images/companywebsite/appleIMG2.png",
    text: "Easy Financing",
    desc: "Through Connect Equipment Leasing Private Limited, Connect’s own NBFC entity, we provide access to quick and easy capital requirements to propel any of your Apple Leasing requirements in a fast, digital and seamless model. ",
    reverseOrder: true,
  },
];

export const valueaddedCardArray = [
  {
    imageUrl:
      "https://storage.googleapis.com/connectrpl_images/companywebsite/valueIMG1.png",
    text: "Asset Disposable Services",
    desc: "A  service designed to help clients uncover the value of unwanted  equipment. With our Asset disposable Services, we manage the retirement  of client’s equipment, we do it in a way that can help the client to  safeguard their proprietary information, complies with applicable  environmental laws and regulations and may even provide you with a  financial return on remarketing able equipment.",
    reverseOrder: false,
  },
  {
    imageUrl:
      "https://storage.googleapis.com/connectrpl_images/companywebsite/valueIMG2.png",
    text: "Asset Verification and Tagging",
    desc: "Through MyConnect platform, view all your assets at a single dashboard, attach system generated QR codes and verify status, details and location of each asset via mobile and web. ",
    reverseOrder: true,
  },
  {
    imageUrl:
      "https://storage.googleapis.com/connectrpl_images/companywebsite/valueIMG3.png",
    text: "On Premise Testing Service",
    desc: "Connect provides softwares for evaluating health of your IT assets digitally. Our well equipped technical team can visit your location, do a comprehensive software driven Quality checks for your laptop assets and shares recommendations for optimal device performance and management. ",
    reverseOrder: false,
  },
  {
    imageUrl:
      "https://storage.googleapis.com/connectrpl_images/companywebsite/valueIMG4.png",
    text: "Strategic & Advisory Services",
    desc: "This  includes consultancy services on Asset Planning, Asset Tracking,  Physical Inventory & Asset Audit, Guidance on useful life of assets  and Cost Benefit analysis on Rent v/s Buy.",
    reverseOrder: true,
  },
];

export const assetDisposalCardArray = [
  {
    imageUrl:
      "https://storage.googleapis.com/connectrpl_images/companywebsite/assetdisIMG1.png",
    text: "Asset Disposal Service",
    desc: "Sell your old equipments, from laptops to ATM machines, networking devices, plant and factory machines, surveillance assets, furniture and fitouts etc with Connect for best in industry rates and an complete end to end plan. Our Team ensures best in class quality checks, pricing, packing and movement of assets, via our entity, GoRevive Private Limited. ",
    reverseOrder: false,
  },
  {
    imageUrl:
      "https://storage.googleapis.com/connectrpl_images/companywebsite/assetdisIMG2.png",
    text: "Last Mile Logistics",
    desc: "Our team is well equipped to provide software based automated quality checks at your doorstep to give you the best pricing for your assets. With GoRevive Private Limited, Connect’s asset recovery company,  the micro-logistics in asset disposal are handled professionally and in a transparent manner, PAN India",
    reverseOrder: true,
  },
];

export const CSRCardArray = [
  {
    imageUrl:
      "https://storage.googleapis.com/connectrpl_images/companywebsite/assetdisIMG1.png",
    text: "Transforming Learning Spaces at The Foundation Academy, Midnapore",
    desc: "At Connect,  we believe in the power of education to transform lives. As part of our commitment to Corporate Social Responsibility (CSR), we recently undertook a furniture donation initiative at The Foundation Academy, a school located in Midnapore, West Bengal. This initiative aimed to create a more conducive learning environment for students and staff by providing them with essential furniture.",
    reverseOrder: false,
  },
  {
    imageUrl:
      "https://storage.googleapis.com/connectrpl_images/companywebsite/assetdisIMG2.png",
    text: "Omega/Cambridge English High School",
    desc: "Connect and  Members of RCM Mulund South, extended thier support to Omega / Cambridge English High School, a school fostering education for underprivileged students. Recognizing their need for enhanced learning facilities, we donated essential equipment. The computer lab received modern computers, promoting digital literacy for students.  In the chemistry lab, new equipment and supplies enable hands-on experiments, solidifying scientific understanding. Finally, the library was enriched with bookshelves, comfortable seating, and a diverse collection of books, creating a vibrant space that fosters a love for reading. Through these contributions, we aim to empower students at Omega School Upliftment to excel in their academic endeavors.",
    reverseOrder: true,
  },
];

export const benefitsData = [
  {
    point:
      "Economical and more efficient way of paying for equipment that depreciates in value and blocks capital",
  },
  {
    point:
      "Single window solution for multiple equipment class such as IT, Furniture, Fit Outs, Office equipment etc.",
  },
  {
    point:
      "Flexible End of term Options – return, re-rent, upgrade some or all Equipment",
  },
  {
    point: "Flexibility to choose the make, configuration and purchase price.",
  },
  {
    point:
      "Enable Technology refresh through pre-defined end of life strategy:",
    subPoints: [
      " Hedge against technology obsolescence",
      "Implement a refresh methodology that becomes part of the overall business strategy",
    ],
  },
  {
    point: "Eliminate asset disposition concerns and cost:",
    subPoints: [
      "Implement a secure and environmentally friendly disposal without the hassles",
    ],
  },
  {
    point: "Ensure regulatory compliance",
  },
  {
    point: "Enable better planning:",
    subPoints: [
      "Make predictable payments instead of a large upfront cash purchase and have better financial planning",
      "Transition from existing equipment, acquire new technology, manage that technology then retire it cost effectively",
    ],
  },
  {
    point: "Spread costs over the rent term with better ROI:",
    subPoints: [
      "Lower Total Cost of Ownership over the useful life of the solution",
      "Improves efficiency ratios",
    ],
  },
];

export const industriesArray = [
  {
    imageUrl:
      "https://storage.googleapis.com/connectrpl_images/companywebsite/Banking.png",
    text: "Banking Infrastructure, ATMs",
  },
  {
    imageUrl:
      "https://storage.googleapis.com/connectrpl_images/companywebsite/It.png",
    text: "Information Technology",
  },
  {
    imageUrl:
      "https://storage.googleapis.com/connectrpl_images/companywebsite/Factory.png",
    text: "BPO and Managed Services",
  },
  {
    imageUrl:
      "https://storage.googleapis.com/connectrpl_images/companywebsite/BpoServices.png",
    text: "Manufacturing",
  },
  {
    imageUrl:
      "https://storage.googleapis.com/connectrpl_images/companywebsite/Retail.png",
    text: "Retail",
  },
  {
    imageUrl:
      "https://storage.googleapis.com/connectrpl_images/companywebsite/Medical.png",
    text: "Medical Infrastructure",
  },
  {
    imageUrl:
      "https://storage.googleapis.com/connectrpl_images/companywebsite/Media.png",
    text: "Media",
  },
  {
    imageUrl:
      "https://storage.googleapis.com/connectrpl_images/companywebsite/Qsr.png",
    text: "F&B, QSR",
  },
];

export const dashPaths = [
  "/login",
  "/epp/login",
  "/dashboard",
  "/dashboard/rentalschedule",
  "/dashboard/inventory",
  "/dashboard/all-inventory",
  "/dashboard/invoices",
  "/dashboard/support",
  "/dashboard/contact",
  "/dashboard/documents",
  "/dashboard/inventorylist",
  "/dashboard/newbusiness",
  "/dashboard/automation",
  "/dashboard/employee-directory",
  "/dashboard/add-employees",
  "/dashboard/products",
  "/dashboard/activerentals",
  "/dashboard/managerentals",
  "/dashboard/admin-settings",
  "/dashboard/orders",
  "/dashboard/pdfview",
  "/dashboard/purchasemanagement",
  "/client",
  "/client/view-orders",
  "/client/view-order-detail",
  "/client/upload-product-details",
  "/dashboard/add-address",
  "/client/view-vendor-orders",
  "/client/vendor-profile",
  "/client/vendor-inventory",
  "/dashboard/add-inventory",
  "/dashboard/employee-active-rentals",
  "/client/po-detail",
  "/client/po-upload-product",
  "/dashboard/order-details",
  "/client/vendor-invoice",
  "/client/po-upload-invoice",
  "/dashboard/purchasedetails",
  "/dashboard/products/upload-epp-products",
  "/pre-procurement-documents",
  "/pre-procurement-documents/upload",
];

export const sidebarLinks = [
  {
    imgURL: "/assets/home.svg",
    route: "/",
    label: "Home",
    logocmp: <Home />,
  },
  {
    imgURL: "/assets/search.svg",
    route: "/dashboard",
    label: "Documents",
    logocmp: <Search />,
  },
];

export const Data = [
  {
    id: 1,
    year: 2016,
    userGain: 80000,
    userLost: 823,
  },
  {
    id: 2,
    year: 2017,
    userGain: 45677,
    userLost: 345,
  },
  {
    id: 3,
    year: 2018,
    userGain: 78888,
    userLost: 555,
  },
  {
    id: 4,
    year: 2019,
    userGain: 90000,
    userLost: 4555,
  },
  {
    id: 5,
    year: 2020,
    userGain: 4300,
    userLost: 234,
  },
];

export const servicesData = [
  {
    imageUrl:
      "https://storage.googleapis.com/connectrpl_images/companywebsite/ServicesImage11.png",
    text: "New Equipment Rentals",
    url: "https://www.connectrpl.com/services",
  },
  {
    imageUrl:
      "https://storage.googleapis.com/connectrpl_images/companywebsite/ServicesImage21.png",
    text: "Sell and Lease Back",
    url: "https://www.connectrpl.com/services",
  },
  {
    imageUrl:
      "https://storage.googleapis.com/connectrpl_images/companywebsite/ServicesImage3.png",
    text: "Short Term Rentals",
    url: "https://www.connectrpl.com/services",
  },
  {
    imageUrl:
      "https://storage.googleapis.com/connectrpl_images/companywebsite/ServicesImage4.png",
    text: "Refresh Plan ",
    url: "https://www.connectrpl.com/services",
  },
  {
    imageUrl:
      "https://storage.googleapis.com/connectrpl_images/companywebsite/appleIMG1.png",
    text: "Apple For Business",
    url: "https://www.connectrpl.com/appleforbusiness",
  },
  {
    imageUrl:
      "https://storage.googleapis.com/connectrpl_images/companywebsite/assetdisIMG2.png",
    text: "Asset Disposal",
    url: "https://www.connectrpl.com/assetdisposal",
  },
];

export const colorMapping = {
  black: "#000000",
  white: "#FFFFFF",
  silver: "#C0C0C0",
  gold: "#FFCC00",
  blue: "#007A7A",
  spacegray: "#A1A1A1",
  red: "#FF0000",
  green: "#00FF00",
  pink: "#FFC0CB",
  // Add more colors as needed
};
