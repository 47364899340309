import React from "react";
import { EmployeeDirectoryType } from "../../../types.ts";
import Spacer from "../../../components/ui/Spacer.tsx";
import useApi from "../../../hooks/useApi.tsx";
import { GenerateEmployeePassword, GetEppEmployesApi } from "../../../apis.ts";
import EmployeeList from "../../../components/pages/employee-directory/List.tsx";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Main from "../../../components/ui/sidebar/Main.tsx";

const EmployeeDirectory = () => {
  const { makeApiCall } = useApi();
  const navigate = useNavigate();

  const [employees, setEmployees] = React.useState<EmployeeDirectoryType[]>([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    makeApiCall(GetEppEmployesApi())
      .then((response) => {
        setEmployees(response?.data ?? ([] as EmployeeDirectoryType[]));
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, [makeApiCall]);

  const generateEmployeePassword = React.useCallback(
    (name: string, email: string) => {
      return makeApiCall(GenerateEmployeePassword(name, email))
        .then(() => toast.success("Employee password generate succesfully"))
        .catch(() => toast.error("Employee password generate failed"));
    },
    [makeApiCall],
  );

  const navigateToAddEmployee = React.useCallback(() => {
    navigate("/dashboard/add-employees");
  }, [navigate]);

  return (
    <Main>
      <div className="text-black bg-pageBackground px-10 min-h-screen">
        <Spacer size="sm" />
        <EmployeeList
          employees={employees}
          loading={loading}
          generateEmployeePassword={generateEmployeePassword}
          navigateToAddEmployee={navigateToAddEmployee}
        />
      </div>
    </Main>
  );
};

export default EmployeeDirectory;
