import React from "react";
import { Colors } from "../../assets/colors";

const ProgrammeCard = ({
  imageUrl,
  cardtitle,
  content,
  onClick = () => {},
}) => {
  return (
    <div onClick={onClick} className="flex justify-center cursor-pointer ">
      <div className="text-zinc-800 relative mb-4 p-8  rounded-md  ">
        {/* Image */}
        <div className="flex   items-center">
          {imageUrl && (
            <img
              src={imageUrl}
              alt="Service Image"
              className="w-8 h-8 mb-4 rounded-md  shadow-sm"
            />
          )}

          {/* Title */}
          <h2 className="text-black text-xl leading-8 font-roboto font-medium mb-4 ml-3">
            {cardtitle}
          </h2>
        </div>
        {/* Content */}
        <p className=" text-justify font-roboto text-black text-base pb-3 relative font-light">
          {content}
        </p>
        {/* Read More */}
        <p
          style={{ offsetPosition: "normal", color: Colors.buttonprimary }}
          className="underline font-roboto font-normal text-base cursor-pointer"
        >
          Learn more
        </p>
      </div>
    </div>
  );
};

export default ProgrammeCard;
