import React from "react";
import { OrderEppList } from "../../../types.ts";
import Spacer from "../../../components/ui/Spacer.tsx";
import useApi from "../../../hooks/useApi.tsx";
import { GetEppOrdersApi } from "../../../apis.ts";
import OrdersEpp from "../../../components/pages/epp/orders/List.tsx";
import Main from "../../../components/ui/sidebar/Main.tsx";

const ActiveRentals = () => {
  const { makeApiCall } = useApi();

  const [eppOrders, setEppOrderseNumbers] = React.useState<OrderEppList[]>([]);
  const [invoiceLoading, setInvoiceLoading] = React.useState(false);

  // Rental schedule get API call
  React.useEffect(() => {
    setInvoiceLoading(true);
    makeApiCall(GetEppOrdersApi())
      .then((response) => {
        if (response !== undefined && Array.isArray(response?.orders)) {
          setEppOrderseNumbers(response?.orders);
        }
      })
      .catch((error) => console.error(error))
      .finally(() => setInvoiceLoading(false));
  }, [makeApiCall]);

  return (
    <Main>
      <div className="text-black bg-pageBackground px-10 min-h-screen">
        <Spacer size="sm" />
        <OrdersEpp eppOrders={eppOrders} loading={invoiceLoading} />
      </div>
    </Main>
  );
};

export default ActiveRentals;
