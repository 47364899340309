import { OrderType } from "@/src/types";
import * as React from "react";

interface Props {
  item: OrderType;
  onViewOrderDetail: (item: OrderType) => void;
}

export default function Action({ item, onViewOrderDetail }: Props) {
  const handleViewOrders = React.useCallback(() => {
    return onViewOrderDetail(item);
  }, [item, onViewOrderDetail]);

  return (
    <div className="flex flex-row justify-end gap-x-3 2xl:gap-x-4 whitespace-nowrap">
      <button
        onClick={handleViewOrders}
        className="text-textColorGrey cursor-pointer font-roboto font-normal"
      >
        View Details
      </button>
    </div>
  );
}
