export default function getFormattedDate(dateString: string): string {
  return new Intl.DateTimeFormat("en-US", { dateStyle: "long" }).format(
    new Date(dateString),
  );
}

export function goTop() {
  window.scrollTo({
    top: 0,
    behavior: "smooth", // for smooth scrolling
  });
}
