import React from "react";

const GoReviveCard = ({
  imageUrl,
  cardtitle,
  cardsubtitle,
  content1,
  content2,
  hyperlinkirl,
  margintop,
}) => {
  return (
    <div className="bg-white shadow-lg rounded-lg p-6 w-[35rem]">
      {/* Logo */}
      <div
        className="flex justify-center"
        style={{ marginTop: `${margintop}px` }}
      >
        <img src={imageUrl} alt="Company Logo" className="mt-5 w-44" />
      </div>
      {/* Title */}
      <div
        className="text-2xl sm:text-xl font-roboto text-center font-light text-gray-700 "
        style={{ marginTop: `${margintop}px` }}
      >
        <br />
        {cardtitle}
      </div>
      {/* Sub-title */}
      <div className="text-center text-black text-xl leading-8 font-roboto font-medium mb-4 ml-3">
        <br />
        {cardsubtitle}
      </div>
      {/* Description */}
      <div className="text-center font-roboto text-black text-base pb-4 relative font-light">
        {content1}
        <br />
        <br />
        {content2}
      </div>
      {/* Button */}
      <div className="flex justify-center">
        <a
          href={hyperlinkirl}
          target="_blank"
          className="bg-[#00006D] text-white text-sm font-normal py-2 px-4 rounded-md"
        >
          Visit Website
        </a>
      </div>
    </div>
  );
};

export default GoReviveCard;
