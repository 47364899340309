import React from "react";
import ServiceCard from "../common/ServiceCard";
import { useNavigate } from "react-router-dom";
import TitleHead from "../common/TitleHead.tsx";
import { goTop } from "../../lib/methods.ts";

const Ourservices = () => {
  const navigate = useNavigate();

  const navigateEquipmentLeasing = React.useCallback(() => {
    window.open("/services", "_blank");
  }, []);

  const navigateEndOfLease = React.useCallback(() => {
    window.open("/assetdisposal", "_blank");
  }, []);
  const navigateMyConnect = React.useCallback(() => {
    window.open("/login", "_blank");
  }, []);

  return (
    <section
      style={{ offsetPosition: "normal" }}
      className="text-zinc-800  relative w-full flex justify-center items-center mt-16"
    >
      <div className=" w-[80%] m-auto px-4">
        <TitleHead
          title="Services"
          subtitle="What sets up apart?"
          summary="With over 40+ Years leasing experience, our PAN India presence, and  our unmatched end-of-lease services, Connect has the tools you need to stay at the top of your industry."
        />
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mx-auto my-8">
          <ServiceCard
            imageUrl={
              "https://storage.googleapis.com/connectrpl_images/companywebsite/serviceimage1.png"
            }
            cardtitle={"Equipment Leasing"}
            content={
              "Looking for leasing of any equipment for your business, our leasing portfolio covers all types of assets, from factory setups, IT, design and architecture, FMCG industries"
            }
            onClick={navigateEquipmentLeasing}
          />
          <ServiceCard
            imageUrl={
              "https://storage.googleapis.com/connectrpl_images/companywebsite/serviceimage2.png"
            }
            cardtitle={"My Connect"}
            content={
              "Organize and streamline your leased assets all with just a couple clicks. Our free online asset"
            }
            onClick={navigateMyConnect}
          />
          <ServiceCard
            imageUrl={
              "https://storage.googleapis.com/connectrpl_images/companywebsite/serviceimage3.png"
            }
            cardtitle={"End of Lease"}
            content={
              "We designed end-of-lease process to be secure, flexible and easy to manage. Our certified returns "
            }
            onClick={navigateEndOfLease}
          />
        </div>
      </div>
    </section>
  );
};

export default Ourservices;
