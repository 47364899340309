import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

export const exportToExcel = <T>(data: T[], fileName: string): void => {
  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  const wbout: ArrayBuffer = XLSX.write(wb, {
    bookType: "xlsx",
    type: "array",
  });
  const blob: Blob = new Blob([wbout], { type: "application/octet-stream" });
  saveAs(blob, `${fileName}.xlsx`);
};

export const capitalizeFirstLetter = (text: string, maxLength: number) => {
  const truncatedText = text?.slice(0, maxLength) ?? "";
  return truncatedText
    ?.toLowerCase()
    ?.replace(/\b\w/g, (char) => char.toUpperCase());
};

export function addDays(date: string, numberOfDays: number) {
  const newDate = new Date(date);

  newDate.setDate(newDate.getDate() + numberOfDays);

  const year = newDate.getFullYear();
  const month = String(newDate.getMonth() + 1).padStart(2, "0"); // Add leading zero if needed
  const day = String(newDate.getDate()).padStart(2, "0"); // Add leading zero if needed

  return `${year}-${month}-${day}`;
}

export function getDateTwoMonthsAgo() {
  const today = new Date();
  const twoMonthsAgo = new Date(today.setMonth(today.getMonth() - 2));
  const year = twoMonthsAgo.getFullYear();
  const month = String(twoMonthsAgo.getMonth() + 1).padStart(2, "0"); // Adding padding if month is single digit
  const day = String(twoMonthsAgo.getDate()).padStart(2, "0"); // Adding padding if day is single digit
  return `${year}-${month}-${day}`;
}

export function getDateThreeMonthsAhead() {
  const today = new Date();
  const threeMonthsAhead = new Date(today.setMonth(today.getMonth() + 3));
  const year = threeMonthsAhead.getFullYear();
  const month = String(threeMonthsAhead.getMonth() + 1).padStart(2, "0"); // Adding padding if month is single digit
  const day = String(threeMonthsAhead.getDate()).padStart(2, "0"); // Adding padding if day is single digit
  return `${year}-${month}-${day}`;
}

export const formatDateIntl = (dateString: string): string => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "long",
    year: "numeric",
  };
  return new Intl.DateTimeFormat("en-GB", options).format(date);
};
