import * as React from "react";
import { Tabs, Tab } from "@nextui-org/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Spacer from "../../../../../components/ui/Spacer.tsx";
import Row from "../../../../../components/ui/Row.tsx";
import Button from "../../../../../components/ui/Button.tsx";
import OrderDetailList from "./OrderDetailList";
import ProductDetailList from "./ProductDetailList.tsx";

export default function OrderDetailView() {
  const [selected, setSelected] = React.useState<React.Key>("order-details");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const companyCode = searchParams.get("company_code") ?? "";
  const companyName = searchParams.get("company_name") ?? "";
  const navigateToUploadProductDetails = React.useCallback(() => {
    return navigate(
      `/client/upload-product-details?company_name=${encodeURIComponent(companyName)}&company_code=${encodeURIComponent(companyCode)}`,
    );
  }, [companyCode, companyName, navigate]);

  return (
    <div className="flex flex-col mt-[2%]">
      <Row justifyContent="space-between" alignItems="center">
        <p className="text-black text-lg leading-8 font-roboto font-normal">
          Assests
        </p>
        <Button
          color="default"
          variant="bordered"
          className="font-roboto"
          size="sm"
          radius="sm"
          onClick={navigateToUploadProductDetails}
        >
          Upload Product Details
        </Button>
      </Row>
      <Spacer size="xs" />
      <Tabs
        aria-label="Options"
        selectedKey={selected.toString()}
        onSelectionChange={setSelected}
      >
        <Tab key="order-details" title="Order Details">
          <OrderDetailList />
        </Tab>
        <Tab key="product-details" title="Product Details">
          <ProductDetailList />
        </Tab>
      </Tabs>
    </div>
  );
}
