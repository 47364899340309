import React from "react";
import { TbUserSquareRounded } from "react-icons/tb";

interface CardProps {
  user_name: string;
  user_email: string;
  cardTitle: string;
  level: number;
  designation?: string;
  phone?: string;
}

const Card: React.FC<CardProps> = ({
  user_name,
  user_email,
  cardTitle,
  level,
  designation = "",
  phone = "",
}) => {
  return (
    <div className=" w-[50%] py-4 px-4 mb-5 rounded-lg shadow-md bg-white">
      <div>
        <div className=" flex flex-row justify-between items-center mb-[10%]">
          <div className="flex flex-row items-center">
            <TbUserSquareRounded size={20} opacity={0.6} />
            <p className="text-textColorSecondary font-roboto font-normal ml-2 text-base">
              {cardTitle}
            </p>
          </div>

          <div className="bg-bgButtonGreen rounded-lg px-2 py-1">
            {" "}
            <p className="text-black font-roboto font-medium text-xs rounded-xl">
              Level-{level}
            </p>
          </div>
        </div>

        <p className="text-black font-roboto font-medium text-base mb-2">
          <span className="text-black font-roboto font-light text-base">
            Name:{" "}
          </span>
          {user_name}
        </p>
        {designation && designation != "" ? (
          <p className="text-black font-roboto font-medium text-base mb-2">
            <span className="text-black font-roboto font-light text-base">
              Designation:{" "}
            </span>
            {designation}
          </p>
        ) : null}

        <p className="text-black font-roboto font-medium text-base">
          <span className="text-black font-roboto font-light text-base">
            Email:{" "}
          </span>
          {user_email}
        </p>
        {phone && phone != "" ? (
          <p className="text-black font-roboto font-medium text-base mb-2">
            <span className="text-black font-roboto font-light text-base">
              Phone:{" "}
            </span>
            {phone}
          </p>
        ) : null}
      </div>
    </div>
  );
};

export default Card;
