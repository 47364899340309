import * as React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import { CiSaveDown2 } from "react-icons/ci";
import { exportToExcel } from "../../../../../../src/utils/utils";
import { AddEppVendorInventoryApi } from "../../../../../../src/apis";
import Spacer from "../../../../../../src/components/ui/Spacer";
import Button from "../../../../../../src/components/ui/Button";
import FileInput from "../../../../../../src/components/ui/FileInput";
import Row from "../../../../../../src/components/ui/Row";
import useApi from "../../../../../../src/hooks/useApi";
import BreadcrumbComponent from "../../../../../../src/components/ui/Breadcrumb.tsx";

const INTIAL_VALUES = {
  vendor_inventory: null as File | null,
};

export default function AddInventory() {
  const { makeApiCall } = useApi();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const companyCode = searchParams.get("company_code") ?? "";
  const poNumber = searchParams.get("po_number") ?? "";

  const validationSchema = Yup.object().shape({
    vendor_inventory: Yup.mixed().required("File is required"),
  });

  const downloadCSV = React.useCallback(() => {
    const headers = [
      {
        Sno: "",
        "Order Id": "",
        InvoiceNumber: "",
        "Invoice Date": "",
        PONumber: "",
        "Asset Type": "",
        "Asset Description": "",
        "Rate per unit": "",
        Quantity: "",
        "Serial Number": "",
        "Tax Rate": "",
        CGST: "",
        IGST: "",
        SGST: "",
        "Cess Rate": "",
        Cess: "",
        "GST Amount": "",
        "Total Asset Cost": "",
        "Delivery Address 1": "",
        "Delivery Address 2": "",
        "Delivery PIN Code": "",
        "Delivery City": "",
        "Delivery State": "",
        "State Of Billing": "",
      },
    ];
    return exportToExcel(headers, "powiseinventory");
  }, []);
  const navigateToUploadProductDetails = React.useCallback(() => {
    return navigate(
      `/client/po-detail?company_code=${encodeURIComponent(companyCode)}&po_number=${encodeURIComponent(poNumber)}`,
    );
  }, [companyCode, navigate, poNumber]);

  const handleSubmit = React.useCallback(
    ({ vendor_inventory }: typeof INTIAL_VALUES) => {
      return makeApiCall(AddEppVendorInventoryApi(vendor_inventory!, poNumber))
        .then((response) => {
          navigate("/client/vendor-inventory");
          navigateToUploadProductDetails();
          toast.success("Inventory added succesfully");
        })
        .catch((error) => {
          toast.error(
            error?.response?.data?.message || "Inventory addition failed",
          );
        });
    },
    [makeApiCall, navigate, navigateToUploadProductDetails, poNumber],
  );
  return (
    <div className="text-black bg-pageBackground min-h-screen">
      <div className="flex flex-col">
        <BreadcrumbComponent
          locations={[
            { name: "Client", path: "/client" },
            { name: companyCode, path: "/client" },
            { name: poNumber, path: "/po-upload-product" },
          ]}
        />
        <div className="flex flex-row justify-between items-center w-[100%]">
          <p className="text-black text-lg leading-8 font-roboto font-normal">
            Upload Inventory
          </p>
          <Button
            color="default"
            variant="bordered"
            startContent={<CiSaveDown2 size={18} />}
            className="font-roboto"
            size="sm"
            radius="sm"
            onClick={downloadCSV}
          >
            Download
          </Button>
        </div>
        <Spacer size="xs" />
        <Formik
          initialValues={INTIAL_VALUES}
          onSubmit={handleSubmit}
          validateOnBlur
          validateOnChange
          validationSchema={validationSchema}
          enableReinitialize
        >
          <Form>
            <FileInput name="vendor_inventory" type="dropzone" accept=".csv" />
            <Spacer size="sm" />
            <Row justifyContent="center">
              <Button type="submit">Submit</Button>
            </Row>
          </Form>
        </Formik>
      </div>
    </div>
  );
}
