import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/common/Header.js";
import HeadingBanner from "../../components/common/HeadingBanner.tsx";
import Contactform from "../../components/homeelments/Contactform.js";
import TitleHead from "../../components/common/TitleHead.tsx";
import InfoCard from "../../components/common/InfoCard.tsx";
import { startupsCardArray } from "../../assets/data/index.js";
import Benefits from "../../components/common/Benifits.tsx";

const Services = () => {
  return (
    <div>
      <Helmet>
        <title>
          Asset Leasing and Financing | Connect Residuary Private Limited |
          Startups
        </title>
        <meta
          name="description"
          content="Connect Residuary Pvt Limited is a well-established asset leasing brand in India dealing in all types of IT Assets, Fit-outs, Plant and machinery etc. We have been leased over 2000cr + of assets in past 12 years of operations."
        />
        <meta
          name="keywords"
          content="leasing, rent, asset on rent, laptop on rent, leasing, laptop on lease, Corporate Asset Purchase, IT asset leasing, equipment leasing, Financing, small business financing, equipment financing, vehicle financing, asset management, Leaseback, capital equipment, business loans, commercial loans, equipment rentals, SME, tax benefits of leasing, lease accounting"
        />
      </Helmet>

      {/* servicespage */}
      <div className="w-full ">
        <Header />
        <div className=" w-screen">
          <HeadingBanner
            imageUrl={
              "https://storage.googleapis.com/connectrpl_images/companywebsite/StartupsBanner.png"
            }
            text="Startup Leasing Program"
            desc={"Empowering Innovation, Simplifying Growth"}
          />
        </div>
        <div className="flex justify-center ">
          <div className=" w-[80%] ">
            <p className=" text-base font-roboto w-full text-black text-center mt-10">
              Startups thrive on innovation but often struggle with capital
              constraints. Our Startup Leasing Program is designed to empower
              your burgeoning business by providing flexible, affordable, and
              straightforward leasing solutions. Whether you're a tech startup
              needing the latest IT equipment or a biotech firm requiring
              state-of-the-art lab tools, our program tailors to your unique
              needs without the financial burden of hefty upfront costs.
            </p>
            <p className=" text-base font-roboto w-full text-black text-center mt-5">
              Understanding that no two startups are alike, we offer customized
              leasing solutions that match your specific requirements and growth
              trajectories. From minimal initial investments to scalable lease
              terms, our program is designed to grow as you do. With our
              support, manage your cash flow effectively and invest in what
              truly matters—innovation and growth.
            </p>
            <TitleHead
              title="Our Solutions"
              subtitle="Types of Solutions to Startup"
            />

            {startupsCardArray?.map((card, index) => (
              <InfoCard
                key={index}
                imageUrl={card.imageUrl}
                text={card.text}
                desc={card.desc}
                reverseOrder={card.reverseOrder}
              />
            ))}
            <Benefits />
            <Contactform />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
