import React from "react";

interface ProfileCardProps {
  name: string;
  title: string;
  imageUrl: string;
  profileUrl: string;
}

const ProfileCard: React.FC<ProfileCardProps> = ({
  name,
  title,
  imageUrl,
  profileUrl,
}) => {
  const openProfileUrl = React.useCallback(() => {
    window.open(profileUrl, "_blank");
  }, [profileUrl]);

  return (
    <div className="relative w-[384px] h-[390px] bg-white border border-[#EAEAEA] rounded-md flex flex-col justify-end items-center p-10 gap-5">
      <div className="flex flex-row items-start w-[200px] h-[200px]">
        <div className="w-[200px] h-[200px] bg-white border-2 border-[#00006D] flex-none rounded-full relative">
          <div
            className="absolute left-[5%] right-[5%] top-[5%] bottom-[5%] bg-cover bg-center rounded-full"
            style={{ backgroundImage: `url(${imageUrl})` }}
          />
        </div>
      </div>

      <div className="flex flex-col items-center w-[304px] h-[90px]">
        <h2 className="font-roboto font-medium text-[24px] leading-[32px] text-[#222222] mb-1">
          {name}
        </h2>
        <p className="font-roboto font-normal text-[20px] leading-[30px] text-center text-[#333333]">
          {title}
        </p>
        <a
          onClick={openProfileUrl}
          className="flex items-center cursor-pointer text-[#00006A] mt-2"
        >
          <img
            src="https://storage.googleapis.com/connectrpl-landing-pagev3/siteimages/linkedinlogo.png"
            alt="LinkedIn"
            className="h-[19.88px] w-[20px] mr-1"
          />
        </a>
      </div>
    </div>
  );
};

export default ProfileCard;
