import * as React from "react";
import { useContext, createContext, ReactNode, FC } from "react";
import { useNavigate } from "react-router-dom";

interface SidebarContextProps {
  expanded: boolean;
}

interface SidebarProps {
  children: ReactNode;
  setExpandedMain: boolean;
}

interface SidebarItemProps {
  icon: ReactNode;
  text: string;
  active?: boolean;
  alert?: boolean;
  href: string;
}

const SidebarContext = createContext<SidebarContextProps | undefined>(
  undefined,
);

const Sidebar: FC<SidebarProps> = ({ children, setExpandedMain }) => {
  return (
    <div>
      <aside
        id="logo-sidebar"
        className={`fixed top-0  hidden sm:block left-0 z-40 w-64 pt-0 h-screen sm:translate-x-0 transition-all ${"w-[18%] hidden sm:block"}`}
      >
        <nav
          className="h-full flex flex-col shadow-sm"
          style={{
            background: "white",
          }}
        >
          <div className="p-4 pb-2 flex justify-between items-center ">
            <img
              src="https://storage.googleapis.com/connectrpl_images/crpl%20logo%203.svg"
              className={`overflow-hidden transition-all ${"w-48"}`}
              alt=""
            />
          </div>
          <SidebarContext.Provider value={{ expanded: true }}>
            <ul className="flex-1 px-8 mt-4">{children}</ul>
          </SidebarContext.Provider>
        </nav>
      </aside>
    </div>
  );
};

export const SidebarItem: FC<SidebarItemProps> = ({
  icon,
  text,
  active,
  href,
}) => {
  const context = useContext(SidebarContext);
  if (!context) {
    throw new Error("SidebarItem must be used within a SidebarContext");
  }
  const navigate = useNavigate();

  const navigateTabs = React.useCallback(() => {
    navigate(href);
  }, [href, navigate]);

  return (
    <li
      onClick={navigateTabs}
      style={{ backgroundColor: active ? "#00006D" : "#fff" }}
      className={`relative flex items-center py-2 px-3 my-3 font-medium rounded-md cursor-pointer  group  ${active ? " text-white" : ""}`}
    >
      {icon}
      <span
        className={`overflow-hidden font-normal font-roboto transition-all  ${active ? " font-medium" : ""} ${"w-52 ml-4 text-base"}`}
      >
        {text}
      </span>
    </li>
  );
};

export default Sidebar;
