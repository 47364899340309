import { VendorClientListType } from "@/src/types";
import * as React from "react";

interface Props {
  item: VendorClientListType;
  onViewOrders: (item: VendorClientListType) => void;
}

export default function Action({ item, onViewOrders }: Props) {
  const handleViewOrders = React.useCallback(() => {
    return onViewOrders(item);
  }, [item, onViewOrders]);

  return (
    <div className="flex flex-row justify-end gap-x-3 2xl:gap-x-4 whitespace-nowrap">
      <button
        onClick={handleViewOrders}
        className="text-textColorGrey cursor-pointer font-roboto font-normal"
      >
        View Orders
      </button>
      {/* 
      <button
        onClick={handleDownloadQrCodes}
        className="text-textColorGrey cursor-pointer font-roboto font-normal"
      >
        QR Codes
      </button> */}
    </div>
  );
}
