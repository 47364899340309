import * as React from "react";
import Cell from "../../../ui/Cell.tsx";
import {
  AssetInventoryType,
  EmployeeDirectoryType,
} from "../../../../types.ts";
import Action from "./Action.tsx";
import { capitalizeFirstLetter } from "../../../../utils/utils.ts";
import { Tooltip } from "@nextui-org/react";
import UpdateRemarks from "./UpdateRemark.tsx";

interface Props {
  index: number;
  asset: AssetInventoryType;
  columnKey: React.Key;
  handleDownloadCsv: (item: AssetInventoryType) => void;
  onSubmitRemark: (serial_number: string, remark: string) => void;
  employees?: EmployeeDirectoryType[];
}

export default function RenderColumns({
  index,
  asset,
  columnKey,
  handleDownloadCsv,
  onSubmitRemark,
  employees,
}: Props) {
  switch (columnKey) {
    case "sr_no":
      return <Cell>{index + 1}</Cell>;
    case "serial_number":
      return <Cell>{asset.SerialNumber}</Cell>;
    case "brand":
      return <Cell>{asset.Brand}</Cell>;
    case "model":
      return <Cell>{asset.ModelName === "" ? "NA" : asset.ModelName}</Cell>;
    case "state":
      return <Cell>{asset.State}</Cell>;
    case "total_ram":
      return <Cell>{asset.RAM === "" ? "NA" : asset?.RAM}</Cell>;
    case "memory":
      return <Cell>{asset?.Storage === "" ? "NA" : asset?.Storage}</Cell>;
    case "category":
      return <Cell>{asset.AssetCategory}</Cell>;
    case "status":
      return (
        <Cell>
          <p>Delivered</p>
        </Cell>
      );
    case "update_remark":
      return (
        <UpdateRemarks
          device={asset}
          onSubmitRemark={onSubmitRemark}
          employees={employees ?? ([] as EmployeeDirectoryType[])}
        />
      );
    case "delivery_address":
      return (
        <Cell>
          <Tooltip content={asset.DeliveryAddress} size="sm">
            {capitalizeFirstLetter(asset.DeliveryAddress, 20)}
          </Tooltip>
        </Cell>
      );
    case "description":
      return (
        <Cell>
          {asset.AssetDetailsDescription === ""
            ? "NA"
            : asset.AssetDetailsDescription}
        </Cell>
      );
    case "action":
      return (
        <div className="flex justify-center items-center">
          <Action
            item={asset}
            handleDownloadCsv={handleDownloadCsv}
            downloadInvoice={() => {}}
          />
        </div>
      );
  }
}
