import React from "react";
import Readbutton from "../common/Readbutton";
import Inr from "../../assets/images/inr.svg";
import Team from "../../assets/images/team.svg";
import States from "../../assets/images/states.svg";
import { Colors } from "../../assets/colors";
import { Card } from "flowbite-react";
import ImageCard from "../common/ImageCard";
import Clients from "../../assets/images/clients.svg";
import Hug from "../../assets/images/hug.svg";
import Branches from "../../assets/images/branches.svg";
import Assets from "../../assets/images/assets.svg";

const Welcome = () => {
  return (
    <section
      style={{ offsetPosition: "normal" }}
      className="text-zinc-800  pt-10  flex justify-center items-center"
    >
      <div className=" w-[80%] flex flex-col  ">
        <div>
          <p
            style={{ offsetPosition: "normal", color: Colors.buttonprimary }}
            className="text-2xl sm:text-3xl  font-roboto  font-light  py-2"
          >
            Welcome to Connect Residuary Private Limited
          </p>
        </div>

        {/* Welcome To <span className=" text-base sm:text-2xl font-roboto  font-light  text-justify">  Connect Residuary Private Limited</span> */}

        <p
          style={{
            offsetPosition: "normal",
            color: Colors.text,
            lineHeight: "1.4rem",
          }}
          className="  text-base font-roboto font-light   py-2  text-justify   md:w-[80vw]  "
        >
          A company formed by a group of professionals who have been connected
          with the equipment renting industry for over 36 years in India and
          Overseas. The prime business of the company is equipment renting based
          on Residual management capability. Having served 270+ clients, ranging
          from Top Fortune 500 companies to MSMEs and startups, Connect's top in
          industry expertise helps you to convert your Capex to Opex via its
          innovative leasing and renting models
        </p>
        {/* <div className="mt-5"> 
          <Readbutton />
        </div> */}

        <div className="w-full  flex justify-center items-center mt-16 ">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4  w-full">
            <div>
              <ImageCard
                title={"360+"}
                titledesc={"Clients"}
                Banner={Clients}
              />
            </div>
            <div>
              <ImageCard title={"70+"} titledesc={"Team Size"} Banner={Hug} />
            </div>
            <div>
              <ImageCard
                title={"6+"}
                titledesc={"Branches"}
                Banner={Branches}
              />
            </div>
            <div>
              <ImageCard
                title={"4000cr +"}
                titledesc={"Worth Assets Leased"}
                Banner={Assets}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Welcome;
