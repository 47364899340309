import React, { useEffect, useState } from "react";
import TitleHead from "./TitleHead.tsx";

const calculateQuarterlyRental = (assetCost: string, tenure: string) => {
  const assetCostNumber = parseFloat(assetCost.replace(/[^0-9.-]+/g, ""));
  let quarterlyRental = 0;
  if (tenure === "3") {
    quarterlyRental = (assetCostNumber * 0.07) / 4;
  } else if (tenure === "5") {
    quarterlyRental = (assetCostNumber * 0.03) / 4;
  }
  return quarterlyRental;
};

const Calculator = () => {
  const [assetCost, setAssetCost] = useState("10000000"); // Default to 1 Crore
  const [tenure, setTenure] = useState("3"); // Default to 3 years
  const [quarterlyRental, setQuarterlyRental] = useState<number | null>(null);

  useEffect(() => {
    const calculatedRental = calculateQuarterlyRental(assetCost, tenure);
    setQuarterlyRental(calculatedRental);
  }, [assetCost, tenure]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const calculatedRental = calculateQuarterlyRental(assetCost, tenure);
    setQuarterlyRental(calculatedRental);
  };

  return (
    <div>
      <TitleHead
        title="Calculate Estimate Your Savings"
        subtitle=""
        summary=""
      />
      <div className="">
        <form onSubmit={handleSubmit}>
          <div className="flex items-center my-10">
            <select
              className="mr-2 w-[40%] px-3 py-3 border border-gray-300 font-roboto font-light rounded-md focus:border-none focus:outline-none"
              value={assetCost}
              onChange={(e) => setAssetCost(e.target.value)}
            >
              <option value="10000000">1 Crore</option>
              <option value="30000000">3 Crore</option>
            </select>
            <select
              className="mr-2 px-3 w-[40%] py-3 border border-gray-300 font-roboto font-light rounded-md focus:border-none focus:outline-none"
              value={tenure}
              onChange={(e) => setTenure(e.target.value)}
            >
              <option value="3">3 years</option>
              <option value="5">5 years</option>
            </select>
            <button
              className="px-12 py-3 bg-blue-950 text-white rounded-sm focus:outline-none"
              type="submit"
            >
              Submit
            </button>
          </div>
        </form>
        {assetCost && tenure ? (
          <div className="w-full">
            <div className="flex flex-row justify-between ">
              <div>
                <p className="text-black text-xl pb-3 relative font-medium font-roboto">
                  Without Leasing
                </p>
                <p className="text-black text-base pb-1 ml-3 relative font-light font-roboto">
                  Total Payments:{" "}
                  <span className="font-medium">INR {assetCost}</span>
                </p>
                <p className="text-black text-base pb-1 ml-3 relative font-light font-roboto">
                  No Support after asset life
                </p>
                <p className="text-black text-base pb-1 ml-3 relative font-light font-roboto">
                  High Financing Costs{" "}
                </p>
              </div>
              <div>
                <p className="text-black text-xl pb-3 relative font-medium font-roboto">
                  With Connect Leasing Plans
                </p>
                <p className="text-black text-base pb-1 ml-3 relative font-light font-roboto">
                  Quarterly Rentals:{" "}
                  <span className="font-medium">
                    INR {quarterlyRental ? quarterlyRental.toFixed(2) : "0.00"}
                  </span>
                </p>
                <p className="text-black text-base pb-1 ml-3 relative font-light font-roboto">
                  Return Assets after use
                </p>
                <p className="text-black text-base pb-1 ml-3 relative font-light font-roboto">
                  Flexible and Custom Build Rental Plans
                </p>
              </div>
              <div>
                <p className="text-black text-xl pb-3 relative font-medium font-roboto">
                  .
                </p>
                <p className="text-black text-base pb-1 ml-3 relative font-light font-roboto">
                  Minimum Capex Impact
                </p>
                <p className="text-black text-base pb-1 ml-3 relative font-light font-roboto">
                  PAN India presence
                </p>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Calculator;
