import React from "react";
import Main from "../../../components/ui/sidebar/Main.tsx";

const Contact = () => {
  return (
    <Main>
      <div>
        <div className="text-black">HAHAH</div>
      </div>
    </Main>
  );
};

export default Contact;
