import React from "react";
import TopNav from "../../components/TopNav";
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
  return (
    <div>
      <Helmet>
        <title>
          Asset Leasing and Financing | Connect Residuary Private Limited |
          Industries
        </title>
        <meta
          name="description"
          content="Connect Residuary Pvt Limited is a well-established asset leasing brand in India dealing in all types of IT Assets, Fit-outs, Plant and machinery etc. We have been leased over 2000cr + of assets in past 12 years of operations."
        />
        <meta
          name="keywords"
          content="leasing, rent, asset on rent, laptop on rent, leasing, laptop on lease, Corporate Asset Purchase, IT asset leasing, equipment leasing, Financing, small business financing, equipment financing, vehicle financing, asset management, Leaseback, capital equipment, business loans, commercial loans, equipment rentals, SME, tax benefits of leasing, lease accounting"
        />
      </Helmet>
      <section style={{ offsetPosition: "normal" }} className="text-zinc-800">
        <div className="m-auto">
          {/* <TopNav
            bannerurl="https://storage.googleapis.com/connectrpl-landing-pagev3/banner1%20.png"
            title="Privacy Policy"
            titledesc=""
            showreadmore={false}
          /> */}
          <div className="flex justify-center items-center">
            <div className="w-[80%]">
              <div class="p-6">
                <h1 class="text-3xl font-bold text-gray-800 mb-4">
                  Privacy Policy
                </h1>

                <p class="text-base text-gray-600 mb-3">
                  The following policies are with regard to Connect Residuary
                  Private Limited. We appreciate the faith you have in us.
                  That’s why we insist upon the highest standards for secure
                  transactions and the confidentiality of consumer information.
                  To learn more about our information gathering and sharing
                  procedures, please read the statement below.
                </p>

                <p class="text-base text-gray-600 mb-3">
                  You consent to be legally bound by this Privacy Policy's terms
                  and conditions by accessing this website. Please refrain from
                  using or accessing our website if you disagree.
                </p>

                <p class="text-base text-gray-600 mb-3">
                  You expressly consent to the use and dissemination of your
                  personal information by us in accordance with this Privacy
                  Policy by simply using the Website.
                </p>

                <h2 class="text-2xl font-semibold text-gray-700 mt-6 mb-3">
                  Gathering of Personally Identifiable Information and other
                  Information
                </h2>

                <p class="text-base text-gray-600 mb-3">
                  When you use our website, we occasionally request and save
                  personal information from you. Our main objective is to give
                  you a secure, effective, effortless, and personalised
                  experience. As a result, we can offer services and features
                  that will most likely satisfy your needs and customize our
                  website to make your experience safer and easier. More
                  importantly, we also gather from you whatever personal data we
                  deem necessary to fulfil this objective.
                </p>

                <p class="text-base text-gray-600 mb-3">
                  Generally, you are free to surf the Website without
                  identifying yourself to us or disclosing any personal
                  information about yourself. You are no longer anonymous to us
                  after you provide us your personal information. We try to make
                  it clear which fields are necessary and which ones are
                  optional. You can never be forced to give information; you may
                  always decide not to use a certain service or feature on the
                  Website. Based on how you use our website, we may monitor
                  certain information about you automatically. To better
                  understand, safeguard, and serve our users, we conduct
                  internal research using this data on their demographics,
                  interests, and behaviour. This data is gathered and evaluated
                  on an aggregate level. The URL that you just came from, your
                  computer browser information, your IP address, etc., could be
                  among this data.
                </p>

                <p class="text-base text-gray-600 mb-3">
                  On certain pages of the Website, we use data collection
                  devices such as “cookies” to help analyze our web page flow,
                  measure promotional effectiveness, and promote trust and
                  safety. “Cookies” are small files placed on your hard drive
                  that assist us in providing our services. We offer certain
                  features that are only available through the use of a
                  “cookie”. We also use cookies to allow you to enter your
                  password less frequently during a session. Cookies can also
                  help us provide information that is targeted to your
                  interests. Most cookies are “session cookies,” meaning that
                  they are automatically deleted from your hard drive at the end
                  of a session. You are always free to decline our cookies if
                  your browser permits, although in that case you may not be
                  able to use certain features on the Website and you may be
                  required to re-enter your password more frequently during a
                  session. On some Website pages, third parties may also use
                  "cookies" or other similar technologies to store information
                  about you. We have no control over how third parties use
                  cookies.
                </p>

                <p class="text-base text-gray-600 mb-3">
                  We get information about your purchasing habits if you decide
                  to make a purchase through the website. If you transact with
                  us, we collect some additional information, such as a billing
                  address, a credit/debit card number and a credit/debit card
                  expiration date and/or other payment instrument details and
                  tracking information from cheques or money orders.
                </p>

                <p class="text-base text-gray-600 mb-3">
                  We will collect the information you give us if you choose to
                  offer feedback or post messages in our message boards, chat
                  rooms, or other message areas. As allowed by law, we keep your
                  information as long as it's required to resolve disputes,
                  offer customer assistance, and troubleshoot issues. We may
                  compile information about you into a file if you send us
                  personal correspondence, including emails or letters, or if
                  other users or other parties send us correspondence regarding
                  your actions or postings on the Website.
                </p>

                <p class="text-base text-gray-600 mb-3">
                  When you create a free account with us, we ask for personally
                  identifiable information from you, such as your email address,
                  name, phone number, credit card or debit card information, or
                  details of other payment instruments. Various actions (like
                  placing an order) do require registration even though you can
                  view some areas of our website without registering. Your
                  contact information is used by us to send you offers that are
                  customized to your interests and past purchases.
                </p>

                <h2 class="text-2xl font-semibold text-gray-700 mt-6 mb-3">
                  Use of Your Information/Profile and Demographic Data
                </h2>

                <p class="text-base text-gray-600 mb-3">
                  In order to fulfill your request for services, we use personal
                  information. To the extent we use your personal information to
                  market to you, we will provide you the ability to opt-out of
                  such uses. Your personal information is used by us to: resolve
                  disputes; troubleshoot issues; support a safe service; collect
                  money; assess consumer interest in our products and services;
                  personalize your experience; detect and protect us from
                  errors, fraud, and other illegal activity; enforce our terms
                  and conditions; and as otherwise disclosed to you at the time
                  of collection.
                </p>

                <p class="text-base text-gray-600 mb-3">
                  In order to determine your eligibility for specific products
                  or services (including, but not limited to, providing credit)
                  offered by us, our affiliates, or our lending partners, we may
                  collect and store your PAN and Aadhaar data with your
                  permission.
                </p>

                <p class="text-base text-gray-600 mb-3">
                  On occasion, we may invite you to participate in optional
                  online surveys. You might be prompted to provide contact and
                  demographic information in these surveys (like zip code, age,
                  or income level). We use this information to personalize your
                  visit to our website, provide you content we believe you'll
                  find interesting, and present it in a way that suits your
                  interests.
                </p>

                <h2 class="text-2xl font-semibold text-gray-700 mt-6 mb-3">
                  Disclosure of personal data
                </h2>

                <p class="text-base text-gray-600 mb-3">
                  We may release personal data to our affiliates and other
                  corporate entities. Unless you specifically opt out, these
                  entities and their affiliates may market to you as a result of
                  such sharing.
                </p>

                <p class="text-base text-gray-600 mb-3">
                  We may make personal data available to outside parties. It's
                  possible that we need to make this disclosure in order to give
                  you access to our services, abide by legal duties, enforce our
                  user agreement, facilitate marketing and advertising efforts,
                  or to prevent, deter, mitigate, and look into fraudulent or
                  illegal activity involving our services. Without your express
                  permission, we will not divulge any of your personal
                  information to third parties for their marketing and
                  advertising efforts.
                </p>

                <p class="text-base text-gray-600 mb-3">
                  If obliged to do so by law or in the good faith judgement that
                  such disclosure is advisable in order to comply to subpoenas,
                  court orders, or other legal process, we may disclose personal
                  information. We may disclose personal information to law
                  enforcement offices, third party rights owners, or others in
                  the good faith belief that such disclosure is reasonably
                  necessary to: enforce our Terms or Privacy Policy; respond to
                  claims that an advertisement, posting or other content
                  violates the rights of a third party; or protect the rights,
                  property or personal safety of our users or the general
                  public.
                </p>

                <p class="text-base text-gray-600 mb-3">
                  If we (or our assets) decide to merge with, be bought by,
                  reorganise, amalgamate, or restructure our business, we and
                  our affiliates may transfer or sell part or all of your
                  personal information to that other corporate entity. In the
                  event that such a transaction takes place, the other business
                  entity (or the resulting combined Entity) will be obligated to
                  abide by this privacy policy with regard to your personal
                  data.
                </p>

                <h2 class="text-2xl font-semibold text-gray-700 mt-6 mb-3">
                  Other Website Links
                </h2>

                <p class="text-base text-gray-600 mb-3">
                  Our website contains links to other websites that may track
                  your personally identifiable data. We disclaim all liability
                  for the content or privacy policies of those websites that are
                  linked to us.
                </p>

                <h2 class="text-2xl font-semibold text-gray-700 mt-6 mb-3">
                  Security measures
                </h2>

                <p class="text-base text-gray-600 mb-3">
                  Strict security controls are in place on our website to guard
                  against the loss, misuse, and alterations of the data that is
                  in our custody. We provide the usage of a secure server
                  whenever you modify or access the information associated with
                  your account. Once we have your information, we follow strong
                  security procedures to keep it safe from unwanted access.
                </p>

                <h2 class="text-2xl font-semibold text-gray-700 mt-6 mb-3">
                  Choice/Opt-Out
                </h2>

                <p class="text-base text-gray-600 mb-3">
                  After creating an account, we give all users the option to
                  choose whether or not they want to receive non-essential
                  (promotional, marketing-related) emails from us on behalf of
                  our partners. Please visit unsubscribe if you want to take
                  your contact information off of all of our lists and emails.
                </p>

                <h2 class="text-2xl font-semibold text-gray-700 mt-6 mb-3">
                  Online advertisements
                </h2>

                <p class="text-base text-gray-600 mb-3">
                  When you visit our website, advertisements are displayed by
                  third-party ad agencies. In order to show you advertisements
                  for products and services you might be interested in, these
                  businesses may utilise information about your visits to this
                  and other websites (but not your name, address, email address,
                  or phone number).
                </p>

                <h2 class="text-2xl font-semibold text-gray-700 mt-6 mb-3">
                  Your Consent
                </h2>

                <p class="text-base text-gray-600 mb-3">
                  By using the website and/or submitting your information, you
                  consent to the collection, sharing and use of the information
                  in accordance with this privacy policy. Our privacy policies
                  are subject to change at any time and without previous notice.
                  By often visiting this page for updates, you can stay informed
                  about updates.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="w-[100%] h-20"></div>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
