import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/common/Header.js";
import HeadingBanner from "../../components/common/HeadingBanner.tsx";
import Contactform from "../../components/homeelments/Contactform.js";
import TitleHead from "../../components/common/TitleHead.tsx";
import InfoCard from "../../components/common/InfoCard.tsx";
import { msmeCardArray } from "../../assets/data/index.js";
import Benefits from "../../components/common/Benifits.tsx";

const Msme = () => {
  return (
    <div>
      <Helmet>
        <title>
          Asset Leasing and Financing | Connect Residuary Private Limited | Msme
        </title>
        <meta
          name="description"
          content="Connect Residuary Pvt Limited is a well-established asset leasing brand in India dealing in all types of IT Assets, Fit-outs, Plant and machinery etc. We have been leased over 2000cr + of assets in past 12 years of operations."
        />
        <meta
          name="keywords"
          content="leasing, rent, asset on rent, laptop on rent, leasing, laptop on lease, Corporate Asset Purchase, IT asset leasing, equipment leasing, Financing, small business financing, equipment financing, vehicle financing, asset management, Leaseback, capital equipment, business loans, commercial loans, equipment rentals, SME, tax benefits of leasing, lease accounting"
        />
      </Helmet>

      {/* servicespage */}
      <div className="w-full ">
        <Header />
        <div className=" w-screen">
          <HeadingBanner
            imageUrl={
              "https://storage.googleapis.com/connectrpl_images/companywebsite/StartupsBanner.png"
            }
            text="MSME Leasing Program"
            desc={"Enabling Growth, Ensuring Agility"}
          />
        </div>
        <div className="flex justify-center ">
          <div className=" w-[80%] ">
            <p className=" text-base font-roboto w-full text-black text-center mt-5">
              Micro, Small, and Medium Enterprises (MSMEs) are the backbone of
              the economy, but they often face significant challenges in
              accessing the resources necessary to expand and compete. Our MSME
              Leasing Program is specifically designed to bridge this gap,
              providing affordable, flexible leasing options that empower MSMEs
              to thrive without the burden of large capital expenditures.
            </p>
            <p className=" text-base font-roboto w-full text-black text-center mt-5">
              Empower your MSME with the tools it needs to expand and succeed.
              Our MSME Leasing Program offers more than just financial
              solutions—it offers a partnership geared towards your long-term
              success. Contact us today to learn how we can help you optimize
              your operations and accelerate your growth.
            </p>
            <TitleHead
              title="Our Solutions"
              subtitle="Types of Solutions to Small and Medium Enterprises"
            />

            {msmeCardArray?.map((card, index) => (
              <InfoCard
                key={index}
                imageUrl={card.imageUrl}
                text={card.text}
                desc={card.desc}
                reverseOrder={card.reverseOrder}
              />
            ))}
            <Benefits />
            <Contactform />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Msme;
