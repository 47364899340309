import React from "react";
import TitleHead from "./TitleHead.tsx";
import { benefitsData } from "../../assets/data";
import Calculator from "./Calculator.tsx";

interface InfoCardProps {}

const Benefits: React.FC<InfoCardProps> = () => {
  const midpoint = Math.ceil(benefitsData.length / 2);
  const firstHalf = benefitsData.slice(0, midpoint);
  const secondHalf = benefitsData.slice(midpoint);

  return (
    <div className="bg-white mt-[5vh]">
      <TitleHead
        title="Benefits"
        subtitle="Benefits of Leasing with Connect"
        summary=""
      />

      <div className="bg-white mt-[5vh] ">
        <div className="flex flex-row">
          <div className="w-1/2 p-4">
            {firstHalf.map((item, index) => (
              <div key={index}>
                <p className="text-black text-base pb-3 relative font-light font-roboto">
                  {item.point}
                </p>
                {item.subPoints && item.subPoints.length > 0 && (
                  <ul className="list-disc list-inside">
                    {item.subPoints.map((subPoint, subIndex) => (
                      <li
                        key={subIndex}
                        className="text-black text-base relative font-light font-roboto"
                      >
                        {subPoint}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            ))}
          </div>
          <div className="w-1/2 p-4 ">
            {secondHalf.map((item, index) => (
              <div key={index}>
                <p className="text-black text-base pb-3 relative font-light font-roboto">
                  {item.point}
                </p>
                {item.subPoints && item.subPoints.length > 0 && (
                  <ul className="list-disc list-inside">
                    {item.subPoints.map((subPoint, subIndex) => (
                      <li
                        key={subIndex}
                        className="text-black text-base relative font-light font-roboto"
                      >
                        {subPoint}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* <Calculator/> */}
    </div>
  );
};

export default Benefits;
