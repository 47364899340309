import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/common/Header.js";
import HeadingBanner from "../../components/common/HeadingBanner.tsx";
import Contactform from "../../components/homeelments/Contactform.js";
import {} from "../../assets/colors.js";
import TitleHead from "../../components/common/TitleHead.tsx";
import InfoCard from "../../components/common/InfoCard.tsx";
import { assetDisposalCardArray } from "../../assets/data/index.js";
import Benefits from "../../components/common/Benifits.tsx";

const AssetDisposalService = () => {
  return (
    <div>
      <Helmet>
        <title>
          Asset Leasing and Financing | Connect Residuary Private Limited |
          Asset disposal service
        </title>
        <meta
          name="description"
          content="Connect Residuary Pvt Limited is a well-established asset leasing brand in India dealing in all types of IT Assets, Fit-outs, Plant and machinery etc. We have been leased over 2000cr + of assets in past 12 years of operations."
        />
        <meta
          name="keywords"
          content="leasing, rent, asset on rent, laptop on rent, leasing, laptop on lease, Corporate Asset Purchase, IT asset leasing, equipment leasing, Financing, small business financing, equipment financing, vehicle financing, asset management, Leaseback, capital equipment, business loans, commercial loans, equipment rentals, SME, tax benefits of leasing, lease accounting"
        />
      </Helmet>

      {/* servicespage */}
      <div className="w-full ">
        <Header />
        <div className=" w-screen">
          <HeadingBanner
            imageUrl={
              "https://storage.googleapis.com/connectrpl_images/companywebsite/StartupsBanner.png"
            }
            text="Asset Disposal Services"
            desc={"Sell your unused Assets with Connect"}
          />
        </div>
        <div className="flex justify-center ">
          <div className=" w-[80%] ">
            <p className=" text-base font-roboto w-full text-black text-center mt-5">
              In partnership with GoRevive Private Limited(gorevive.in), Connect
              provides asset disposal services for your old, unused assets. Our
              Team handles all aspects related to asset disposal, ranging from
              QC checks, , packaging, asset movement , at attractive rates.
            </p>

            <TitleHead title="Our Solutions" subtitle="" />

            {assetDisposalCardArray?.map((card, index) => (
              <InfoCard
                key={index}
                imageUrl={card.imageUrl}
                text={card.text}
                desc={card.desc}
                reverseOrder={card.reverseOrder}
              />
            ))}
            <Benefits />
            <Contactform />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssetDisposalService;
