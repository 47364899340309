import * as React from "react";
import useApi from "../../hooks/useApi";
import Main from "../../components/ui/sidebar/Main";
import {
  GetVendorClientApi,
  UploadPreProcurementDocumentsApi,
} from "../../apis";
import { SelectType, VendorClientListType } from "../../types";
import Spacer from "../../components/ui/Spacer";
import Breadcrumb from "../../components/ui/Breadcrumb";
import Row from "../../components/ui/Row";
import { Form, Formik } from "formik";
import Button from "../../components/ui/Button";
import * as Yup from "yup";
import FileInput from "../../components/ui/FileInput";
import FormikSelect from "../../components/ui/sidebar/FormikSelect";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const DocumentType: SelectType[] = [
  { value: "boq", label: "BOQ" },
  { value: "pi", label: "PI" },
  { value: "others", label: "Others" },
];

const initial_values = {
  client_code: "",
  document_type: "",
  document: null as File | null,
};

export default function Upload() {
  const { makeApiCall } = useApi();
  const navigate = useNavigate();

  const [vendorClientList, setVendorClientList] = React.useState<
    VendorClientListType[]
  >([]);
  const [loading, setLoading] = React.useState(false);

  // Rental schedule get API call
  React.useEffect(() => {
    makeApiCall(GetVendorClientApi())
      .then((response) => {
        if (response !== undefined) {
          setVendorClientList(response);
        }
      })
      .catch((error) => console.error(error))
      .finally(() => {});
  }, [makeApiCall]);

  const client_list: SelectType[] = React.useMemo(() => {
    return vendorClientList.map((client) => ({
      value: client["Client Code"],
      label: client["Client Name"],
    }));
  }, [vendorClientList]);

  const goBack = React.useCallback(() => {
    return navigate("/pre-procurement-documents");
  }, [navigate]);

  const handleSubmit = React.useCallback(
    ({ client_code, document_type, document }: typeof initial_values) => {
      console.log(client_code, document_type, document);
      setLoading(true);
      const client_name = client_list.find(
        (item) => item.value === client_code,
      )?.label;
      return makeApiCall(
        UploadPreProcurementDocumentsApi(
          client_code,
          client_name ?? "",
          document_type,
          document!,
        ),
      )
        .then(() => {
          toast.success("Document uploaded succesfully");
          goBack();
        })
        .catch((error) => {
          toast.error(
            error?.response?.data?.message || "Document Upload failed",
          );
        })
        .finally(() => setLoading(false));
    },
    [client_list, goBack, makeApiCall],
  );

  const validationSchema = Yup.object().shape({
    client_code: Yup.string().required("Client name is required"),
    document_type: Yup.string().required("Document type is required"),
    document: Yup.mixed().required("File is required"),
  });

  return (
    <Main>
      <div className="text-black bg-pageBackground px-10 min-h-screen">
        <Spacer size="sm" />
        <Breadcrumb
          locations={[
            {
              name: "Pre-Procurement Documents",
              path: "/pre-procurement-documents",
            },
            {
              name: "Upload",
              path: "/pre-procurement-documents/upload",
            },
          ]}
        />
        <Spacer size="sm" />
        <Formik
          initialValues={initial_values}
          onSubmit={handleSubmit}
          validateOnBlur
          validateOnChange
          validationSchema={validationSchema}
          enableReinitialize
        >
          <Form>
            <Row>
              <FormikSelect
                item={client_list}
                key={`status-dropdown`}
                label="Entity Type"
                name="client_code"
                variant="bordered"
              />
              <Spacer size="xs" orientation="horizontal" />
              <FormikSelect
                item={DocumentType}
                key={`status-dropdown`}
                label="Document Type"
                name="document_type"
                variant="bordered"
              />
            </Row>
            <Spacer size="xs" />
            <FileInput name="document" type="dropzone" />
            <Spacer size="xs" />
            <Row justifyContent="center">
              <Button isLoading={loading} color="primary" type="submit">
                Submit
              </Button>
            </Row>
          </Form>
        </Formik>
      </div>
    </Main>
  );
}
