import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { goTop } from "../lib/methods.ts";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";

const Footer = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "newsletter",
    email: "",
    message: "This message from newsletter!!",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const notify = (text) => toast.success(text);

  const notifyfail = (text) => toast.error(text);

  const payload = {
    name: formData.name,
    recipient: formData.email,
    subject: "Feedback form subject",
    body: formData.message,
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    fetchData();
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        "https://feedbackservice-u5ab3vfhaa-uw.a.run.app/sendemail",
        payload,
      );

      setIsLoading(false);
      notify(" Message sent!");
    } catch (error) {
      setIsLoading(false);
      notifyfail("Error occurred!!");

      setError(error);
    }

    setIsLoading(false);
  };

  return (
    <footer className="w-full m-auto   ">
      <div
        className="text-zinc-800  bg-slate-950 flex justify-center items-center"
        style={{ backgroundColor: "#3B3B3B" }}
      >
        <div className=" pt-10 w-[85%]">
          <div className="grid grid-cols-5 w-full ">
            <div className=" p-4 ">
              <p
                onClick={() => {
                  window.open("/aboutus", "_blank");
                }}
                className="text-base font-light font-roboto text-white mb-2 w-full cursor-pointer"
                style={{ textDecoration: "none" }} // Optional: to remove underline from links
              >
                About Connect
              </p>

              <p
                onClick={() => {
                  window.open("/reachus", "_blank");
                }}
                className="text-base font-light font-roboto text-white mb-2 w-full cursor-pointer"
                style={{ textDecoration: "none" }} // Optional: to remove underline from links
              >
                Contact Us
              </p>
              <p
                onClick={() => {
                  window.open("/csr", "_blank");
                }}
                className="text-base font-light font-roboto text-white mb-2 w-full cursor-pointer"
                style={{ textDecoration: "none" }} // Optional: to remove underline from links
              >
                Corporate Social Responsibility
              </p>
            </div>
            <div className=" p-4 ">
              <p
                onClick={() => {
                  window.open("/services", "_blank");
                }}
                className="text-base font-light font-roboto text-white mb-2 w-full cursor-pointer"
                style={{ textDecoration: "none" }} // Optional: to remove underline from links
              >
                Services
              </p>
              <p
                onClick={() => {
                  window.open("/startups", "_blank");
                }}
                className="text-base font-light font-roboto text-white mb-2 w-full cursor-pointer"
                style={{ textDecoration: "none" }} // Optional: to remove underline from links
              >
                Programs for Startups
              </p>

              <p
                onClick={() => {
                  window.open("/corporates", "_blank");
                }}
                className="text-base font-light font-roboto text-white mb-2 w-full cursor-pointer"
                style={{ textDecoration: "none" }} // Optional: to remove underline from links
              >
                Corporate Leasing Solutions
              </p>

              <p
                onClick={() => {
                  window.open("/msme", "_blank");
                }}
                className="text-base font-light font-roboto text-white mb-2 w-full cursor-pointer"
                style={{ textDecoration: "none" }} // Optional: to remove underline from links
              >
                MSME Plans
              </p>

              <p
                onClick={() => {
                  window.open("/appleforbusiness", "_blank");
                }}
                className="text-base font-light font-roboto text-white mb-2 w-full cursor-pointer"
                style={{ textDecoration: "none" }} // Optional: to remove underline from links
              >
                Apple for Business
              </p>
            </div>
            <div className=" p-4 ">
              <p
                onClick={() => {
                  window.open("/works", "_blank");
                }}
                className="text-base font-light font-roboto text-white mb-2 w-full cursor-pointer"
                style={{ textDecoration: "none" }} // Optional: to remove underline from links
              >
                Onboarding
              </p>
              <p
                onClick={() => {
                  window.open("/assetdisposal", "_blank");
                }}
                className="text-base font-light font-roboto text-white mb-2 w-full cursor-pointer"
                style={{ textDecoration: "none" }} // Optional: to remove underline from links
              >
                Asset Disposal Services
              </p>
              <p
                onClick={() => {
                  window.open("/epp/login", "_blank");
                }}
                className="text-base font-light font-roboto text-white mb-2 w-full cursor-pointer"
                style={{ textDecoration: "none" }} // Optional: to remove underline from links
              >
                EPP Login
              </p>
            </div>
            <div className=" p-4">
              <p
                onClick={() => {
                  window.open("/blogs", "_blank");
                }}
                className="text-base font-light font-roboto text-white mb-2 w-full cursor-pointer"
                style={{ textDecoration: "none" }} // Optional: to remove underline from links
              >
                Blogs
              </p>

              <p
                onClick={() => {
                  window.open(
                    "https://connectrpl.zohorecruit.in/jobs/Careers",
                    "_blank",
                  );
                }}
                className="text-base font-light font-roboto text-white mb-2  w-full cursor-pointer"
              >
                Careers
              </p>
            </div>
            <div className=" p-4">
              <p
                onClick={() => {
                  window.open("/partnerus", "_blank");
                }}
                className="text-base font-light font-roboto text-white mb-2  w-full "
              >
                Partner With Us
              </p>

              <p
                onClick={() => {
                  window.open("/privacypolicy", "_blank");
                }}
                className="text-base font-light font-roboto text-white mb-2 w-full cursor-pointer"
                style={{ textDecoration: "none" }} // Optional: to remove underline from links
              >
                Privacy Policy
              </p>
            </div>
          </div>

          {/* <div>
            <div className=" sm: flex sm: justify-center sm:items-center ">
              <ul className="  grid grid-cols-2 gap-x-4 gap-y-2 py-2 border-b-white border-b-2 md:flex md:justify-center   md:grid-rows-1 base:grid-rows-1 xl:grid-rows-1 sm: w-[85vw] ">
                <li className="py-1.5 base:px-4  border-r lg:border-white lg:border-r border-transparent  lg:w-32 lg:flex lg: justify-center lg:items-center">
                  <a
                    // href="https://www.connectrpl.com/"
                    onClick={() => {
                      navigate("/");
                    }}
                    className="text-white hover:text-rose-600  text-xs sm:text-sm font-light font-poppins"
                  >
                    Home
                  </a>
                </li>

                <li className="py-1.5 lg:px-4  border-r lg:border-white lg:border-r border-transparent   lg:w-32 lg:flex lg: justify-center lg:items-center ">
                  <a
                    // href="https://www.connectrpl.com/about-us/"
                    onClick={() => {
                      navigate("/aboutus");
                    }}
                    className="text-white hover:text-rose-600 text-xs sm:text-sm font-light font-poppins"
                  >
                    About Us
                  </a>
                </li>
                <li className="py-1.5 lg:px-4  border-r lg:border-white lg:border-r border-transparen lg:w-32 lg:flex lg: justify-center lg:items-center">
                  <a
                    // href="https://www.connectrpl.com/why-rent/"
                    onClick={() => {
                      navigate("/whyrent");
                    }}
                    className="text-white hover:text-rose-600 text-xs sm:text-sm font-light font-poppins"
                  >
                    Why Rent
                  </a>
                </li>
                <li className="py-1.5 lg:px-4   border-r lg:border-white lg:border-r border-transparent   lg:w-32 lg:flex lg: justify-center lg:items-center">
                  <a
                    // href="https://www.connectrpl.com/solutions/"
                    onClick={() => {
                      navigate("/solutions");
                    }}
                    // onClick={() => { router.push("/");}}
                    className="text-white hover:text-rose-600 text-xs sm:text-sm font-light font-poppins"
                  >
                    Solutions
                  </a>
                </li>
                <li className="py-1.5 lg:px-4  border-r lg:border-white lg:border-r border-transparent   lg:w-32 lg:flex lg: justify-center lg:items-center ">
                  <a
                    // href="https://www.connectrpl.com/csr/"
                    onClick={() => {
                      navigate("/csr");
                    }}
                    className="text-white hover:text-rose-600 text-xs sm:text-sm font-light font-poppins"
                  >
                    CSR
                  </a>
                </li>
                <li className="py-1.5 lg:px-4  border-r lg:border-white lg:border-r border-transparent  lg:w-56 lg:flex lg: justify-center lg:items-center ">
                  <a
                    // href="https://www.connectrpl.com/industries-we-cater-to/"
                    onClick={() => {
                      navigate("/industries");
                    }}
                    className="text-white hover:text-rose-600 text-xs sm:text-sm font-light font-poppins"
                  >
                    Industries we cater to
                  </a>
                </li>
                <li className="py-1.5 lg:px-4  border-r lg:border-white sm:border-r border-transparent  lg:w-32 lg:flex lg: justify-center lg:items-center">
                  <a
                    // href="https://www.connectrpl.com/career/"
                    onClick={() => {
                      navigate("/career");
                    }}
                    className="text-white hover:text-rose-600 text-xs sm:text-sm font-light font-poppins"
                  >
                    Career
                  </a>
                </li>
                <li className="py-1.5 lg:px-4   lg:w-32 lg:flex lg: justify-center lg:items-center ">
                  <a
                    // href="https://www.connectrpl.com/contact/"
                    onClick={() => {
                      navigate("/reachus");
                    }}
                    className="text-white hover:text-rose-600 text-xs sm:text-sm font-light font-poppins"
                  >
                    Reach Us
                  </a>
                </li>
              </ul>
            </div>
          </div> */}

          <div className=" w-[40%] ml-5 ">
            <form
              onSubmit={handleSubmit}
              className=" rounded  pt-6 pb-8 mb-4 flex items-start"
            >
              <div className="mb-5 w-full">
                <input
                  className="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="email"
                  type="email"
                  name="email"
                  placeholder="Enter your email ID for subscribe"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="flex items-center justify-between ml-3 p-1">
                <button
                  style={{ backgroundColor: "#3B3B3B" }}
                  className=" border cursor-pointer border-white text-white font-light font-roboto py-2 px-10 rounded focus:outline-none focus:shadow-outline"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>

          <p className="flex justify-center text-base font-light font-roboto text-white mb-2  w-full ">
            Copyright © 2024 Connect Residuary Private Limited. All rights
            Reserved |
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
