import React from "react";

const ImageCard = ({ title, titledesc, Banner }) => {
  return (
    <div className="border ">
      <div className="flex flex-col justify-center items-center p-1 sm:p-4">
        <div className="flex-1 flex justify-center items-center">
          <div className="flex justify-center items-center mt-2">
            <img src={Banner} alt="My SVG" className="w-[100%] h-auto    " />
          </div>
        </div>
        <div className="text-black font-['Roboto'] text-3xl lg:text-3xl font-medium leading-[normal] mt-4 mb-2">
          {title}
        </div>

        <div className=" text-black text-center font-['Roboto'] text-base lg:text-xl font-normal leading-[normal]  w-[100%]">
          {titledesc}
        </div>
      </div>
    </div>
  );
};

export default ImageCard;
