import * as React from "react";
import Main from "../../components/ui/sidebar/Main.tsx";
import Spacer from "../../components/ui/Spacer.tsx";
import {
  Table,
  TableHeader,
  TableBody,
  TableColumn,
  TableRow,
  TableCell,
  Spinner,
  Chip,
} from "@nextui-org/react";
import { PreProcurementDocumentsType } from "../../types.ts";
import useApi from "../../hooks/useApi.tsx";
import { GetPreProcurementDocumentsApi } from "../../apis.ts";
import Breadcrumb from "../../components/ui/Breadcrumb.tsx";
import Row from "../../components/ui/Row.tsx";
import Button from "../../components/ui/Button.tsx";
import { useNavigate } from "react-router-dom";
import ViewDocument from "./ViewDocument.tsx";
import { formatDateIntl } from "../../utils/utils.ts";

const COLUMNS = [
  {
    name: "Sr No.",
    key: "sr_no",
  },
  {
    name: "Client Name",
    key: "client_name",
  },
  {
    name: "Document Name",
    key: "document_name",
  },

  {
    name: "Document Type",
    key: "document_type",
  },
  {
    name: "Uploaded Date",
    key: "uploaded_date",
  },
  {
    name: "Document Status",
    key: "document_status",
  },
  {
    name: "Remarks",
    key: "remarks",
  },
  {
    name: "Document URL",
    key: "document_url",
  },
];

export default function VendorList() {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [documents, setDocuments] = React.useState<
    PreProcurementDocumentsType[]
  >([]);

  const { makeApiCall } = useApi();
  const navigate = useNavigate();

  React.useEffect(() => {
    makeApiCall(GetPreProcurementDocumentsApi())
      .then((response) => {
        if (response !== undefined) {
          setDocuments(response?.data);
        }
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, [makeApiCall]);

  const classNames = React.useMemo(
    () => ({
      th: [
        "bg-transparent",
        "text-tableHeaderColor",
        "border-b",
        "border-divider",
        "font-roboto",
        "font-regular",
        "text-sm",
      ],
      td: [
        "group-data-[first=true]:first:before:rounded-none",
        "group-data-[first=true]:last:before:rounded-none",
        "group-data-[middle=true]:before:rounded-none",
        "group-data-[last=true]:first:before:rounded-none",
        "group-data-[last=true]:last:before:rounded-none",
        "font-roboto",
        "font-normal",
        "text-textColorGrey",
      ],
      table: "min-h-[350px]",
      wrapper: "table-wrapper",
    }),
    [],
  );

  const renderStatus = React.useCallback(
    (item: PreProcurementDocumentsType) => {
      switch (item?.document_status) {
        case "approval_pending":
          return (
            <Chip variant="bordered" color="default">
              Approval Pending
            </Chip>
          );
        case "po_request_sent":
          return (
            <Chip variant="bordered" color="warning">
              PO Request Sent
            </Chip>
          );
        case "request_approved":
          return (
            <Chip variant="bordered" color="success">
              Request Approved
            </Chip>
          );
        case "request_rejected":
          return (
            <Chip variant="bordered" color="danger">
              Request Rejected
            </Chip>
          );
      }
    },
    [],
  );

  const renderType = React.useCallback((item: PreProcurementDocumentsType) => {
    switch (item?.document_type) {
      case "boq":
        return (
          <div className="flex flex-col">
            <p className="text-bold text-sm capitalize">BOQ</p>
          </div>
        );
      case "pi":
        return (
          <div className="flex flex-col">
            <p className="text-bold text-sm capitalize">PI</p>
          </div>
        );
      case "others":
        return (
          <div className="flex flex-col">
            <p className="text-bold text-sm capitalize">Others</p>
          </div>
        );
    }
  }, []);

  const renderCell = React.useCallback(
    (item: PreProcurementDocumentsType, columnKey: React.Key) => {
      const index = documents?.map((object) => object.id).indexOf(item.id);
      switch (columnKey) {
        case "sr_no":
          return (
            <div className="flex flex-col">
              <p className="text-bold text-sm capitalize">{index + 1}</p>
            </div>
          );
        case "client_name":
          return (
            <div className="flex flex-col">
              <p className="text-bold text-sm capitalize">{item.client_name}</p>
            </div>
          );
        case "document_name":
          return (
            <div className="flex flex-col">
              <p className="text-bold text-sm capitalize">
                {item.document_name}
              </p>
            </div>
          );
        case "document_type":
          return renderType(item);
        case "uploaded_date":
          return (
            <div className="flex flex-col">
              <p className="text-bold text-sm capitalize">
                {formatDateIntl(item.CreatedAt.split("T")[0])}
              </p>
            </div>
          );
        case "document_status":
          return renderStatus(item);
        case "remarks":
          return (
            <div className="flex flex-col">
              <p className="text-bold text-sm capitalize">
                {item.remarks === "" ? "NA" : item.remarks}
              </p>
            </div>
          );
        case "document_url":
          return <ViewDocument item={item} />;
      }
    },
    [documents, renderStatus, renderType],
  );

  const navigateToUpload = React.useCallback(() => {
    return navigate("/pre-procurement-documents/upload");
  }, [navigate]);

  const topContent = React.useMemo(() => {
    return (
      <Row justifyContent="flex-end">
        <Button
          color="primary"
          variant="bordered"
          className="font-roboto"
          size="sm"
          radius="sm"
          onClick={navigateToUpload}
        >
          Upload
        </Button>
      </Row>
    );
  }, [navigateToUpload]);

  return (
    <Main>
      <div className="text-black bg-pageBackground px-10 min-h-screen">
        <Spacer size="sm" />
        <Breadcrumb
          locations={[
            {
              name: "Pre-Procurement Documents",
              path: "/pre-procurement-documents",
            },
          ]}
        />
        <Spacer size="sm" />
        <Table
          selectionMode="single"
          classNames={classNames}
          topContent={topContent}
          topContentPlacement="outside"
        >
          <TableHeader columns={COLUMNS}>
            {(column) => (
              <TableColumn
                key={column.key}
                align={column.key === "action" ? "end" : "start"}
                width={column.key === "action" ? 100 : undefined}
                className="text-center"
              >
                {column.name}
              </TableColumn>
            )}
          </TableHeader>
          <TableBody
            emptyContent={!loading && "No rows to display."}
            items={documents ?? []}
            isLoading={loading}
            loadingContent={<Spinner label="Loading..." />}
          >
            {(item) => (
              <TableRow key={`${item.uuid}`} className="cursor-pointer h-12 ">
                {(columnKey) => (
                  <TableCell className="text-center">
                    {renderCell(item, columnKey)}
                  </TableCell>
                )}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </Main>
  );
}
