import React from "react";
import { Tabs, Tab } from "@nextui-org/react";
import ApprovalPolicy from "./ApprovalPolicy";
import LocationDirectory from "./LocationDirectory";
import Spacer from "../../../../src/components/ui/Spacer";

export default function AdminSettingsTabs() {
  const [selected, setSelected] = React.useState<React.Key>("my-cart");

  return (
    <div className="flex w-full flex-col">
      <Spacer size="sm" />
      <Tabs
        aria-label="Options"
        selectedKey={selected.toString()}
        onSelectionChange={setSelected}
        color="primary"
      >
        <Tab key="my-cart" title="Device Approval Policy">
          <ApprovalPolicy />
        </Tab>
        <Tab key="orders" title="Location Directory">
          <LocationDirectory />
        </Tab>
      </Tabs>
    </div>
  );
}
