import * as React from "react";
import * as Yup from "yup";
import { Formik, Form as FormikForm } from "formik";
import { Button, Spacer } from "@nextui-org/react";
import FileInput from "../../../../../components/ui/FileInput";
import { useSearchParams } from "react-router-dom";

const INTIAL_VALUES = {
  inventory: null as File | null,
};

interface Props {
  onSubmit: (
    name: string,
    code: string,
    inventory: File | null,
  ) => Promise<boolean> | undefined;
  loading: boolean;
}

export default function UploadProductDetailsForm({ loading, onSubmit }: Props) {
  const validationSchema = Yup.object().shape({
    documents: Yup.mixed().required("Documents are required"),
  });

  const [searchParams] = useSearchParams();
  const companyCode = searchParams.get("company_code") ?? "";
  const companyName = searchParams.get("company_name") ?? "";

  const handleSubmit = React.useCallback(
    ({ inventory }: typeof INTIAL_VALUES) => {
      onSubmit(companyCode, companyName, inventory);
    },
    [companyCode, companyName, onSubmit],
  );

  return (
    <div className="flex flex-col mt-[2%]">
      <p className="text-black text-lg leading-8 font-roboto font-normal">
        Upload Product Details
      </p>
      <Formik
        initialValues={INTIAL_VALUES}
        onSubmit={handleSubmit}
        validateOnBlur
        validateOnChange
        validationSchema={validationSchema}
        enableReinitialize
      >
        <FormikForm>
          <FileInput name="inventory" type="dropzone" accept=".csv" />
          <Spacer y={4} />
          <Button type="submit" disabled={loading}>
            Submit
          </Button>
        </FormikForm>
      </Formik>
    </div>
  );
}
