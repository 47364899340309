import React from "react";

interface ServiceItemProps {
  imageUrl: string;
  text: string;
  url: string; // New prop for URL
}

const ServiceItem: React.FC<ServiceItemProps> = ({ imageUrl, text, url }) => {
  const handleClick = () => {
    window.open(url, "_blank"); // Opens URL in a new tab
  };

  return (
    <div
      className="flex flex-col justify-center items-center p-4 mb-4 cursor-pointer rounded-lg"
      onClick={handleClick}
    >
      <img
        src={imageUrl}
        alt={text}
        className="w-auto h-auto max-h-20 opacity-70 rounded-md "
      />
      <p className="text-black text-sm font-roboto font-normal mt-3">{text}</p>
    </div>
  );
};

export default ServiceItem;
