import React from "react";
import { Link } from "react-router-dom";
import { Address, Article } from "../../../types";
import { Colors } from "../../../assets/colors";

interface Props {
  address: Address;
}

const AddressCardUs = ({ address }: Props) => {
  return (
    <>
      <div className="flex justify-center border ">
        <div className="text-zinc-800 relative mb-4 px-5 py-5  rounded-md  w-[100%] ">
          <div
            style={{ width: "100%" }}
            className="mb-4 rounded-md overflow-hidden"
          >
            <div
              className="map-container flex flex-row items-stretch" // Ensure items stretch to fill the container height
              style={{ width: "100%", height: "450px", border: "none" }} // Set a specific height for the container
            >
              <img
                src={address?.image}
                alt={address?.city}
                className="flex-1 object-cover bg-slate-400" // flex-1 makes the image take half of the container width
                style={{ width: "50%", height: "100%" }} // Explicit width and height settings can be omitted since flex-1 and items-stretch are used
              />
              <iframe
                src={address.iframe}
                style={{ width: "50%", height: "100%", border: 0 }} // Set the iframe to take the other half of the width
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title={`Map of ${address.city}`}
              ></iframe>
            </div>
          </div>
          <p className="text-black px-2 text-xl font-roboto font-medium mb-4">
            {address?.city}
          </p>
          <p className="text-black text-base px-2 leading-5 font-normal text-justify font-roboto w-[80%]">
            {address?.address}
          </p>
          <p className="text-black px-2 text-base font-roboto font-medium mb-4 mt-2">
            {address?.phone}
          </p>
        </div>
      </div>
    </>
  );
};

export default AddressCardUs;
