import React from "react";
import Main from "../../../components/ui/sidebar/Main";

const Dashboard = () => {
  return (
    <Main>
      <p>EPP dashboard</p>
    </Main>
  );
};

export default Dashboard;
