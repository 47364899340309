import React from "react";

const HeaderButton = ({ buttontext, onClick = () => {}, isActive = false }) => {
  return (
    <div>
      <button
        className={`
      bg-transparent hover:bg-gray-200 text-black font-roboto font-light border-transparent border-2
        transition duration-300 ease-in-out transform  mx-3
        px-3 py-2 sm:px-4 sm:py-2 md:px-1 md:py-1 text-xs sm:text-base md:text-base lg:text-base         ${
          isActive ? "" : ""
        } 

      `}
        onClick={onClick}
      >
        <p className="font-roboto font-light text-base text-black">
          {buttontext}
        </p>
      </button>
    </div>
  );
};

export default HeaderButton;
