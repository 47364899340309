import * as React from "react";
import Spacer from "../../../../components/ui/Spacer.tsx";
import useApi from "../../../../hooks/useApi.tsx";
import {
  GetAllInventoryApi,
  GetEppEmployesApi,
  UpdateDeviceRemarkApi,
} from "../../../../apis.ts";
import InventoryDataList from "../../../../components/pages/client/inventorydata-list/List.tsx";
import useToast from "../../../../hooks/useToast.tsx";
import {
  AssetInventoryType,
  DeviceInfoType,
  EmployeeDirectoryType,
} from "../../../../types.ts";
import secureLocalStorage from "react-secure-storage";
import EppInventoryList from "../../../../components/pages/client/inventorydata-list/EppInventoryList.tsx";
import Main from "../../../../components/ui/sidebar/Main.tsx";

const ViewAllInventory = () => {
  const { makeApiCall } = useApi();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const scheduleNumberParam = params.get("schedule_number");
  const statusParam = params.get("status");

  const { showToast } = useToast();

  const [deviceInfo, setDeviceInfo] = React.useState<AssetInventoryType[]>([]);
  const [deviceInfoQc, setDeviceInfoQc] = React.useState<DeviceInfoType[]>([]);
  const [employees, setEmployees] = React.useState<EmployeeDirectoryType[]>([]);
  const [loading, setLoading] = React.useState(false);

  // Rental schedule get API call
  React.useEffect(() => {
    setLoading(true);
    makeApiCall(GetAllInventoryApi())
      .then((response) => {
        if (response !== undefined && Array.isArray(response)) {
          setDeviceInfo(response);
        }
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, [makeApiCall, scheduleNumberParam, statusParam]);

  const handleDeviceRemark = React.useCallback(
    (serial_number: string, remark: string) => {
      return makeApiCall(UpdateDeviceRemarkApi(serial_number, remark))
        .then(() =>
          showToast("Remark update successfully", { type: "success" }),
        )
        .catch(() => showToast("Remark update failed", { type: "error" }));
    },
    [makeApiCall, showToast],
  );

  React.useEffect(() => {
    if (secureLocalStorage.getItem("platform") === "epp") {
      setLoading(true);
      makeApiCall(GetEppEmployesApi())
        .then((response) => {
          setEmployees(response?.data ?? ([] as EmployeeDirectoryType[]));
        })
        .catch((error) => console.error(error))
        .finally(() => setLoading(false));
    } else {
      setEmployees([] as EmployeeDirectoryType[]);
    }
  }, [makeApiCall]);

  return (
    <Main>
      <div className="text-black bg-pageBackground px-10 min-h-screen">
        <Spacer size="sm" />
        {secureLocalStorage.getItem("platform") === "epp" &&
        secureLocalStorage.getItem("role") === "employer" ? (
          <EppInventoryList
            deviceInfo={deviceInfo}
            loading={loading}
            onSubmitRemark={handleDeviceRemark}
            employees={employees}
          />
        ) : (
          <InventoryDataList
            deviceInfo={deviceInfo}
            loading={loading}
            onSubmitRemark={handleDeviceRemark}
          />
        )}
      </div>
    </Main>
  );
};

export default ViewAllInventory;
