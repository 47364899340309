import React from "react";
import { Colors } from "../../assets/colors";

// Define the props type for better type checking
interface TitleHeadProps {
  title: string;
  subtitle: string;
  summary: string;
}

// Correctly define the component to accept props
const TitleHead: React.FC<TitleHeadProps> = ({
  title = "",
  subtitle = "",
  summary = "",
}) => {
  return (
    <div>
      <div className=" text-[#1e0e62] mt-10  text-center text-2xl sm:text-3xl  font-roboto  font-light  py-2 ">
        <span className="font-light">{title}</span> {/* Dynamic title */}
      </div>
      <div>
        <p
          style={{ offsetPosition: "normal", color: Colors.text }}
          className="text-2xl sm:text-xl font-roboto text-center font-light"
        >
          {subtitle} {/* Dynamic subtitle */}
        </p>
      </div>
      <div className="flex justify-center items-center mt-2">
        <p
          style={{ offsetPosition: "normal", color: Colors.text }}
          className="  text-base font-roboto  py-4  text-center font-light  md:w-[70vw] "
        >
          {summary}
        </p>
      </div>
    </div>
  );
};

export default TitleHead;
