import React, { useEffect, useState } from "react";
import HeaderButton from "./common/HeaderButton";
import Hamburger from "hamburger-react";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const [showHeader, setShowHeader] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 80) {
        setShowHeader(true);
      } else {
        setShowHeader(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header
      className={`fixed w-full top-0 inset-x-0 z-30 bg-white transition-transform duration-300 ease-out ${
        showHeader ? "translate-y-0" : "-translate-y-full"
      }`}
    >
      <div className="flex justify-between items-center h-[80px] px-4">
        <div className="flex items-center">
          <button onClick={() => navigate("/")}>
            <img
              src="https://storage.googleapis.com/connectrpl-landing-pagev3/siteimages/logonew.png"
              alt="new"
              style={{ width: "9rem", height: "auto", marginLeft: "10rem" }}
            />
          </button>
        </div>

        <nav className="hidden lg:flex flex-nowrap items-center space-x-2">
          <HeaderButton
            buttontext="Home"
            onClick={() => {
              navigate("/");
            }}
          />
          <HeaderButton
            buttontext="About Us"
            onClick={() => {
              navigate("/aboutus");
            }}
          />
          <HeaderButton
            buttontext="Solutions"
            onClick={() => {
              navigate("/services");
            }}
          />
          <HeaderButton
            buttontext="Blogs"
            onClick={() => {
              navigate("/blogs");
            }}
          />
          <HeaderButton
            buttontext="Industries we cater to"
            onClick={() => {
              navigate("/industries");
            }}
          />
          <HeaderButton
            buttontext="Careers"
            onClick={() => {
              window.open(
                "https://connectrpl.zohorecruit.in/jobs/Careers",
                "_blank",
              );
            }}
          />
          <HeaderButton
            buttontext="Reach Us"
            onClick={() => {
              navigate("/reachus");
            }}
          />
        </nav>
        <div className="lg:hidden">
          <Hamburger size={20} />
        </div>
      </div>
    </header>
  );
};

export default Header;
