import * as React from "react";
import UploadProductDetailsForm from "../../../components/pages/epp/vendor/view-orders/UploadProductDetailsForm";
import Spacer from "../../../components/ui/Spacer";
import { AddClientApi } from "../../../apis";
import useApi from "../../../hooks/useApi.tsx";
import Main from "../../../components/ui/sidebar/Main.tsx";

export default function UploadProductDetails() {
  const [loading, setLoading] = React.useState(false);

  const { makeApiCall } = useApi();

  const handleSubmit = React.useCallback(
    (name: string, code: string, inventory: File | null) => {
      setLoading(true);
      return makeApiCall(AddClientApi(name, code, inventory!))
        .then(() => {
          return true;
        })
        .catch((error) => {
          return false;
        })
        .finally(() => setLoading(false));
    },
    [makeApiCall],
  );
  return (
    <Main>
      <div className="text-black bg-pageBackground px-10 min-h-screen">
        <Spacer size="sm" />
        <UploadProductDetailsForm onSubmit={handleSubmit} loading={loading} />
      </div>
    </Main>
  );
}
