import * as React from "react";
import Button from "../../components/ui/Button";
import { PreProcurementDocumentsType } from "../../types";

interface Props {
  item: PreProcurementDocumentsType;
}

export default function ViewDocument({ item }: Props) {
  const handleViewOrders = React.useCallback(() => {
    if (item.document_url) {
      window.open(item.document_url, "_blank");
    }
  }, [item]);

  return (
    <div className="flex flex-row justify-center gap-x-3 2xl:gap-x-4 whitespace-nowrap">
      <Button
        onClick={handleViewOrders}
        className="text-textColorGrey cursor-pointer font-roboto font-normal"
      >
        Download
      </Button>
    </div>
  );
}
