import React from "react";
import {
  ClientInvoiceNumbersList,
  SelectType,
} from "../../../../../../types.ts";
import {
  Table,
  TableHeader,
  TableBody,
  TableColumn,
  TableRow,
  TableCell,
  Spinner,
  Pagination,
  Input,
  Button,
  Tooltip,
  SortDescriptor,
} from "@nextui-org/react";
import Action from "./Action.tsx";
import Spacer from "../../../../../ui/Spacer.tsx";
import { CiFilter, CiSearch } from "react-icons/ci";
import Row from "../../../../../ui/Row.tsx";
import Select from "../../../../../ui/Select.tsx";
import { Datepicker } from "flowbite-react";
import { convert } from "../../../../../../../src/utils/fileSizeCalculator.ts";

interface Props {
  invoiceNumbers: ClientInvoiceNumbersList[];
  loading: boolean;
  downloadInvoice: (name: string, url: string) => void;
  onUpdateInvoice: () => void;
  mraUrl: string;
}

const COLUMNS = [
  { name: "Sr No.", key: "sr_no" },
  { name: "Invoice Number", key: "invoice_number" },
  { name: "Invoice Date", key: "invoice_date" },
  { name: "State", key: "customer_state" },
  { name: "Invoice Amount", key: "total_amount", sortable: true },
  { name: "Document Type", key: "document_type" },
  { name: "Funder Name", key: "funder_name" },
  { name: "Funder Amount", key: "funder_amount", sortable: true },
  { name: "Connect Amount", key: "connect_amount", sortable: true },
  { name: "Rental Schedule", key: "credit_ledger_1" },
  { name: "Rental Start Date", key: "rental_start_date", sortable: true },
  { name: "Rental End Date", key: "rental_end_date", sortable: true },
  { name: "Payment Amount", key: "payment_amount", sortable: true },
  { name: "Payment Date", key: "payment_date", sortable: true },
  { name: "Remark", key: "remark" },
  { name: "Action", key: "action" },
];
export default function InvoiceNumbersList({
  invoiceNumbers,
  loading,
  downloadInvoice,
  onUpdateInvoice,
  mraUrl,
}: Props) {
  const [filterValue, setFilterValue] = React.useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [selectedState, setSelectedState] = React.useState("all");
  const [showFilter, setShowFilter] = React.useState(false);
  const [dropdownFilter, setDropdownFilters] = React.useState<SelectType[]>([]);
  const [dropdownEinvoiceFilter, setDropdownEinvoiceFilters] = React.useState<
    SelectType[]
  >([]);

  const [tempSelectedState, setTempSelectedState] = React.useState("all");
  const [selectedEinvoice, setSelectedEinvoice] = React.useState("all");

  const [tempSelectedEinvoice, setTempSelectedEinvoice] = React.useState("all");

  const [dropdownCustomerName, setDropdownCustomerName] = React.useState<
    SelectType[]
  >([]);

  const [selectedCustomerName, setSelectedCustomerName] = React.useState("all");
  const [tempSelectedCustomerName, setTempSelectedCustomerName] =
    React.useState("all");

  const [startDate, setStartDate] = React.useState<string | null>(null);
  const [endDate, setEndDate] = React.useState<string | null>(null);

  const [dropdownInvoiceTypeFilter, setDropdownInvoiceTypeFilters] =
    React.useState<SelectType[]>([]);
  const [selectedInvoiceType, setSelectedInvoiceType] = React.useState("all");
  const [tempSelectedInvoiceType, setTempSelectedInvoiceType] =
    React.useState("all");

  const [dropdownRentalTypeFilter, setDropdownRentalTypeFilters] =
    React.useState<SelectType[]>([]);
  const [selectedRentalType, setSelectedRentalType] = React.useState("all");
  const [tempSelectedRentalType, setTempSelectedRentalType] =
    React.useState("all");

  const [dropdownScheduleNumberFilter, setDropdownScheduleNumberFilters] =
    React.useState<SelectType[]>([]);
  const [selectedScheduleNumber, setSelectedScheduleNumber] =
    React.useState("all");
  const [tempSelectedScheduleNumber, setTempSelectedScheduleNumber] =
    React.useState("all");

  const [sortDescriptor, setSortDescriptor] = React.useState<SortDescriptor>({
    column: "schedule_number",
    direction: "ascending",
  });

  const pages = React.useMemo(() => {
    if (invoiceNumbers.length === 0) {
      return 1;
    }
    return Math.ceil((invoiceNumbers?.length ?? 1) / rowsPerPage);
  }, [invoiceNumbers, rowsPerPage]);

  const hasSearchFilter = Boolean(filterValue);
  const [page, setPage] = React.useState(1);

  function parseInvoiceDate(dateString: string): Date {
    const [day, month, year] = dateString.split("/");
    const monthNumber = parseInt(month, 10);
    return new Date(`${year}-${monthNumber}-${day}`);
  }

  function resetTime(date: Date): Date {
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0);
    return newDate;
  }

  const filteredItems = React.useMemo(() => {
    let filteredUsers = invoiceNumbers || [];

    if (hasSearchFilter) {
      filteredUsers = filteredUsers?.filter(
        (user) =>
          user?.customer_name
            .toLowerCase()
            .includes(filterValue.toLowerCase()) ||
          user?.irn.toLowerCase().includes(filterValue.toLowerCase()),
      );
    }

    if (selectedState !== "all" && selectedState) {
      filteredUsers = filteredUsers?.filter(
        (user) => user?.place_of_supply === selectedState,
      );
    }

    if (selectedEinvoice !== "all" && selectedEinvoice) {
      filteredUsers = filteredUsers?.filter(
        (user) => user?.e_invoice_status === selectedEinvoice,
      );
    }

    if (selectedInvoiceType !== "all" && selectedInvoiceType) {
      filteredUsers = filteredUsers?.filter(
        (user) => user?.invoice_type === selectedInvoiceType,
      );
    }

    if (selectedRentalType !== "all" && selectedRentalType) {
      filteredUsers = filteredUsers?.filter(
        (user) => user?.rental_type === selectedRentalType,
      );
    }

    if (selectedScheduleNumber !== "all" && selectedScheduleNumber) {
      filteredUsers = filteredUsers?.filter(
        (user) => user?.rental_schedule === selectedScheduleNumber,
      );
    }
    if (selectedCustomerName !== "all" && selectedCustomerName) {
      filteredUsers = filteredUsers?.filter(
        (user) => user?.customer_name === selectedCustomerName,
      );
    }

    // Filter by date range (start and end date)
    if (startDate && endDate) {
      filteredUsers = filteredUsers?.filter((user) => {
        // Parse invoice date (DD-MMM-YYYY format)
        // Parse invoice date (DD-MMM-YYYY format)
        const invoiceDate = parseInvoiceDate(user.invoice_date);

        // Parse startDate and endDate (assuming they are in "Month Day, Year" format)
        const startDateValue = new Date(startDate);
        const endDateValue = new Date(endDate);

        // Reset the time part of startDate, endDate, and invoiceDate
        const resetStartDate = resetTime(startDateValue);
        const resetEndDate = resetTime(endDateValue);
        const resetInvoiceDate = resetTime(invoiceDate);
        // Compare the dates, ignoring the time portion
        return (
          resetInvoiceDate >= resetStartDate && resetInvoiceDate <= resetEndDate
        );
      });
    }

    return filteredUsers;
  }, [
    invoiceNumbers,
    hasSearchFilter,
    selectedState,
    selectedEinvoice,
    selectedInvoiceType,
    selectedRentalType,
    selectedScheduleNumber,
    selectedCustomerName,
    startDate,
    endDate,
    filterValue,
  ]);

  const items = React.useMemo(() => {
    const start = (page - 1) * rowsPerPage;
    const end = start + rowsPerPage;
    return filteredItems.slice(start, end);
  }, [page, filteredItems, rowsPerPage]);

  const onRowsPerPageChange = React.useCallback((e) => {
    setRowsPerPage(Number(e.target.value));
    setPage(1);
  }, []);

  const onSearchChange = React.useCallback((value) => {
    if (value) {
      setFilterValue(value);
      setPage(1);
    } else {
      setFilterValue("");
    }
  }, []);

  // Handle the state selection change

  const handleStateSelect = React.useCallback((value: string) => {
    setTempSelectedState(value);
  }, []);
  const handleEinvoiceSelect = React.useCallback((value: string) => {
    setTempSelectedEinvoice(value);
  }, []);

  const handleCustomerName = React.useCallback((value: string) => {
    setTempSelectedCustomerName(value);
  }, []);

  const handleInvoiceTypeSelect = React.useCallback((value: string) => {
    setTempSelectedInvoiceType(value);
  }, []);
  const handleRentalTypeSelect = React.useCallback((value: string) => {
    setTempSelectedRentalType(value);
  }, []);

  const handleScheduleNumberSelect = React.useCallback((value: string) => {
    setTempSelectedScheduleNumber(value);
  }, []);

  const renderCell = React.useCallback(
    (client: ClientInvoiceNumbersList, columnKey: React.Key) => {
      const index = invoiceNumbers
        .map((object) => object.invoice_number)
        .indexOf(client.invoice_number);
      switch (columnKey) {
        case "sr_no":
          return (
            <div className="flex flex-col">
              <p className="text-bold text-sm capitalize">{index + 1}</p>
            </div>
          );

        case "schedule_number":
          return (
            <div className="flex flex-col">
              <p className="text-bold text-sm capitalize">
                {client.rental_schedule}
              </p>
            </div>
          );
        case "total_amount":
          return (
            <div className="flex flex-col">
              <p className="text-bold text-sm capitalize">
                {client.total_invoice_value}
              </p>
            </div>
          );
        case "rental_start_date":
          return (
            <div className="flex flex-col">
              <p className="text-bold text-sm capitalize">
                {client.rental_start_date}
              </p>
            </div>
          );

        case "asset_value":
          return (
            <div className="flex flex-col">
              <p className="text-sm">{client.asset_value}</p>
            </div>
          );
        case "billing_state":
          return (
            <div className="flex flex-col">
              <p className="text-sm">{client.billing_state}</p>
            </div>
          );
        case "cess_amount":
          return (
            <div className="flex flex-col">
              <p className="text-sm">{client.cess_amount}</p>
            </div>
          );
        case "cess_amount_val":
          return (
            <div className="flex flex-col">
              <p className="text-sm">{client.cess_amount_val}</p>
            </div>
          );
        case "cgst_amount":
          return (
            <div className="flex flex-col">
              <p className="text-sm">{client.cgst_amount}</p>
            </div>
          );
        case "cgst_amount_val":
          return (
            <div className="flex flex-col">
              <p className="text-sm">{client.cgst_amount_val}</p>
            </div>
          );
        case "connect_amount":
          return (
            <div className="flex flex-col">
              <p className="text-sm">{client.connect_amount}</p>
            </div>
          );
        case "connect_state":
          return (
            <div className="flex flex-col">
              <p className="text-sm">{client.connect_state}</p>
            </div>
          );
        case "credit_ledger_1":
          return (
            <div className="flex flex-col">
              <p className="text-sm">
                {client.credit_ledger_1.split(" ").pop()}
              </p>
            </div>
          );
        case "credit_ledger_1_amount":
          return (
            <div className="flex flex-col">
              <p className="text-sm">{client.credit_ledger_1_amount}</p>
            </div>
          );
        case "credit_ledger_2":
          return (
            <div className="flex flex-col">
              <p className="text-sm">{client.credit_ledger_2}</p>
            </div>
          );
        case "credit_ledger_2_amount":
          return (
            <div className="flex flex-col">
              <p className="text-sm">{client.credit_ledger_2_amount}</p>
            </div>
          );
        case "credit_ledger_3":
          return (
            <div className="flex flex-col">
              <p className="text-sm">{client.credit_ledger_3}</p>
            </div>
          );
        case "credit_ledger_3_amount":
          return (
            <div className="flex flex-col">
              <p className="text-sm">{client.credit_ledger_3_amount}</p>
            </div>
          );
        case "credit_ledger_4":
          return (
            <div className="flex flex-col">
              <p className="text-sm">{client.credit_ledger_4}</p>
            </div>
          );
        case "credit_ledger_4_amount":
          return (
            <div className="flex flex-col">
              <p className="text-sm">{client.credit_ledger_4_amount}</p>
            </div>
          );
        case "credit_ledger_5":
          return (
            <div className="flex flex-col">
              <p className="text-sm">{client.credit_ledger_5}</p>
            </div>
          );
        case "credit_ledger_5_amount":
          return (
            <div className="flex flex-col">
              <p className="text-sm">{client.credit_ledger_5_amount}</p>
            </div>
          );
        case "customer_address_line_1":
          return (
            <div className="flex flex-col">
              <p className="text-sm">{client.customer_address_line_1}</p>
            </div>
          );
        case "customer_gst_number":
          return (
            <div className="flex flex-col">
              <p className="text-sm">{client.customer_gst_number}</p>
            </div>
          );
        case "customer_gstin":
          return (
            <div className="flex flex-col">
              <p className="text-sm">{client.customer_gstin}</p>
            </div>
          );
        case "customer_name":
          return (
            <div className="flex flex-col">
              <p className="text-sm">{client.customer_name}</p>
            </div>
          );
        case "customer_state":
          return (
            <div className="flex flex-col">
              <p className="text-sm">{client.customer_state}</p>
            </div>
          );
        case "debit_party_ledger":
          return (
            <div className="flex flex-col">
              <p className="text-sm">{client.debit_party_ledger}</p>
            </div>
          );
        case "document_type":
          return (
            <div className="flex flex-col">
              <p className="text-sm">{client.document_type}</p>
            </div>
          );
        case "e_invoice_status":
          return (
            <div className="flex flex-col">
              <p className="text-sm">{client.e_invoice_status}</p>
            </div>
          );
        case "funder_amount":
          return (
            <div className="flex flex-col">
              <p className="text-sm">{client.funder_amount}</p>
            </div>
          );
        case "funder_name":
          return (
            <div className="flex flex-col">
              <p className="text-sm">{client.funder_name}</p>
            </div>
          );
        case "igst_amount":
          return (
            <div className="flex flex-col">
              <p className="text-sm">{client.igst_amount}</p>
            </div>
          );
        case "igst_amount_val":
          return (
            <div className="flex flex-col">
              <p className="text-sm">{client.igst_amount_val}</p>
            </div>
          );
        case "invoice_date":
          return (
            <div className="flex flex-col">
              <p className="text-sm">{client.invoice_date}</p>
            </div>
          );
        case "invoice_number":
          return (
            <div className="flex flex-col">
              <p className="text-sm">{client.invoice_number}</p>
            </div>
          );
        case "invoice_pdf_url":
          return (
            <div className="flex flex-col">
              <p className="text-sm">{client.invoice_pdf_url}</p>
            </div>
          );
        case "invoice_transaction_type":
          return (
            <div className="flex flex-col">
              <p className="text-sm">{client.invoice_transaction_type}</p>
            </div>
          );
        case "invoice_type":
          return (
            <div className="flex flex-col">
              <p className="text-sm">{client.invoice_type}</p>
            </div>
          );
        case "irn":
          return (
            <div className="flex flex-col">
              <p className="text-sm">{client.irn}</p>
            </div>
          );
        case "item_description":
          return (
            <div className="flex flex-col">
              <p className="text-sm">{client.item_description}</p>
            </div>
          );
        case "other_charges":
          return (
            <div className="flex flex-col">
              <p className="text-sm">{client.other_charges}</p>
            </div>
          );
        case "payment_amount":
          return (
            <div className="flex flex-col">
              <p className="text-sm">{client.payment_amount || "-"}</p>
            </div>
          );
        case "payment_date":
          return (
            <div className="flex flex-col">
              <p className="text-sm">{client.payment_date || "-"}</p>
            </div>
          );
        case "place_of_supply":
          return (
            <div className="flex flex-col">
              <p className="text-sm">{client.place_of_supply}</p>
            </div>
          );
        case "remark":
          return (
            <div className="flex flex-col">
              <p className="text-sm">{client.remark || "-"}</p>
            </div>
          );
        case "rental_end_date":
          return (
            <div className="flex flex-col">
              <p className="text-sm">{client.rental_end_date}</p>
            </div>
          );
        case "rental_schedule":
          return (
            <div className="flex flex-col">
              <p className="text-sm">{client.rental_schedule}</p>
            </div>
          );
        case "rental_schedule_state":
          return (
            <div className="flex flex-col">
              <p className="text-sm">{client.rental_schedule_state}</p>
            </div>
          );
        // case "payment_status":
        //   return (
        //     <div className="flex flex-col">
        //       <p className="text-bold text-sm capitalize">
        //         {client.PaymentStatus}
        //       </p>
        //       {client.PaymentStatus === true ? (
        //         <>
        //           <Tooltip content="download" size="sm">
        //             <p className=" text-textColorGrey underline cursor-pointer font-roboto font-light">
        //               Payment Received
        //             </p>
        //           </Tooltip>
        //         </>
        //       ) : (
        //         <>
        //           <Tooltip content="download" size="sm">
        //             <p className=" text-textColorGrey underline cursor-pointer font-roboto font-light">
        //               Pending
        //             </p>
        //           </Tooltip>
        //         </>
        //       )}
        //     </div>
        //   );
        case "action":
          return (
            <div className="flex">
              <Action item={client} downloadInvoice={downloadInvoice} />
            </div>
          );
        case "status":
          return null;
      }
    },
    [invoiceNumbers, downloadInvoice],
  );

  React.useEffect(() => {
    const dropdownOptionsData: SelectType[] = (invoiceNumbers || [])
      .map((item: ClientInvoiceNumbersList) => ({
        value: item.place_of_supply,
        label: item.place_of_supply,
      }))
      .filter(
        (option, index, self) =>
          index === self.findIndex((o) => o.value === option.value),
      );
    dropdownOptionsData?.unshift({ value: "all", label: "All" });
    setDropdownFilters(dropdownOptionsData || []);

    const dropdownEinvoiceOptionsData: SelectType[] = (invoiceNumbers || [])
      .map((item: ClientInvoiceNumbersList) => ({
        value: item.e_invoice_status,
        label: item.e_invoice_status,
      }))
      .filter(
        (option, index, self) =>
          index === self.findIndex((o) => o.value === option.value),
      );
    dropdownEinvoiceOptionsData?.unshift({ value: "all", label: "All" });
    setDropdownEinvoiceFilters(dropdownEinvoiceOptionsData || []);

    const dropdownInvoiceTypeOptionsData: SelectType[] = (invoiceNumbers || [])
      .map((item: ClientInvoiceNumbersList) => ({
        value: item.invoice_type,
        label: item.invoice_type,
      }))
      .filter(
        (option, index, self) =>
          index === self.findIndex((o) => o.value === option.value),
      );
    dropdownInvoiceTypeOptionsData?.unshift({ value: "all", label: "All" });
    setDropdownInvoiceTypeFilters(dropdownInvoiceTypeOptionsData || []);

    const dropdownRentalTypeOptionsData: SelectType[] = (invoiceNumbers || [])
      .map((item: ClientInvoiceNumbersList) => ({
        value: item.rental_type,
        label: item.rental_type,
      }))
      .filter(
        (option, index, self) =>
          index === self.findIndex((o) => o.value === option.value),
      );
    dropdownRentalTypeOptionsData?.unshift({ value: "all", label: "All" });
    setDropdownRentalTypeFilters(dropdownRentalTypeOptionsData || []);

    const dropdownScheduleNumberOptionsData: SelectType[] = (
      invoiceNumbers || []
    )
      .map((item: ClientInvoiceNumbersList) => ({
        value: item.rental_schedule,
        label: item.rental_schedule,
      }))
      .filter(
        (option, index, self) =>
          index === self.findIndex((o) => o.value === option.value),
      );
    dropdownScheduleNumberOptionsData?.unshift({ value: "all", label: "All" });
    setDropdownScheduleNumberFilters(dropdownScheduleNumberOptionsData || []);

    const dropdownCustomerOptionsData: SelectType[] = (invoiceNumbers || [])
      .map((item: ClientInvoiceNumbersList) => ({
        value: item.customer_name,
        label: item.customer_name,
      }))
      .filter(
        (option, index, self) =>
          index === self.findIndex((o) => o.value === option.value),
      );
    dropdownCustomerOptionsData?.unshift({ value: "all", label: "All" });
    setDropdownCustomerName(dropdownCustomerOptionsData || []);
  }, [invoiceNumbers]);

  const sortedItems = React.useMemo(() => {
    return [...items].sort(
      (a: ClientInvoiceNumbersList, b: ClientInvoiceNumbersList) => {
        const first = a[
          sortDescriptor.column as keyof ClientInvoiceNumbersList
        ] as number;
        const second = b[
          sortDescriptor.column as keyof ClientInvoiceNumbersList
        ] as number;
        const cmp = first < second ? -1 : first > second ? 1 : 0;

        return sortDescriptor.direction === "descending" ? -cmp : cmp;
      },
    );
  }, [sortDescriptor, items]);

  const onStartDateFilter = React.useCallback((date: Date) => {
    console.log(date, "Start date value");
    const formattedDate = convert(date.toString());
    setStartDate(formattedDate);
  }, []);

  const onEndDateFilter = React.useCallback((date: Date) => {
    console.log(date, "StaEndrt date value");
    const formattedDate = convert(date.toString());
    setEndDate(formattedDate);
  }, []);

  const onClickFilter = React.useCallback(() => {
    setSelectedState(tempSelectedState);
    setSelectedEinvoice(tempSelectedEinvoice);
    setSelectedCustomerName(tempSelectedCustomerName);
    setStartDate(startDate);
    setEndDate(endDate);
    setSelectedInvoiceType(tempSelectedInvoiceType);
    setSelectedRentalType(tempSelectedRentalType);
    setSelectedScheduleNumber(tempSelectedScheduleNumber);
    setShowFilter(false);
  }, [
    tempSelectedState,
    tempSelectedEinvoice,
    tempSelectedCustomerName,
    startDate,
    endDate,
    tempSelectedInvoiceType,
    tempSelectedRentalType,
    tempSelectedScheduleNumber,
  ]);

  const topContent = React.useMemo(() => {
    return (
      <div className="relative flex flex-col gap-4">
        <div className="flex justify-between gap-3 items-end my-2">
          <Input
            isClearable
            className="focus:outline-none focus:border-none"
            classNames={{
              base: "w-full sm:max-w-[44%] focus:outline-none focus:border-none",
              inputWrapper:
                "border-0 focus:border-0 focus:outline-none focus:border-none",
              input: "border-0 focus:outline-none focus:border-none",
            }}
            placeholder="Search by schedule number..."
            size="sm"
            startContent={<CiSearch />}
            value={filterValue}
            onClear={() => {
              setFilterValue("");
              setSelectedState("all");
            }}
            onValueChange={onSearchChange}
          />

          <div className="flex gap-3">
            <Row>
              <Row className="flex mr-5">
                <Datepicker
                  title="Start Date"
                  onSelectedDateChanged={onStartDateFilter}
                  placeholder="Start date"
                  value={startDate || ""}
                  className="w-[30%] p-0 mr-4"
                />
                <Datepicker
                  title="End Range"
                  onSelectedDateChanged={onEndDateFilter}
                  placeholder="End date"
                  value={endDate || ""}
                  className="w-[30%] p-0"
                />
              </Row>
              <Button
                color="default"
                variant="bordered"
                startContent={<CiFilter size={18} />}
                className="font-roboto"
                size="sm"
                radius="sm"
                onClick={() => {
                  setShowFilter(!showFilter);
                  setSelectedState("all");
                }}
              >
                Filter
              </Button>
            </Row>
          </div>
        </div>
        {showFilter && (
          <div className="absolute top-full mt-2 right-0 w-[20%] px-6 py-4 rounded shadow-xl z-10 bg-white">
            <p className="text-black text-base leading-8 font-roboto font-medium mt-[4%] bg-white">
              Filter
            </p>
            <div className="w-full h-5" />
            <Select
              name="filter"
              item={dropdownFilter}
              label="State"
              placeholder="State"
              value={selectedState}
              onSelect={handleStateSelect}
              className="font-roboto text-xl font-light"
            />
            <div className="w-full h-5" />

            <Select
              name="filter"
              item={dropdownEinvoiceFilter}
              label="E-invoice status"
              placeholder="Status"
              value={selectedEinvoice}
              onSelect={handleEinvoiceSelect}
              className="font-roboto text-xl font-light"
            />
            <div className="w-full h-5" />

            <Select
              name="filter"
              item={dropdownInvoiceTypeFilter}
              label="Invoice Type"
              placeholder="Type"
              value={selectedInvoiceType}
              onSelect={handleInvoiceTypeSelect}
              className="font-roboto text-xl font-light"
            />
            <div className="w-full h-5" />

            <Select
              name="filter"
              item={dropdownRentalTypeFilter}
              label="Rental Type"
              placeholder="rental type"
              value={selectedRentalType}
              onSelect={handleRentalTypeSelect}
              className="font-roboto text-xl font-light"
            />
            <div className="w-full h-5" />

            <Select
              name="filter"
              item={dropdownScheduleNumberFilter}
              label="Schedule Number"
              placeholder="schedule number"
              value={selectedScheduleNumber}
              onSelect={handleScheduleNumberSelect}
              className="font-roboto text-xl font-light"
            />
            <div className="w-full h-5" />

            <Select
              name="filter"
              item={dropdownCustomerName}
              label="Customer Name"
              value={selectedCustomerName}
              placeholder="Customer name"
              onSelect={handleCustomerName}
              className="font-roboto text-xl font-light"
            />
            <div>
              <Row>
                <Button
                  color="default"
                  className="font-roboto font-light text-sm bg-blue text-white w-[100%] mt-4"
                  size="md"
                  radius="sm"
                  onPress={onClickFilter}
                >
                  Filter
                </Button>
              </Row>
            </div>
          </div>
        )}
      </div>
    );
  }, [
    dropdownCustomerName,
    dropdownEinvoiceFilter,
    dropdownFilter,
    dropdownInvoiceTypeFilter,
    dropdownRentalTypeFilter,
    dropdownScheduleNumberFilter,
    filterValue,
    handleCustomerName,
    handleEinvoiceSelect,
    handleInvoiceTypeSelect,
    handleRentalTypeSelect,
    handleScheduleNumberSelect,
    handleStateSelect,
    onClickFilter,
    onSearchChange,
    selectedCustomerName,
    selectedEinvoice,
    selectedInvoiceType,
    selectedRentalType,
    selectedScheduleNumber,
    selectedState,
    showFilter,
  ]);

  const bottomContent = React.useMemo(() => {
    return (
      <div className="py-2 px-2 flex justify-between items-center">
        <Pagination
          showControls
          classNames={{
            item: "bg-pageBackground w-6 h-6 min-w-4 font-roboto",
            cursor: "w-6 h-6 min-w-4 font-roboto",
          }}
          color="default"
          isDisabled={hasSearchFilter}
          page={page}
          total={pages}
          variant="light"
          onChange={setPage}
        />
        <div className="flex justify-between items-center">
          <label className="flex items-center text-small font-roboto text-black font-light ">
            Items per page :&nbsp;
            <select
              className="border-none shadow-sm outline-none text-default-400 text-small font-roboto font-light px-1 py-0 rounded-md"
              onChange={onRowsPerPageChange}
              defaultValue={"20"}
            >
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
              <option value="25">25</option>
            </select>
          </label>
        </div>
      </div>
    );
  }, [hasSearchFilter, page, pages, onRowsPerPageChange]);

  const classNames = React.useMemo(
    () => ({
      th: [
        "bg-transparent",
        "text-tableHeaderColor",
        "border-b",
        "border-divider",
        "font-roboto",
        "font-regular",
        "text-sm",
      ],
      td: [
        "group-data-[first=true]:first:before:rounded-none",
        "group-data-[first=true]:last:before:rounded-none",
        "group-data-[middle=true]:before:rounded-none",
        "group-data-[last=true]:first:before:rounded-none",
        "group-data-[last=true]:last:before:rounded-none",
        "font-roboto",
        "font-normal",
        "text-textColorGrey",
      ],
      table: "min-h-[350px]",
      wrapper: "table-wrapper",
    }),
    [],
  );

  return (
    <div className="flex flex-col">
      <p className="text-black text-lg leading-8 font-roboto font-normal mt-[2%]">
        Invoices
      </p>
      <Spacer size="xs" />

      {mraUrl !== "" && (
        <>
          <p>
            MRA:{" "}
            <a href={mraUrl} target="_blank" rel="noopener noreferrer">
              {mraUrl?.split("/").pop()}
            </a>
          </p>
          <Spacer size="xs" />
        </>
      )}

      <Table
        selectionMode="single"
        classNames={classNames}
        topContent={topContent}
        bottomContent={bottomContent}
        bottomContentPlacement="inside"
        sortDescriptor={sortDescriptor}
        onSortChange={setSortDescriptor}
      >
        <TableHeader columns={COLUMNS}>
          {(column) => (
            <TableColumn
              allowsSorting={column.sortable}
              key={column.key}
              align={column.key === "action" ? "end" : "start"}
              width={column.key === "action" ? 100 : undefined}
              className="text-center"
            >
              {column.name}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody
          emptyContent={!loading && "No rows to display."}
          items={sortedItems}
          isLoading={loading}
          loadingContent={<Spinner label="Loading..." />}
        >
          {(item) => (
            <TableRow
              key={`${item.rental_schedule}-${item.ID}`}
              className="cursor-pointer h-12 "
            >
              {(columnKey) => (
                <TableCell className="text-center">
                  {renderCell(item, columnKey)}
                </TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
}
