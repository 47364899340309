import * as React from "react";
import Row from "../../../ui/Row.tsx";
import { Tooltip } from "@nextui-org/react";
import { AssetInventoryType, DeviceInfoType } from "../../../../types.ts";

interface Props {
  item: DeviceInfoType;
  handleDownloadCsv: (item: DeviceInfoType) => void;
  downloadInvoice: (name: string, url: string) => void;
}

export default function ActionQc({
  item,
  handleDownloadCsv,
  downloadInvoice,
}: Props) {
  const handleDownloadItemCsv = React.useCallback(() => {
    handleDownloadCsv(item);
  }, [handleDownloadCsv, item]);
  return (
    <div className="flex flex-row justify-end gap-x-3 2xl:gap-x-4 whitespace-nowrap">
      <a
        href="#"
        onClick={handleDownloadItemCsv}
        className="text-textColorGrey  cursor-pointer font-roboto font-normal"
      >
        Details
      </a>
      {/* <a
          href="#"
          className="text-textColorGrey cursor-pointer font-roboto font-normal"
        >
          Buyback
        </a> */}
    </div>
  );
}
