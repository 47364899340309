import React from "react";

interface HeadingBannerProps {
  imageUrl: string;
  text: string;
  desc: string;
}

const HeadingBanner: React.FC<HeadingBannerProps> = ({
  imageUrl,
  text,
  desc,
}) => {
  return (
    <div className="relative">
      <img src={imageUrl} alt="Banner" className="w-full h-auto" />
      <div className="absolute bottom-0   text-white py-4 ml-[10%]">
        <p className="text-4xl font-light font-roboto text-white ">{text}</p>
        <p className="text-lg font-light font-roboto text-white mt-4">{desc}</p>
      </div>
    </div>
  );
};

export default HeadingBanner;
