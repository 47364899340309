import React from "react";
import { LineSvg } from "../../../assets/images/Images";

export const AddressCard = ({ cardtitle, content, Banner, left = false }) => {
  return (
    <>
      <div className=" flex justify-center items-center">
        <div className=" flex flex-col w-[30%] " />
        <LineSvg width={50} height={4} color={undefined} />
        <div className={"flex flex-row"}>
          <img src={Banner} alt="card image" className="" />
        </div>
        <LineSvg width={50} height={4} color={"#00006D"} />
        <div className=" flex flex-col w-[30%] px-4">
          <p className=" text-black font-roboto text-xl font-medium mt-[4%]">
            {cardtitle}
          </p>
          <p className=" text-black font-roboto text-base font-light mt-[2%]">
            {content}
          </p>
        </div>
      </div>
    </>
  );
};

export const AddressCardLeft = ({
  cardtitle,
  content,
  Banner,
  left = false,
}) => {
  return (
    <>
      <div className=" flex justify-center items-center">
        <div className=" flex flex-col w-[30%] pr-4">
          <p className=" text-black font-roboto text-xl font-medium mt-[4%]">
            {cardtitle}
          </p>
          <p className=" text-black font-roboto text-base font-light mt-[2%]">
            {content}
          </p>
        </div>
        <LineSvg width={50} height={4} color={"#00006D"} />
        <div className={"flex flex-row"}>
          <img src={Banner} alt="card image" className="" />
        </div>
        <LineSvg width={50} height={4} color={undefined} />
        <div className=" flex flex-col w-[30%] " />
      </div>
    </>
  );
};

{
  /* <div
className={`flex flex-row ${left ? "flex-row-reverse" : ""} ${
  left ? "mr-[40%]" : "ml-[40%]"
}`}
>
<img src={Banner} alt="card image" />
<img src={Line} alt="line" width={50} className="mx-2" />
<div className="bg-neutral-600 flex flex-col">
  <p className=" text-black font-roboto text-xl font-medium mt-[4%]">
    {cardtitle}
  </p>
  <p className=" text-black font-roboto text-base font-light mt-[2%]">
    {content}
  </p>
</div>
</div> */
}
