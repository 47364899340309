import React from "react";
import Spacer from "../../../components/ui/Spacer.tsx";
import { Button, Input } from "@nextui-org/react";
import Select from "../../../components/ui/Select.tsx";
import { SelectType } from "../../../types.ts";
import Main from "../../../components/ui/sidebar/Main.tsx";

const Automation = () => {
  const [dropdownFilter, setDropdownFilters] = React.useState<SelectType[]>([
    {
      label: "Schedule Expiry",
      value: "schedule_expiry",
    },
  ]);

  return (
    <Main>
      <div className="text-black bg-pageBackground px-10 min-h-screen">
        <Spacer size="sm" />
        <p className="text-black text-lg leading-8 font-roboto font-normal mt-[2%]">
          Automation
        </p>
        <Spacer size="xs" />
        <div className="bg-white w-[90%] px-[2%] py-[2%] shadow-box rounded-lg">
          <p className="text-black text-base leading-8 font-roboto font-normal mb-14">
            Setup whome to notify for various events
          </p>
          <Select
            name="filter"
            item={dropdownFilter}
            label="Type of Automation"
            placeholder="Type of Automation"
            selectedKeys={["schedule_expiry"]}
            className="font-roboto text-xl font-normal max-w-xs"
          />
          <Spacer size="xs" />
          <Input isRequired label="Name" className="max-w-xs h-12" />{" "}
          <Spacer size="xs" />
          <Input
            isRequired
            type="email"
            label="Email"
            className="max-w-xs h-12"
          />
          <Spacer size="xs" />
          <div className="flex flex-row  items-center">
            <Input
              isRequired
              type="number"
              label="Notify Before"
              className="max-w-xs h-12"
            />
            <p className="text-black text-xs leading-8 font-roboto font-normal ml-4">
              Days
            </p>
          </div>
          <Button className="bg-buttonprimary mt-5 w-[20%] max-w-xs">
            <p className="text-white">Save</p>
          </Button>
        </div>
      </div>
    </Main>
  );
};

export default Automation;
