import * as React from "react";
import Row from "../../../../../ui/Row.tsx";
import { ClientInvoiceNumbersList } from "../../../../../../types.ts";
import { Tooltip } from "@nextui-org/react";
import Button from "../../../../../ui/Button.tsx";

interface Props {
  item: ClientInvoiceNumbersList;
  downloadInvoice: (name: string, url: string) => void;
}

export default function Action({ item, downloadInvoice }: Props) {
  const handleDownloadInvoice = React.useCallback(() => {
    downloadInvoice(item?.InvoiceNumber, item?.DocumentUrl);
  }, [downloadInvoice, item?.InvoiceNumber, item?.DocumentUrl]);

  return (
    <Row>
      <Tooltip content="download" size="sm">
        <button
          onClick={handleDownloadInvoice}
          className="text-textColorGrey underline cursor-pointer font-roboto font-light"
        >
          Download
        </button>
      </Tooltip>
    </Row>
  );
}
