import * as React from "react";
import Row from "../../../ui/Row.tsx";
import { Tooltip } from "@nextui-org/react";
import { ClientScheduleNumbersList } from "../../../../types.ts";

interface Props {
  item: ClientScheduleNumbersList;
  downloadRental: () => void;
  onViewInventory: (
    schedule_number: string,
    item: ClientScheduleNumbersList,
  ) => void;
  downloadQrCodes: (scheduleNumber: string) => void;
}

export default function Action({
  item,
  downloadRental,
  onViewInventory,
  downloadQrCodes,
}: Props) {
  const handleDownloadQrCodes = React.useCallback(() => {
    if (item?.ScheduleNumber !== undefined) {
      downloadQrCodes(item.ScheduleNumber);
    }
  }, [downloadQrCodes, item?.ScheduleNumber]);

  const handleViewInventory = React.useCallback(() => {
    onViewInventory(item.ScheduleNumber, item);
  }, [item, onViewInventory]);

  return (
    <div className="flex flex-row justify-end gap-x-3 2xl:gap-x-4 whitespace-nowrap">
      <button
        onClick={handleViewInventory}
        className="text-textColorGrey cursor-pointer font-roboto font-normal"
      >
        View Inventory
      </button>

      <button
        onClick={handleDownloadQrCodes}
        className="text-textColorGrey cursor-pointer font-roboto font-normal"
      >
        QR Codes
      </button>
    </div>
  );
}
