import React, { useState } from "react";
import Card from "../../../components/pages/epp/Card.tsx";
import useApi from "../../../hooks/useApi.tsx";
import { CreateOrderApi, GetEppProductApi } from "../../../apis.ts";
import { toast } from "react-toastify";
import Main from "../../../components/ui/sidebar/Main.tsx";

// Define the Product interface
interface Product {
  ProductID: string;
  Category: string;
  Name: string;
  Model: string;
  Storage: string;
  Varients: string[];
  Specification: string;
  MRP: number;
  MonthlyRent: number;
  BenefitCost: number;
  CompanyCode: string;
  BuybackPrice: number;
  Discount: number;
  IsActive: boolean;
  GST: number;
  ProductImage: string;
  NumberOfInstallments: number;
}

const Products: React.FC = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = React.useState(false);
  const { makeApiCall } = useApi();
  const [filteredCategory, setFilteredCategory] = useState("All");

  React.useEffect(() => {
    setLoading(true);
    makeApiCall(GetEppProductApi())
      .then((response) => {
        if (response?.data && Array.isArray(response.data)) {
          const updatedData = response.data.map((item: any) => {
            return {
              ...item,
              Varients: JSON.parse(item.Variants),
            };
          });
          setProducts(updatedData);
        }
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, [makeApiCall]);

  // Get unique categories for filtering
  const categories = [
    "All",
    ...new Set(products?.map((product) => product.Category)),
  ];

  // Filter products by selected category
  const filteredProducts =
    filteredCategory === "All"
      ? products
      : products.filter((product) => product.Category === filteredCategory);

  const handleSubmit = React.useCallback(
    (product: Product) => {
      return makeApiCall(
        CreateOrderApi(
          product.ProductID,
          product.Specification,
          product.MonthlyRent,
          product.Varients[0],
          1,
        ),
      )
        .then((response) => {
          if (response?.status === true) {
            toast.success("Order added succesfully");
          } else {
            toast.error("Order creation failed");
          }
        })
        .catch((error) => {
          return false;
        })
        .finally(() => setLoading(false));
    },
    [makeApiCall],
  );

  return (
    <Main>
      <div className="p-10">
        <p className="text-black font-roboto font-normal my-5 text-xl">
          Categories
        </p>
        <div className="mb-5">
          {categories?.map((category, index) => (
            <button
              key={index}
              onClick={() => setFilteredCategory(category)}
              className={`mr-2 px-4 py-2 rounded-md ${
                filteredCategory === category
                  ? "bg-blue-500 text-white bg-buttonBackground"
                  : " text-black bg-slate-100"
              }`}
            >
              {category}
            </button>
          ))}
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-2">
          {loading ? (
            <p>Loading...</p>
          ) : (
            filteredProducts?.map((product) => (
              <Card
                createOrder={handleSubmit}
                key={product.ProductID}
                product={product}
              />
            ))
          )}
        </div>
      </div>
    </Main>
  );
};

export default Products;
