window?.addEventListener("load", () => {
  autoSlide();
});

function autoSlide() {
  setInterval(() => {
    slide(getItemActiveIndex() + 1);
  }, 10000); // slide speed = 3s
}

export function leftSlide() {
  const currentIndex = getItemActiveIndex();
  let toIndex;
  if (currentIndex === 0) {
    toIndex = 2; // Wrap around to index 2 if at the beginning
  } else {
    toIndex = currentIndex - 1; // Move to the previous item
  }

  slide(toIndex);
  // slide(getItemActiveIndex() - 1);
  // slide speed = 3s
}

export function rightSlide() {
  slide(getItemActiveIndex() + 1);
  // slide speed = 3s
}

function slide(toIndex) {
  const itemsArray = Array.from(document.querySelectorAll(".carousel_item"));
  const itemActive = document.querySelector(".carousel_item__active");

  // check if toIndex exceeds the number of carousel items
  if (toIndex >= itemsArray.length) {
    toIndex = 0;
  }

  const newItemActive = itemsArray[toIndex];

  // start transition
  newItemActive?.classList.add("carousel_item__pos_next");
  setTimeout(() => {
    newItemActive?.classList.add("carousel_item__next");
    itemActive?.classList.add("carousel_item__next");
  }, 20);

  // remove all transition class and switch active class
  newItemActive?.addEventListener(
    "transitionend",
    () => {
      itemActive.className = "carousel_item";
      newItemActive.className = "carousel_item carousel_item__active";
    },
    {
      once: true,
    },
  );
}

function getItemActiveIndex() {
  const itemsArray = Array.from(document.querySelectorAll(".carousel_item"));
  const itemActive = document.querySelector(".carousel_item__active");
  const itemActiveIndex = itemsArray.indexOf(itemActive);
  return itemActiveIndex;
}
