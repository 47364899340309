import React from "react";
import { Colors } from "../../../assets/colors";
import PhoneLogo from "../../../assets/images/phone.svg";
import Mail from "../../../assets/images/mail.svg";

const AddressCard = ({
  cardtitle,
  content,
  onClick = () => {},
  phone,
  email,
}) => {
  return (
    <div className="flex justify-center">
      <div
        className="text-zinc-800 relative mb-4 px-4 py-4  rounded-md shadow-md border  "
        style={{ backgroundColor: "#F7F7F7" }}
      >
        {/* Image */}
        <div className="flex   items-center">
          <h2 className="text-black mb-4 text-2xl sm:text-xl  font-roboto  font-light  ">
            {cardtitle}
          </h2>
        </div>
        <p className="text-black text-base pb-3 relative font-light font-roboto">
          {content}
        </p>
        {/* Read More */}
        <div className="flex flow-row">
          <img width={15} src={PhoneLogo} alt="React Logo" className="mr-2" />
          <p className="text-black text-sm leading-8 font-roboto font-light">
            {phone}
          </p>
        </div>
        <div className="flex flow-row">
          <img width={15} src={Mail} alt="React Logo" className="mr-2" />
          <p className="text-black text-sm leading-8 font-roboto font-light">
            {email}
          </p>
        </div>
      </div>
    </div>
  );
};

export default AddressCard;
