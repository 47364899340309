import * as React from "react";
import {
  Link,
  DropdownItem,
  DropdownTrigger,
  Dropdown,
  DropdownMenu,
} from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import { ProfileSVG } from "../../../assets/images/Images";
import secureLocalStorage from "react-secure-storage";
import { DownArrow } from "../../../assets/images/downarrow.tsx";

const DashHeader: React.FC = () => {
  const navigate = useNavigate();

  const username = secureLocalStorage.getItem("name") as string;

  const handleLogout = React.useCallback((): void => {
    const platform = secureLocalStorage.getItem("platform");
    localStorage.clear();
    sessionStorage.clear();
    secureLocalStorage.clear();
    if (platform === "epp") {
      navigate("/epp/login");
    } else {
      navigate("/");
    }
  }, [navigate]);

  return (
    <div className="flex bg-white justify-end items-center  p-3">
      <div className="flex justify-between w-[82%]">
        <div></div>
        <div className="flex justify-start items-center mr-10">
          <Dropdown placement="bottom-end">
            <DropdownTrigger>
              <Link>
                <ProfileSVG />
                <p className="text-black font-roboto font-normal ml-2 mr-5">
                  {username ? username : "Welcome"}
                </p>
                <DownArrow />
              </Link>
            </DropdownTrigger>
            <DropdownMenu
              aria-label="Profile Actions"
              variant="flat"
              className=" rounded-md"
            >
              <DropdownItem key="profile" className="h-14 gap-2">
                <p className="text-black font-roboto font-medium">
                  Signed in as
                </p>
                <p className="text-black font-roboto font-medium">
                  {username ? username : "Welcome"}
                </p>
              </DropdownItem>
              <DropdownItem
                className="text-black font-roboto text-base"
                key="logout"
                color="danger"
                onClick={handleLogout}
              >
                Log Out
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default DashHeader;
