import React from "react";
import {
  ClientInvoiceNumbersList,
  SelectType,
} from "../../../../../../types.ts";
import {
  Table,
  TableHeader,
  TableBody,
  TableColumn,
  TableRow,
  TableCell,
  Spinner,
  Pagination,
  Input,
  Button,
  Tooltip,
  SortDescriptor,
} from "@nextui-org/react";
import Action from "./Action.tsx";
import Spacer from "../../../../../ui/Spacer.tsx";
import { CiFilter, CiSearch } from "react-icons/ci";
import Row from "../../../../../ui/Row.tsx";
import Select from "../../../../../ui/Select.tsx";

interface Props {
  invoiceNumbers: ClientInvoiceNumbersList[];
  loading: boolean;
  downloadInvoice: (name: string, url: string) => void;
  onUpdateInvoice: () => void;
  mraUrl: string;
}

const COLUMNS = [
  {
    name: "Sr No.",
    key: "sr_no",
  },
  {
    name: "Schedule Number",
    key: "schedule_number",
    sortable: true,
  },
  {
    name: "Invoice Number",
    key: "invoice_number",
  },

  {
    name: "Month",
    key: "year",
  },
  {
    name: "Amount",
    key: "amount",
    sortable: true,
  },

  {
    name: "Action",
    key: "action",
  },
];

export default function InvoiceNumbersList({
  invoiceNumbers,
  loading,
  downloadInvoice,
  onUpdateInvoice,
  mraUrl,
}: Props) {
  const [filterValue, setFilterValue] = React.useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [selectedState, setSelectedState] = React.useState("all"); // State for selected filter
  const [showFilter, setShowFilter] = React.useState(false);
  const [dropdownFilter, setDropdownFilters] = React.useState<SelectType[]>([]);

  const [sortDescriptor, setSortDescriptor] = React.useState<SortDescriptor>({
    column: "schedule_number",
    direction: "ascending",
  });

  const pages = React.useMemo(() => {
    if (invoiceNumbers.length === 0) {
      return 1;
    }
    return Math.ceil((invoiceNumbers?.length ?? 1) / rowsPerPage);
  }, [invoiceNumbers, rowsPerPage]);

  const hasSearchFilter = Boolean(filterValue);
  const [page, setPage] = React.useState(1);

  const filteredItems = React.useMemo(() => {
    let filteredUsers = [...invoiceNumbers];

    if (hasSearchFilter) {
      filteredUsers = filteredUsers.filter((user) =>
        user.ScheduleNumber.toLowerCase().includes(filterValue.toLowerCase()),
      );
    }

    return filteredUsers;
  }, [invoiceNumbers, filterValue, hasSearchFilter]);

  const items = React.useMemo(() => {
    const start = (page - 1) * rowsPerPage;
    const end = start + rowsPerPage;
    return filteredItems.slice(start, end);
  }, [page, filteredItems, rowsPerPage]);

  const onRowsPerPageChange = React.useCallback((e) => {
    setRowsPerPage(Number(e.target.value));
    setPage(1);
  }, []);

  const onSearchChange = React.useCallback((value) => {
    if (value) {
      setFilterValue(value);
      setPage(1);
    } else {
      setFilterValue("");
    }
  }, []);

  // Handle the state selection change
  const handleStateSelect = (value: string) => {
    setSelectedState(value);
  };

  const renderCell = React.useCallback(
    (client: ClientInvoiceNumbersList, columnKey: React.Key) => {
      const index = invoiceNumbers
        .map((object) => object.InvoiceNumber)
        .indexOf(client.InvoiceNumber);
      switch (columnKey) {
        case "sr_no":
          return (
            <div className="flex flex-col">
              <p className="text-bold text-sm capitalize">{index + 1}</p>
            </div>
          );
        case "invoice_number":
          return (
            <div className="flex flex-col">
              <p className="text-bold text-sm capitalize">
                {client.InvoiceNumber}
              </p>
            </div>
          );
        case "schedule_number":
          return (
            <div className="flex flex-col">
              <p className="text-bold text-sm capitalize">
                {client.ScheduleNumber}
              </p>
            </div>
          );
        case "year":
          return (
            <div className="flex flex-col">
              <p className="text-bold text-sm capitalize">{client.Year}</p>
            </div>
          );
        case "amount":
          return (
            <div className="flex flex-col">
              <p className="text-bold text-sm capitalize">{client.Amount}</p>
            </div>
          );
        case "payment_status":
          return (
            <div className="flex flex-col">
              <p className="text-bold text-sm capitalize">
                {client.PaymentStatus}
              </p>
              {client.PaymentStatus === true ? (
                <>
                  <Tooltip content="download" size="sm">
                    <p className=" text-textColorGrey underline cursor-pointer font-roboto font-light">
                      Payment Received
                    </p>
                  </Tooltip>
                </>
              ) : (
                <>
                  <Tooltip content="download" size="sm">
                    <p className=" text-textColorGrey underline cursor-pointer font-roboto font-light">
                      Pending
                    </p>
                  </Tooltip>
                </>
              )}
            </div>
          );
        case "action":
          return (
            <div className="flex">
              <Action item={client} downloadInvoice={downloadInvoice} />
            </div>
          );
        case "status":
          return null;
      }
    },
    [invoiceNumbers, downloadInvoice],
  );

  const sortedItems = React.useMemo(() => {
    return [...items].sort(
      (a: ClientInvoiceNumbersList, b: ClientInvoiceNumbersList) => {
        const first = a[
          sortDescriptor.column as keyof ClientInvoiceNumbersList
        ] as number;
        const second = b[
          sortDescriptor.column as keyof ClientInvoiceNumbersList
        ] as number;
        const cmp = first < second ? -1 : first > second ? 1 : 0;

        return sortDescriptor.direction === "descending" ? -cmp : cmp;
      },
    );
  }, [sortDescriptor, items]);

  const topContent = React.useMemo(() => {
    return (
      <div className="relative flex flex-col gap-4">
        <div className="flex justify-between gap-3 items-end my-2">
          <Input
            isClearable
            className="focus:outline-none focus:border-none"
            classNames={{
              base: "w-full sm:max-w-[44%] focus:outline-none focus:border-none",
              inputWrapper:
                "border-0 focus:border-0 focus:outline-none focus:border-none",
              input: "border-0 focus:outline-none focus:border-none",
            }}
            placeholder="Search by schedule number..."
            size="sm"
            startContent={<CiSearch />}
            value={filterValue}
            onClear={() => {
              setFilterValue("");
              setSelectedState("all");
            }}
            onValueChange={onSearchChange}
          />

          <div className="flex gap-3">
            <Row>
              <Button
                color="default"
                variant="bordered"
                startContent={<CiFilter size={18} />}
                className="font-roboto"
                size="sm"
                radius="sm"
                onClick={() => {
                  setShowFilter(!showFilter);
                  setSelectedState("all");
                }}
              >
                Filter
              </Button>
            </Row>
          </div>
        </div>
        {showFilter && (
          <div className="absolute top-full mt-2 right-0 w-[20%] p-2  rounded bg-white shadow-xl z-10">
            {/* Add your filter content here */}
            <p className="text-black text-base leading-8 font-roboto font-medium mt-[4%]">
              Filter
            </p>
            <Select
              name="filter"
              item={dropdownFilter}
              label="State"
              placeholder="State"
              onSelect={handleStateSelect}
              className="font-roboto text-xl font-light"
            />
          </div>
        )}
      </div>
    );
  }, [dropdownFilter, filterValue, onSearchChange, showFilter]);

  const bottomContent = React.useMemo(() => {
    return (
      <div className="py-2 px-2 flex justify-between items-center">
        <Pagination
          showControls
          classNames={{
            item: "bg-pageBackground w-6 h-6 min-w-4 font-roboto",
            cursor: "w-6 h-6 min-w-4 font-roboto",
          }}
          color="default"
          isDisabled={hasSearchFilter}
          page={page}
          total={pages}
          variant="light"
          onChange={setPage}
        />
        <div className="flex justify-between items-center">
          <label className="flex items-center text-small font-roboto text-black font-light ">
            Items per page :&nbsp;
            <select
              className="border-none shadow-sm outline-none text-default-400 text-small font-roboto font-light px-1 py-0 rounded-md"
              onChange={onRowsPerPageChange}
              defaultValue={"20"}
            >
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
              <option value="25">25</option>
            </select>
          </label>
        </div>
      </div>
    );
  }, [hasSearchFilter, page, pages, onRowsPerPageChange]);

  const classNames = React.useMemo(
    () => ({
      th: [
        "bg-transparent",
        "text-tableHeaderColor",
        "border-b",
        "border-divider",
        "font-roboto",
        "font-regular",
        "text-sm",
      ],
      td: [
        "group-data-[first=true]:first:before:rounded-none",
        "group-data-[first=true]:last:before:rounded-none",
        "group-data-[middle=true]:before:rounded-none",
        "group-data-[last=true]:first:before:rounded-none",
        "group-data-[last=true]:last:before:rounded-none",
        "font-roboto",
        "font-normal",
        "text-textColorGrey",
      ],
      table: "min-h-[350px]",
      wrapper: "table-wrapper",
    }),
    [],
  );

  return (
    <div className="flex flex-col">
      <p className="text-black text-lg leading-8 font-roboto font-normal mt-[2%]">
        Invoices
      </p>
      <Spacer size="xs" />

      {mraUrl !== "" && (
        <>
          <p>
            MRA:{" "}
            <a href={mraUrl} target="_blank" rel="noopener noreferrer">
              {mraUrl?.split("/").pop()}
            </a>
          </p>
          <Spacer size="xs" />
        </>
      )}

      <Table
        selectionMode="single"
        classNames={classNames}
        topContent={topContent}
        bottomContent={bottomContent}
        bottomContentPlacement="inside"
        sortDescriptor={sortDescriptor}
        onSortChange={setSortDescriptor}
      >
        <TableHeader columns={COLUMNS}>
          {(column) => (
            <TableColumn
              allowsSorting={column.sortable}
              key={column.key}
              align={column.key === "action" ? "end" : "start"}
              width={column.key === "action" ? 100 : undefined}
              className="text-center"
            >
              {column.name}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody
          emptyContent={!loading && "No rows to display."}
          items={sortedItems}
          isLoading={loading}
          loadingContent={<Spinner label="Loading..." />}
        >
          {(item) => (
            <TableRow
              key={`${item.ScheduleNumber}-${item.ID}`}
              className="cursor-pointer h-12 "
            >
              {(columnKey) => (
                <TableCell className="text-center">
                  {renderCell(item, columnKey)}
                </TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
}
