import * as React from "react";
import { sidebarLinks } from "../../../assets/data";
import { useNavigate, useLocation } from "react-router-dom";

interface SidebarLink {
  route: string;
  label: string;
  logocmp: React.ReactNode;
}

function Bottombar() {
  const { pathname } = useLocation();

  return (
    <section className="fixed bottom-0 z-10 w-full rounded-t-3xl bg-glassmorphism p-4 backdrop-blur-lg xs:px-7 sm:hidden">
      <div className="flex items-center justify-between gap-3 xs:gap-5">
        {sidebarLinks.map((link: SidebarLink) => {
          const isActive =
            (pathname.includes(link.route) && link.route.length > 1) ||
            pathname === link.route;

          return (
            <a
              href={link.route}
              key={link.label}
              className={`relative flex flex-col items-center gap-2 rounded-lg p-2 sm:flex-1 sm:px-2 sm:py-2.5 ${
                isActive ? "bg-primary-500" : ""
              }`}
            >
              {/* <Image
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQMMbTeQ3avpXty_1plz1_iD07cPMGWQzX_Vg&usqp=CAU"
                alt={link.label}
                width={16}
                height={16}
                className='object-contain'
              /> */}
              {link.logocmp}

              <p className="text-subtle-medium text-light-1 text-black max-sm:hidden">
                {link.label.split(/\s+/)[0]}
              </p>
            </a>
          );
        })}
      </div>
    </section>
  );
}

export default Bottombar;
