import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  blogs: [],
};

export const counterSlice = createSlice({
  name: "blogs",
  initialState,
  reducers: {
    updateblogs: (state, action) => {
      state.blogs = action.payload;
    },
  },
});

export const { updateblogs } = counterSlice.actions;

export default counterSlice.reducer;
