import React from "react";
import Spacer from "../../../components/ui/Spacer.tsx";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import {
  ClientRentalNumbersList,
  LatestUpdateList,
  OrderEppList,
  UpcomingQcType,
} from "../../../types.ts";
import useApi from "../../../hooks/useApi.tsx";
import {
  GetClientSupportApi,
  GetEppOrdersApi,
  GetMraUrlApi,
  GetRentalListApi,
  GetUpcomingQcClient,
  GetlatestListApi,
} from "../../../apis.ts";
import {
  addDays,
  getDateThreeMonthsAhead,
  getDateTwoMonthsAgo,
} from "../../../utils/utils.ts";
import MyDoughnutChart from "../../../components/common/DoughnutChart.js";
import Main from "../../../components/ui/sidebar/Main.tsx";
import secureLocalStorage from "react-secure-storage";

Chart.register(CategoryScale);

// Define the ApiResponse interface
interface ApiResponse {
  user_name: string;
  user_email: string;
  manager_name: string;
  manager_email: string;
}

const Dashboard = () => {
  const { makeApiCall } = useApi();
  const platform = secureLocalStorage.getItem("platform");

  const [loading, setLoading] = React.useState(false);
  const [upcomingQc, setUpcomingQcData] = React.useState<UpcomingQcType[]>([]);
  const [latestUpdateData, setLatestUpdateData] = React.useState<
    LatestUpdateList[]
  >([]);
  const [deals, setDeals] = React.useState(0);
  const [disbursement, setDisbursement] = React.useState(0);
  const [responseData, setResponseData] = React.useState<ApiResponse | null>(
    null,
  ); // State to store API response data
  const [eppOrders, setEppOrderseNumbers] = React.useState<OrderEppList[]>([]);
  const [invoiceLoading, setInvoiceLoading] = React.useState(false);

  const [rentalLoading, setRentalLoading] = React.useState(false);
  const [clientRentalNumbers, setClientIRentalNumbers] = React.useState<
    ClientRentalNumbersList[]
  >([]);

  // Rental schedule get API call
  React.useEffect(() => {
    setRentalLoading(true);
    makeApiCall(GetRentalListApi())
      .then((response) => {
        if (response !== undefined) {
          setClientIRentalNumbers(response?.data);
        }
      })
      .catch((error) => console.error(error))
      .finally(() => setRentalLoading(false));
  }, [makeApiCall]);

  // Helper function to format dates
  const formatDate = (dateStr: string) => {
    const [day, month, year] = dateStr.split("-");
    return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
  };

  // Function to fetch upcoming QC data
  const upcomingQcFetchApi = React.useCallback(
    (start_date: string, end_date: string) => {
      setLoading(true);
      return makeApiCall(GetUpcomingQcClient(start_date, addDays(end_date, 1)))
        .then((response) => {
          setUpcomingQcData(response?.data);
        })
        .catch((error) => console.log(error))
        .finally(() => setLoading(false));
    },
    [makeApiCall],
  );

  // Function to fetch user data
  const userDataApi = React.useCallback(() => {
    setLoading(true);
    return makeApiCall(GetMraUrlApi())
      .then((response) => {
        setDeals(Number(response?.data?.TotalValue));
        setDisbursement(Number(response?.data?.DisbursedValue));
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  }, [makeApiCall]);

  // Function to fetch latest updates
  const getLatestUpdates = React.useCallback(() => {
    setLoading(true);
    return makeApiCall(GetlatestListApi())
      .then((response) => {
        setLatestUpdateData(response?.data);
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  }, [makeApiCall]);

  // useEffect to fetch initial data
  React.useEffect(() => {
    makeApiCall(GetClientSupportApi())
      .then((response) => {
        if (response !== undefined) {
          setResponseData(response);
        }
      })
      .catch((error) => console.error(error));
  }, [makeApiCall, upcomingQcFetchApi, getLatestUpdates, userDataApi]);

  React.useEffect(() => {
    setInvoiceLoading(true);
    makeApiCall(GetEppOrdersApi())
      .then((response) => {
        if (response !== undefined) {
          setEppOrderseNumbers(response?.orders);
        }
      })
      .catch((error) => console.error(error))
      .finally(() => setInvoiceLoading(false));
  }, [makeApiCall]);

  React.useEffect(() => {
    upcomingQcFetchApi(getDateTwoMonthsAgo(), getDateThreeMonthsAhead());
  }, [upcomingQcFetchApi]);

  React.useEffect(() => {
    userDataApi();
  }, [userDataApi]);

  React.useEffect(() => {
    getLatestUpdates();
  }, [getLatestUpdates]);

  const countPendingOrders = () => {
    return eppOrders.filter((order) => order.Status === "pending").length;
  };

  // Example usage
  const pendingCount = countPendingOrders();

  const countActiveRentals = () => {
    return clientRentalNumbers.filter((order) => order.Status === 0).length;
  };

  // Example usage
  const activeRentalCount = countActiveRentals();

  return (
    <Main>
      <div className="text-black bg-pageBackground px-10 min-h-screen">
        <Spacer size="sm" />
        <p className="text-black text-lg leading-8 font-roboto font-normal mt-[2%] mb-[2%]">
          Dashboard
        </p>
        <div className="flex flex-row  w-full h-screen gap-x-2 mb-6">
          <div className="flex flex-col gap-y-2 mr-2  w-1/2 h-screen ">
            <div className="flex-1 flex flex-col rounded-md bg-white shadow-box">
              <p className="px-[4%] py-[2%]  text-base leading-8 font-roboto font-medium text-textColorSecondary">
                Disbursement Tracker
              </p>
              <div className="w-[100%] h-[80%] flex justify-center items-center">
                <MyDoughnutChart deals={deals} disbursement={disbursement} />
              </div>
            </div>
            {platform === "epp" ? (
              <div className=" flex-1 flex rounded-md ">
                <div className=" w-full h-[80%] flex flex-row">
                  <div className="bg-white flex w-[50%] flex-col justify-center items-center shadow">
                    <p className="px-[4%] py-[2%]  text-4xl leading-8 font-roboto font-medium text-black">
                      {activeRentalCount}
                    </p>
                    <p className="px-[4%] py-[2%]  text-base leading-8 font-roboto font-normal text-black">
                      Active Devices
                    </p>
                  </div>
                  <div className="bg-white flex w-[50%] flex-col justify-center items-center shadow-sm">
                    <p className="px-[4%] py-[2%]  text-4xl leading-8 font-roboto font-medium text-black">
                      {pendingCount}
                    </p>
                    <p className="px-[4%] py-[2%]  text-base leading-8 font-roboto font-normal text-black">
                      Approvals Pending
                    </p>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <div className="flex flex-col gap-3 w-1/2 h-screen">
            <div className=" flex-1 flex rounded-md bg-white shadow-box">
              <div>
                <p className="p-[8%] text-textColorSecondary text-base leading-8 font-roboto font-medium">
                  Quick Support
                </p>

                {loading ? (
                  <p>Loading...</p>
                ) : responseData ? (
                  <>
                    <div className="bg-white w-[100%] py-2 px-4 mx-auto rounded-lg">
                      <div>
                        <div className="ml-[8%]  w-full">
                          <p className="text-black text-sm leading-8 font-roboto font-light">
                            Name:{" "}
                            <span className="font-semibold">
                              {responseData?.user_name}
                            </span>
                          </p>
                          <p className="text-black text-sm leading-8 font-roboto font-light">
                            Email:{" "}
                            <span className="font-semibold">
                              {responseData?.user_email}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="bg-white w-[100%] py-2 px-4 mx-auto rounded-lg">
                    <p>No data available</p>
                  </div>
                )}
              </div>{" "}
            </div>
            <div className="bg-white flex-1 flex rounded-md overflow-y-auto shadow-box">
              <div>
                <p className="p-[8%] pb-[1%] text-textColorSecondary text-base leading-8 font-roboto font-medium">
                  Upcoming Events
                </p>
                <div className="ml-[8%] py-5 w-full">
                  {upcomingQc?.map((item) => (
                    <p
                      key={item.id}
                      className="text-black text-sm leading-8 font-roboto font-light py-1"
                    >
                      <span className="font-extrabold text-lg mr-1">
                        &#8226;
                      </span>{" "}
                      Schedule Number {item.schedule_number} expiring on{" "}
                      {formatDate(item.end_date).toLocaleDateString("en-US", {
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                      })}
                    </p>
                  ))}
                </div>
              </div>
            </div>
            <div className=" flex-1 flex rounded-md bg-white shadow-box">
              <div className="w-full">
                <p className="px-[8%] py-[4%] text-textColorSecondary text-base leading-8 font-roboto font-medium">
                  Latest Updates from Connect
                </p>
                <div className="ml-[8%]  w-full">
                  {loading ? (
                    <p>Loading...</p>
                  ) : latestUpdateData.length > 0 ? (
                    <>
                      {latestUpdateData?.map((item, index) => (
                        <p
                          key={index}
                          className="text-black text-base leading-8 font-roboto font-light"
                        >
                          <a
                            href={item.Link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {item.Header}
                          </a>
                        </p>
                      ))}
                    </>
                  ) : (
                    <p>No data available</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Main>
  );
};

export default Dashboard;
