import * as React from "react";
import { firestore, collection, getDocs } from "../../lib/db.js";
import Header from "../../components/common/Header.js";
import HeadingBanner from "../../components/common/HeadingBanner.tsx";
import BlogCard from "../../components/pages/blogs/BlogCard.tsx";
import { Progress } from "@nextui-org/react";

const Blogs = () => {
  const [blogs, setBlogs] = React.useState(undefined); // Define the state with the Blog type

  const [loading, setLoading] = React.useState(true);

  const fetchData = React.useCallback(async () => {
    try {
      const querySnapshot = await getDocs(collection(firestore, "blogs"));
      const fetchedData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setBlogs(fetchedData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  }, []);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const renderContent = React.useCallback(() => {
    if (loading) {
      return (
        <div className="flex mb-10 align-middle justify-center">
          <Progress
            size="sm"
            isIndeterminate
            aria-label="Loading..."
            className="max-w-md"
          />
        </div>
      );
    }
    return (
      <div className="ml-10 grid grid-cols-1 sm:grid-cols-2 gap-6 mb-10">
        {blogs?.map?.((blog) => (
          <BlogCard key={blog.id} blog={blog} />
        ))}
      </div>
    );
  }, [blogs, loading]);

  return (
    <div className="w-full ">
      <Header />
      <div className=" w-screen">
        <HeadingBanner
          imageUrl={
            "https://storage.googleapis.com/connectrpl_images/companywebsite/BlogSubBanner.png"
          }
          text="Blogs"
          desc={"Our Solutions, your growth"}
        />
      </div>
      <div className="flex justify-center">
        <div className="w-[80%]">
          <p className="text-base font-normal font-roboto w-full text-black text-center my-10">
            Connect prides in its 50+ member strong Client Relationship Team,
            present PAN India, who work with you to understand your business,
            your needs and create a leasing plan for you to help you achieve
            your objectives. Our team offices in Mumbai, Delhi, Bangalore,
            Chennai, Pune and Okhla proactively works with you and your team to
            deeply understand your requirements, and uses its 40+ years of
            industry expertise to build and propose a leasing plan tailor made
            for you.
          </p>
          <div className="flex-shrink-0 text-[#1e0e62] mt-10 mb-10 sm:text-4xl text-center font-roboto text-5xl">
            <span className="font-light">Blogs</span>
          </div>
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export default Blogs;
