import React from "react";
import { Tabs, Tab } from "@nextui-org/react";
import MyCart from "./MyCart";
import MyOrders from "./MyOrders";
import Spacer from "../../../../components/ui/Spacer.tsx";

export default function OrderTabs() {
  const [selected, setSelected] = React.useState<React.Key>("my-cart");

  const changeSelectedTab = React.useCallback(() => {
    setSelected("orders");
  }, []);

  return (
    <div className="flex w-full flex-col">
      <Spacer size="sm" />
      <Tabs
        aria-label="Options"
        selectedKey={selected.toString()}
        onSelectionChange={setSelected}
        color="primary"
      >
        <Tab key="my-cart" title="My Cart">
          <MyCart changeSelectedTab={changeSelectedTab} />
        </Tab>
        <Tab key="orders" title="Orders">
          <MyOrders />
        </Tab>
      </Tabs>
    </div>
  );
}
