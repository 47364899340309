import { Select as NSelect, SelectItem, SelectProps } from "@nextui-org/react";
import * as React from "react";
import { SelectType } from "../../types.ts";

interface Props extends Omit<SelectProps, "items" | "children" | "onSelect"> {
  item: SelectType[];
  name: string;
  onSelect?: (value: string) => void;
}

export default function Select({
  name,
  item,
  label,
  placeholder,
  isRequired,
  className,
  size,
  labelPlacement = "outside",
  onSelect,
  ...props
}: Props) {
  const onChange = React.useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      onSelect?.(event.target.value);
    },
    [onSelect],
  );

  return (
    <NSelect
      label={label}
      placeholder={placeholder}
      isRequired={isRequired}
      className={className}
      onChange={onChange}
      size={size}
      labelPlacement={labelPlacement}
      {...props}
    >
      {item?.map(({ label, value }) => (
        <SelectItem key={value} value={value}>
          {label}
        </SelectItem>
      ))}
    </NSelect>
  );
}
