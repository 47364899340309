import * as React from "react";
import { EmployeeDirectoryType } from "../../../types.ts";
import { Tooltip } from "@nextui-org/react";
import {
  ClosePay,
  Generatepassword,
} from "../../../../src/assets/images/Images.js";

interface Props {
  item: EmployeeDirectoryType;
  generateEmployeePassword: (name: string, email: string) => void;
}

export default function Action({ item, generateEmployeePassword }: Props) {
  const handleGeneratePassword = React.useCallback(() => {
    return generateEmployeePassword(item?.name, item?.email);
  }, [generateEmployeePassword, item.email, item.name]);

  return (
    <div className="flex  flex-row gap-4">
      <Tooltip content={"Generate Passowrd"}>
        <p onClick={handleGeneratePassword}>
          <Generatepassword color={"#00006D"} />
        </p>
      </Tooltip>
      <Tooltip content={"Foreclose"}>
        <p>
          <ClosePay color={"#00006D"} />
        </p>
      </Tooltip>
    </div>
  );
}
