import React, { useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  defaults,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js/auto";

ChartJS.register(ArcElement, Tooltip, Legend);

const MyDoughnutChart = (deals, disbursement) => {
  const chartData = {
    labels: ["Total", "Disbursed"],
    datasets: [
      {
        label: "My Dataset",
        data: [deals?.deals - deals?.disbursement, deals?.disbursement],
        backgroundColor: ["rgba(255, 0, 0, 1)", "rgba(0, 0, 109, 1)"],
      },
    ],
  };

  const chartOptions = {
    plugins: {
      legend: {
        display: false,
        position: "top",
        labels: {
          generateLabels: (chart) => {
            const data = chart.data;
            return data.labels.map((label, index) => ({
              text: `${label}: ${data.datasets[0].data[index]} Cr`,
              fillStyle: data.datasets[0].backgroundColor[index],
              strokeStyle: data.datasets[0].backgroundColor[index],
              index: index,
              boxWidth: 20,
              boxHeight: 20,
            }));
          },
          usePointStyle: true,
          pointStyle: "rect",
        },
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  //   const textCenter = {
  //     id: "textCenter",
  //     beforeDatasetsDraw(chart, args, pluginsOptions) {
  //       const { ctx, data } = chart;
  //       ctx.save();
  //       ctx.font = "bold 20px Arial";
  //       ctx.fillStyle = "#000000";
  //       ctx.textAlign = "center";
  //       ctx.textbaseline = "middle";

  //       const totalValue = data.datasets[0].data.reduce((a, b) => a + b, 0);
  //       const { x, y } = chart.getDatasetMeta(0).data[0].tooltipPosition();
  //       ctx.fillText("Total Value", x, y - 20); // Text above
  //       ctx.font = "bold 40px Arial";
  //       ctx.fillText(totalValue, x, y + 20); // Value below
  //     //   ctx.fillText(
  //     //     `Total Value: ${data.datasets[0].data[0] + data.datasets[0].data[1]}`,
  //     //     chart.getDatasetMeta(0).data[0].x,
  //     //     chart.getDatasetMeta(0).data[0].y,
  //     //   );

  //     },
  //   };

  const textCenter = {
    id: "textCenter",
    beforeDatasetsDraw(chart) {
      const {
        ctx,
        chartArea: { top, bottom, left, right },
        data,
      } = chart;
      ctx.save();

      const centerX = (left + right) / 2;
      const centerY = (top + bottom) / 2;

      ctx.font = " 14px Arial";
      ctx.fillStyle = "black";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      const totalValue = data.datasets[0].data.reduce((a, b) => a + b, 0);

      ctx.fillText("Deal Size", centerX, centerY - 20); // Text above
      ctx.font = "bold 22px Arial";
      ctx.fillText(totalValue + "cr", centerX, centerY + 20); // Value below
    },
  };
  return (
    <div cla>
      <p className="text-black text-xs font-roboto mb-2 ml-4">
        <div
          style={{ backgroundColor: "rgba(255, 0, 0, 1)" }}
          className="h-3 w-3 inline-block rounded-md mx-2"
        />
        Total : {deals?.deals} Cr
        <div
          style={{ backgroundColor: "rgba(0, 0, 109, 1)" }}
          className="h-3 w-3 inline-block rounded-md mx-2"
        />
        Disbursement:{deals?.disbursement} Cr
      </p>
      <Doughnut
        data={chartData}
        options={chartOptions}
        plugins={[textCenter]}
      />
    </div>
  );
};

export default MyDoughnutChart;
