import React from "react";
import { Colors } from "../../assets/colors";

const IndustriesCardDetail = ({ imageUrl, cardtitle }) => {
  return (
    <div className="flex justify-center">
      <div className="text-zinc-800 relative mb-4  bg-white  rounded-md  w-[100%] ">
        {imageUrl && (
          <img
            src={imageUrl}
            alt="Service Image"
            className="w-full h-auto mb-4 rounded-md"
          />
        )}
        <h2 className="text-black text-xl leading-8 font-roboto font-light mb-4">
          {cardtitle}
        </h2>
      </div>
    </div>
  );
};

export default IndustriesCardDetail;
