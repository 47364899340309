import React from "react";
import TopNav from "../../components/TopNav";
import { Helmet } from "react-helmet";

const Career = () => {
  return (
    <div>
      <Helmet>
        <title>
          {" "}
          Asset Leasing and Financing | Connect Residuary Private Limited |
          career{" "}
        </title>
        <meta
          name="description"
          content="Connect Residuary Pvt Limited is a well-established asset leasing brand in India dealing in all types of IT Assets, Fit-outs, Plant and machinery etc. We have been leased over 2000cr + of assets in past 12 years of operations."
        />
        <meta
          name="keywords"
          content="leasing, rent, asset on rent, laptop on rent, leasing, laptop on lease, Corporate Asset Purchase, IT asset leasing, equipment leasing, Financing, small business financing, equipment financing, vehicle financing, asset management, Leaseback, capital equipment, business loans, commercial loans, equipment rentals, SME, tax benefits of leasing, lease accounting"
        />
        {/* Add any other meta tags as needed */}
      </Helmet>{" "}
      <TopNav
        bannerurl="https://storage.googleapis.com/connectrpl-landing-pagev3/banner1%20.png"
        title="Career"
        titledesc=""
        showreadmore={false}
      />
      <div className=" flex justify-center items-center">
        <div className=" w-[80%] ">
          <section
            style={{ offsetPosition: "normal" }}
            className="text-zinc-800 px-6"
          >
            <div className="px-4 m-auto">
              <div className="py-4">
                <p className="text-black text-base pb-3 pl-3 relative text-justify font-poppins">
                  Connect is about people, values, growing relationships, and
                  building a community both in real life and on the net. Our
                  policies synchronize the positive energies of our resources by
                  cultivating the professional and personal zest of our
                  workforce. We believe that work is serious, but we also
                  believe that if we do not enjoy what we do, we can never
                  motivate ourselves to excel personally or professionally.
                </p>

                <p className="text-black text-base pb-3 pl-3 relative text-justify font-poppins">
                  Recruitment at Connect is always an ongoing process and we are
                  always open to enrich our team further with quality manpower.
                  If this describes you, and you have outstanding relevant
                  skills, you could be at Connect. Send us your CV at
                </p>

                <a
                  href="https://www.connectrpl.com/mailto:connect@connectrpl.com"
                  className="text-cyan-600 font-poppins"
                >
                  connect@connectrpl.com{" "}
                </a>
              </div>
            </div>
            <div className="w-[100%] h-20"></div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Career;
