import { GetEppAddressApi } from "../../../../src/apis";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import useApi from "../../../hooks/useApi.tsx";
import AddressList from "../../../../src/components/pages/address-directory/List.tsx";
import { EppAddressType } from "../../../../src/types.ts";

const LocationDirectory: React.FC = () => {
  const { makeApiCall } = useApi();
  const navigate = useNavigate();
  const [addresses, setAddresses] = React.useState<EppAddressType[]>([]);
  const [loading, setLoading] = React.useState(false);
  const navigateToAddAddress = React.useCallback(() => {
    navigate("/dashboard/add-address");
  }, [navigate]);

  React.useEffect(() => {
    setLoading(true);
    makeApiCall(GetEppAddressApi())
      .then((response) => {
        setLoading(false);
        if (response !== undefined) {
          console.log(response, "Level API response");
          setAddresses(response); // Set the addresses in the state
        }
      })
      .catch((error) => console.error(error))
      .finally(() => {
        setLoading(false);
      });
  }, [makeApiCall]);

  return (
    <>
      <div>
        <AddressList
          addresses={addresses}
          loading={loading}
          navigateToAddAddress={navigateToAddAddress}
        />
      </div>
    </>
  );
};

export default LocationDirectory;
