import React from "react";
import Logo1 from "../../assets/images/cipla.svg";
import Logo2 from "../../assets/images/kpmg.svg";
import Logo3 from "../../assets/images/samsung.svg";
import Logo4 from "../../assets/images/tata.svg";
import Logo5 from "../../assets/images/philips.svg";
import Logo6 from "../../assets/images/schlumberger.svg";
import { Colors } from "../../assets/colors";
import TitleHead from "../common/TitleHead.tsx";
// import CompanyCard from "../common/GoreviveCard.js";
import GoReviveCard from "../common/GoreviveCard.js";

const OurCompanies = () => {
  return (
    <div className="w-full flex justify-center items-center  mt-16 mb-10 md:mt-20 md:mb-10 bg-[#f7f7f7]">
      <div className="flex flex-col md:flex-row w-[100%] mt-10">
        <div className=" flex-1 w-full ">
          <div className="flex flex-col justify-center items-center ">
            <TitleHead
              title="More from Connect"
              subtitle="Our Sister Companies"
            />
          </div>

          <div className="flex flex-col items-center p-4 w-screen bg-[#f7f7f7] pb-20 ml-5rem">
            <div className="flex flex-row justify-center gap-8">
              <GoReviveCard
                imageUrl={
                  "https://storage.googleapis.com/connectrpl-landing-pagev3/siteimages/logonew.png"
                }
                cardtitle={"Connect Equipment Leasing Private Limited"}
                cardsubtitle={"RBI Registered NBFC"}
                content1={
                  "Connect Equipment Leasing Private Limited, established in the year 2016 by Founder Directors of Connect Residuary Private Limited is registered with RBI as(NBFC)"
                }
                content2={
                  "To meet the diverse financial leasing needs of the market, Connect team took a step forward and formed the NBFC which enables the customers to utilise their capital for day to day business needs rather than blocking the same on capital assets. With our vast experience in Asset Life Cycle Management and financial expertise we assist the entities to focus on their core business through enhanced liquidity."
                }
                hyperlinkirl={"http://connectelpl.com/"}
                margintop={18}
              />
              <GoReviveCard
                imageUrl={
                  "https://storage.googleapis.com/connectrpl-landing-pagev3/siteimages/grpllogo.png"
                }
                cardtitle={"GoRevive Private Limited"}
                cardsubtitle={"Asset Refurbishment Unit"}
                content1={
                  "GoRevive specialises in the business of buying and selling used/pre-owned IT Equipments Viz- laptops, desktops, servers and networking equipment’s by giving these devices a new life by refurbishing them."
                }
                content2={
                  "With technology advancing at a rapid pace and getting more expensive as time passes, a graded device has many more benefits than just being cost effective. It enables you to access the latest trends & brands at almost half the prices and gives you the option of an upgrade in quicker successions."
                }
                hyperlinkirl={"https://gorevive.in"}
                margintop={0}
              />
            </div>
          </div>
          {/* </div> */}

          {/* <CompanyCard
            imageUrl={"https://storage.googleapis.com/connectrpl-landing-pagev3/siteimages/logonew.png"}
            cardtitle={"Connect Equipment Leasing Private Limited"}
            cardsubtitle={"Asset Buyback"}
            content={
              "Have old assets to dispose off? Connect provides its “FIrst in Industry” grading tools, packaging and pickup facilities PAN india "
            }
            onClick={"https://gorevive.in"}
          /> */}
          {/* <ClientsAnimation /> */}
        </div>
      </div>
    </div>
  );
};

export default OurCompanies;
